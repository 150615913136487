import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import OrderForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
  order: Model.IOrder
  batchid: string
  errorMessage: string | undefined
}

const FormEdit: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getOrderState()
  const action = props.appcontext.getOrderAction()

  const handleUpdate = (orderWorkingCopy: Model.Form.IOrder) => {
    action.updateRequest(orderWorkingCopy)
  }
  
  const handleCancel = () => {
    action.closeEditForm(props.order.orderid)
  }

  const orderStatus = state.orderStatuses[props.order.orderid]

  return (
    <OrderForm isOpen={true} formTitle={props.formTitle} isCreate={false} order={props.order} batchid={props.batchid} onUpdate={handleUpdate} onCancel={handleCancel} errorMessage={props.errorMessage} isLoading={orderStatus.isLoading} />
  )
}

export default withAppContext(FormEdit)