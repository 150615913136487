import * as Api from './api'
import * as Form from './form'
export { Api, Form }

export type ShippingMethodKeys = 'face' | 'surface' | 'surfaceregistered' | 'sfcustomer' | 'sfwepay' | 'ems' | 'airmail' | 'sal' | 'ship'
export type AvailableShippingMethod = { [k in ShippingMethodKeys]: string }

export * from './address'
export * from './authenticate'
export * from './batch'
export * from './customer'
export * from './deposit'
export * from './mailtracker'
export * from "./oldproduct"
export * from './order'
export * from './orderproduct'
export * from './payment'
export * from './presettag'
export * from './product'
export * from './stock'
export * from './variance'
export * from './tag'

// Others

export interface IApiErrorResult {
  data: {
    success: boolean,
    message: string
  }
}

export const generateTempId = () => {
  let text = ""
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}