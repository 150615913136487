import * as _ from "lodash"
import * as React from "react"

import { AddressAction, EmptyAddressContext, IAddressAction, IAddressContext } from "./AddressContext"
import { BatchAction, EmptyBatchContext, IBatchAction, IBatchContext } from "./BatchContext"
import { CustomerAction, EmptyCustomerContext, ICustomerAction, ICustomerContext } from "./CustomerContext"
import { EmptyMailTrackerContext, IMailTrackerAction, IMailTrackerContext, MailTrackerAction } from "./MailTrackerContext"
import { EmptyOldProductContext, IOldProductAction, IOldProductContext, OldProductAction } from "./OldProductContext"
import { EmptyOrderContext, IOrderAction, IOrderContext, OrderAction } from "./OrderContext"
import { EmptyPresetTagContext, IPresetTagAction, IPresetTagContext, PresetTagAction } from "./PresetTagContext"
import { EmptyProductContext, IProductAction, IProductContext, ProductAction } from "./ProductContext"

export interface IAppContextProps {
  appcontext: IAppContext
}

export interface IAppContext {
  action: IAppAction
  state: IAppState
  getAddressAction: () => IAddressAction
  getAddressState: () => IAddressContext
  getBatchAction: () => IBatchAction
  getBatchState: () => IBatchContext
  getCustomerAction: () => ICustomerAction
  getCustomerState: () => ICustomerContext
  getMailTrackerAction: () => IMailTrackerAction
  getMailTrackerState: () => IMailTrackerContext
  getOldProductAction: () => IOldProductAction
  getOldProductState: () => IOldProductContext
  getOrderAction: () => IOrderAction
  getOrderState: () => IOrderContext
  getPresetTagAction: () => IPresetTagAction
  getPresetTagState: () => IPresetTagContext
  getProductAction: () => IProductAction
  getProductState: () => IProductContext
}

export interface IAppState {
  address: IAddressContext
  batch: IBatchContext
  customer: ICustomerContext
  mailTracker: IMailTrackerContext
  oldProduct: IOldProductContext
  order: IOrderContext
  presetTag: IPresetTagContext
  product: IProductContext
}

export interface IAppAction {
  address: (state: IAddressContext) => IAddressAction
  batch: (state: IBatchContext) => IBatchAction
  customer: (state: ICustomerContext) => ICustomerAction
  mailTracker: (state: IMailTrackerContext) => IMailTrackerAction
  oldProduct: (state: IOldProductContext) => IOldProductAction
  order: (state: IOrderContext) => IOrderAction
  presetTag: (state: IPresetTagContext) => IPresetTagAction
  product: (state: IProductContext) => IProductAction
}

const context = React.createContext<IAppContext | null>(null)

export class AppContextProvider extends React.Component<{}, IAppState> {
  public readonly state: IAppState = {
    address: EmptyAddressContext(),
    batch: EmptyBatchContext(),
    customer: EmptyCustomerContext(),
    mailTracker: EmptyMailTrackerContext(),
    oldProduct: EmptyOldProductContext(),
    order: EmptyOrderContext(),
    presetTag: EmptyPresetTagContext(),
    product: EmptyProductContext(),
  }
  
  public readonly action: IAppAction = {
    address: (state: IAddressContext) => AddressAction(state, this),
    batch: (state: IBatchContext) => BatchAction(state, this),
    customer: (state: ICustomerContext) => CustomerAction(state, this),
    mailTracker: (state: IMailTrackerContext) => MailTrackerAction(state, this),
    oldProduct: (state: IOldProductContext) => OldProductAction(state, this),
    order: (state: IOrderContext) => OrderAction(state, this),
    presetTag: (state: IPresetTagContext) => PresetTagAction(state, this),
    product: (state: IProductContext) => ProductAction(state, this),
  }

  public render() {
    const appContext: IAppContext = {
      action: this.action,
      getAddressAction: () => this.action.address(this.state.address),
      getAddressState: () => this.state.address,
      getBatchAction: () => this.action.batch(this.state.batch),
      getBatchState: () => this.state.batch,
      getCustomerAction: () => this.action.customer(this.state.customer),
      getCustomerState: () => this.state.customer,
      getMailTrackerAction: () => this.action.mailTracker(this.state.mailTracker),
      getMailTrackerState: () => this.state.mailTracker,
      getOldProductAction: () => this.action.oldProduct(this.state.oldProduct),
      getOldProductState: () => this.state.oldProduct,
      getOrderAction: () => this.action.order(this.state.order),
      getOrderState: () => this.state.order,
      getPresetTagAction: () => this.action.presetTag(this.state.presetTag),
      getPresetTagState: () => this.state.presetTag,
      getProductAction: () => this.action.product(this.state.product),
      getProductState: () => this.state.product,
      state: this.state,
    }

    return (
      <context.Provider value={appContext}>
        {this.props.children}
      </context.Provider>
    )
  }
}

export const Consumer = context.Consumer

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withAppContext<
  P extends IAppContextProps,
  R = Omit<P, "appcontext">
  >(
  Component: React.ComponentClass<P> | React.StatelessComponent<P>
  ): React.SFC<R> {
  return function BoundComponent(props: any) {
    return (
      <Consumer>
        { value => value === null ? (
          <span>Context error</span>
        ) : (
          <Component {...props} appcontext={value} />
        )}
      </Consumer>
    );
  };
}



export const searchInString = (text: string, keyword: string): boolean => {
  if (text === undefined || text === null || text === "") {
    return false
  }

  if (text.match(new RegExp(keyword, "i"))) {
    return true
  }

  return false
}

export const searchInStrings = (textes: string[], keyword: string): boolean => {
  return _.find(textes, (text: string) => {
    return searchInString(text, keyword)
  }) !== undefined
}

export const searchInNumber = (num: number, keyword: string): boolean => {
  const text = num+""
  if (text === undefined || text === null || text === "") {
    return false
  }

  if (text.match(new RegExp(keyword, "i"))) {
    return true
  }

  return false
}