import * as moment from 'moment'

// Mail Tracker

export interface IMailTracker {
  description: string
  isdelivered: boolean
  mailtrackerid: string
  shipdate: moment.Moment
  shippingfee: number
  weight: number
}
export function EmptyMailTracker(): IMailTracker {
  return {
    description: '',
    isdelivered: false,
    mailtrackerid: '',
    shipdate: moment(),
    shippingfee: 0,
    weight: 0
  }
}