import * as React from 'react'
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import * as FontAwesome from 'react-fontawesome'
import * as Model from '../../models'

interface IAddTagProps {
  onAddTag: (tag: Model.Form.ITag) => void
  tag: Model.Form.ITag
}
interface IAddTagState {
  workingCopy: Model.Form.ITag
}

export default class AddTag extends React.Component<IAddTagProps, IAddTagState> {
  constructor(props: IAddTagProps) {
    super(props)

    this.state = {
      workingCopy: { ...props.tag, isadd: true }
    }
  }

  public render() {
    const workingCopy = this.state.workingCopy

    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesome name="tag" className="icon" />
          </InputGroupText>
        </InputGroupAddon>
        <Input type="text" name="name" value={workingCopy.name} onChange={this.handleChange} placeholder="新標籤" />
        <InputGroupAddon addonType="append">
          <Button type="button" onClick={this.handleAddTag}>
            <FontAwesome name="plus" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  private handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    
    const workingCopy = this.state.workingCopy
    workingCopy[name] = value

    this.setState({workingCopy})
  }

  private handleAddTag = () => {
    this.props.onAddTag({ ...this.state.workingCopy })
    this.setState({
      workingCopy: this.props.tag
    })
  }
}