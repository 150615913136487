import * as React from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import * as moment from 'moment'
import 'react-block-ui/style.css'

/* tslint:disable no-var-requires*/
const BlockUi = require("react-block-ui").default
/* tslint:enalbe no-var-requires*/

import * as Model from '../../models'
import * as FormComponents from '../form'
import * as Layout from '../layout'

interface IProps {
  isOpen: boolean
  formTitle: string
  isCreate: boolean
  mailtracker: Model.IMailTracker
  onCreate?: (mailtracker: Model.IMailTracker) => void
  onUpdate?: (mailtracker: Model.IMailTracker) => void
  onCancel: (mailtrackerid?: string) => void
  errorMessage: string | undefined
  isLoading: boolean
}
interface IState {
  workingCopy: Model.IMailTracker
}

class FormGeneric extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      workingCopy: { ...props.mailtracker }
    }
  }

  public render() {
    const workingCopy = this.state.workingCopy
    
    return (
      <Form>
        <Modal isOpen={this.props.isOpen} toggle={this.handleCancel} size="lg">
          <BlockUi tag="div" blocking={this.props.isLoading} loader={Layout.Loader}>
            <ModalHeader>
              {this.props.formTitle}
            </ModalHeader>
            <ModalBody>
              { this.props.isCreate && (
                <FormGroup row={true}>
                  <Label sm={2}>ID</Label>
                  <Col sm={10}>
                    <Input name="mailtrackerid" value={workingCopy.mailtrackerid} onChange={this.handleChangeMailTrackerId} required={true} placeholder="ID" />
                  </Col>
                </FormGroup>
              )}
              <FormGroup row={true}>
                <Label sm={2}>寄送日</Label>
                <Col sm={10}>
                  <FormComponents.DatePickerInput name="shipdate" value={workingCopy.shipdate} onChange={this.handleChangeShipDate} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>描述</Label>
                <Col sm={10}>
                  <Input type="text" name="description" value={workingCopy.description} onChange={this.handleChangeDescription} required={true} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2} />
                <Col sm={10}>
                  <FormGroup check={true}>
                    <FormComponents.Checkbox name="isdelivered" value={workingCopy.isdelivered} onCheckToggled={this.handleChangeIsDelivered} text="已到達" />
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>運費</Label>
                <Col sm={10}>
                  <Input type="number" name="shippingfee" value={workingCopy.shippingfee} onChange={this.handleChangeShippingFee} required={true} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>重量</Label>
                <Col sm={10}>
                  <Input type="number" name="weight" value={workingCopy.weight} onChange={this.handleChangeWeight} required={true} />
                </Col>
              </FormGroup>
              { this.props.errorMessage && (
                <Row>
                  <Col>
                    <Alert color="danger">{this.props.errorMessage}</Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="danger" className="mr-auto" onClick={this.handleClear}>重置</Button>
              <Button type="button" color="link" onClick={this.handleCancel}>取消</Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>確定</Button>
            </ModalFooter>
          </BlockUi>
        </Modal>
      </Form>
    )
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.submitMailTracker(this.state.workingCopy)
  }

  private submitMailTracker = (mailtracker: Model.IMailTracker) => {
    if (this.props.isCreate) {
      // Submit Create
      if (this.props.onCreate) {
        this.props.onCreate(mailtracker)
      }
    } else {
      // Submit Update
      if (this.props.onUpdate) {
        this.props.onUpdate(mailtracker)
      }
    }
  }

  private handleChangeMailTrackerId = (event: any) => {
    const mailtrackerid = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.mailtrackerid = mailtrackerid

    this.setState({workingCopy})
  }

  private handleChangeShipDate = (name: string, value: moment.Moment) => {
    const workingCopy = this.state.workingCopy
    workingCopy.shipdate = value

    this.setState({workingCopy})
  }

  private handleChangeDescription = (event: any) => {
    const description = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.description = description

    this.setState({workingCopy})
  }

  private handleChangeIsDelivered = (name: string, value: boolean) => {
    const isdelivered = value
    const workingCopy = this.state.workingCopy
    workingCopy.isdelivered = isdelivered

    this.setState({workingCopy})
  }

  private handleChangeShippingFee = (event: any) => {
    const shippingfee = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.shippingfee = shippingfee

    this.setState({workingCopy})
  }

  private handleChangeWeight = (event: any) => {
    const weight = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.weight = weight

    this.setState({workingCopy})
  }

  private handleCancel = () => {
    if (window.confirm('確定要取消？')) {
      // window.location.replace('/mailtracker')
      this.props.onCancel()
    }
  }

  private handleClear = () => {
    if (window.confirm("確定要重置？")) {
      this.setState({ workingCopy: { ... this.props.mailtracker } })
    }
  }
}

export default FormGeneric