import * as React from 'react'
import { Input, Label } from 'reactstrap'

interface IProps {
  text: string
  name: string
  value: boolean
  onCheckToggled: (name: string, value: boolean) => void
}
interface IState {
  isChecked: boolean
}

export default class Checkbox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isChecked: this.props.value || false,
    }
  }

  public render() {
    return (
      <Label>
        <Input type="checkbox"
          defaultChecked={this.state.isChecked}
          onChange={this.handleChange}
        />
        {this.props.text}
      </Label>
    );
  }

  private handleChange = () => {
    const isChecked = !this.state.isChecked
    this.setState({ isChecked }, () => {
      this.props.onCheckToggled(this.props.name, this.state.isChecked)
    })
  }
}