import * as _ from 'lodash'
import * as React from 'react'
import 'react-block-ui/style.css'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

/* tslint:disable no-var-requires*/
const BlockUi = require("react-block-ui").default
/* tslint:enable no-var-requires*/

import * as Model from '../../models'
import BatchDropdown from '../batch/dropdown'
// import * as FormComponent from '../form'
import CustomerPicker from '../customer/picker'
import * as Layout from '../layout'
import ProductPicker from '../product/picker'
import ShippingMethodControl from '../shipping/ShippingMethodControl'
import OrderProduct from './OrderProduct'

interface IProps {
  isOpen: boolean
  formTitle: string
  isCreate: boolean
  order: Model.IOrder
  batchid: string
  onCreate?: (order: Model.Form.IOrder) => void
  onUpdate?: (order: Model.Form.IOrder) => void
  onCancel: (orderid?: string) => void
  errorMessage: string | undefined
  isLoading: boolean
}
interface IState {
  activeTab: string,
  workingCopy: Model.Form.IOrder
}

class FormGeneric extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      activeTab: 'product',
      workingCopy: {
        ...Model.Form.ConvertFromOrderModel(props.order),
        batchid: props.batchid
      },
    }
  }

  public render() {
    const { activeTab, workingCopy } = this.state
    
    return (
      <Form>
        <Modal isOpen={this.props.isOpen} toggle={this.handleCancel} size="lg">
          <BlockUi tag="div" blocking={this.props.isLoading} loader={Layout.Loader}>
            <ModalHeader>
              <span>{this.props.formTitle}</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>團期</Label>
                <BatchDropdown defaultValue={workingCopy.batchid} onBatchChanged={this.handleBatchChanged} />
              </FormGroup>
              <FormGroup>
                <Label>客人資料</Label>
                <CustomerPicker canDelete={this.props.isCreate} defaultCustomer={workingCopy.customer} onCustomerChanged={this.handleCustomerChanged} onCustomerCreated={this.handleCustomerCreated} onCustomerPicked={this.handleCustomerPicked} onCustomerDeleted={this.handleCustomerDeleted} />
              </FormGroup>
              <Nav tabs={true}>
                <NavItem>
                  <NavLink
                    className={activeTab === 'product' ? "active" : ""}
                    onClick={ this.handleToggleTab.bind(this, 'product') }
                  >
                    貨品
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'shipping' ? "active" : ""}
                    onClick={ this.handleToggleTab.bind(this, 'shipping') }
                  >
                    郵寄方法
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'others' ? "active" : ""}
                    onClick={ this.handleToggleTab.bind(this, 'others') }
                  >
                    其他
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="product">
                  <Layout.RowSeparator />
                  <ProductPicker onProductCreate={this.handleProductCreate} onProductAddVariance={this.handleProductAddVariance} onProductPicked={this.handleProductPicked} onNewProduct={this.handleCreateProduct} />
                  <Layout.RowSeparator />
                  {/* <Layout.ColumnGallery hasMore={false}> */}
                  <Row>
                  { workingCopy.orderproducts.map((orderproduct: Model.Form.IOrderProduct, index: number) => (
                    <Col key={orderproduct.varianceid} xs={12}>
                      <OrderProduct orderproduct={orderproduct} onOrderProductChanged={this.handleOrderProductChanged} />
                      <Layout.RowSeparator />
                    </Col>
                  ))}
                  </Row>
                  {/* </Layout.ColumnGallery> */}
                </TabPane>
                <TabPane tabId="shipping">
                  <FormGroup>
                    <Label>郵寄方法</Label>
                    <ShippingMethodControl name="shippingmethod" order={workingCopy} onShippingMethodChange={this.handleShippingMethodChange} onShippingFeeChange={this.handleShippingFeeChange} value={workingCopy.shippingmethod} />
                  </FormGroup>
                </TabPane>
                <TabPane tabId="others">
                  <FormGroup>
                    <Label>減價 ($)</Label>
                    <Input name="discountprice" type="number" value={workingCopy.discountprice} onChange={this.handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label>Remarks</Label>
                    <Input name="remarks" type="textarea" value={workingCopy.remarks} onChange={this.handleChange} placeholder="Remarks (free text)" />
                  </FormGroup>
                </TabPane>
              </TabContent>
              { this.props.errorMessage && (
                <Row>
                  <Col>
                    <Alert color="danger">{this.props.errorMessage}</Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="danger" className="mr-auto" onClick={this.handleClear}>重置</Button>
              <Button type="button" color="link" onClick={this.handleCancel}>取消</Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>確定</Button>
            </ModalFooter>
          </BlockUi>
        </Modal>
      </Form>
    )
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.submitOrder(this.state.workingCopy)
  }

  private submitOrder = (order: Model.Form.IOrder) => {
    if (this.props.isCreate) {
      // Submit Create
      if (this.props.onCreate) {
        this.props.onCreate(order)
      }
    } else {
      // Submit Update
      if (this.props.onUpdate) {
        this.props.onUpdate(order)
      }
    }
  }

  private handleBatchChanged = (batch: Model.IBatch) => {
    const workingCopy = this.state.workingCopy
    workingCopy.batchid = batch.batchid

    this.setState({ workingCopy })
  }

  private handleShippingMethodChange = (shippingmethod: Model.ShippingMethodKeys | "") => {
    const workingCopy = this.state.workingCopy
    workingCopy.shippingmethod = shippingmethod
    
    this.setState({workingCopy})
  }

  private handleShippingFeeChange = (shippingmethod: Model.ShippingMethodKeys | "", shippingfee: number) => {
    const workingCopy = this.state.workingCopy
    workingCopy[shippingmethod] = shippingfee

    this.setState({workingCopy})
  }

  private handleCustomerChanged = (customer: Model.Form.ICustomer) => {
    const workingCopy = this.state.workingCopy
    workingCopy.customer = customer

    this.setState({ workingCopy })
  }

  private handleCustomerCreated = (customer: Model.Form.ICustomer) => {
    const workingCopy = this.state.workingCopy
    workingCopy.customer = customer
    
    this.setState({ workingCopy })
  }

  private handleCustomerPicked = (customer: Model.ICustomer) => {
    const workingCopy = this.state.workingCopy
    workingCopy.customer = customer
    
    this.setState({ workingCopy })
  }

  private handleProductPicked = (product: Model.IProduct, variance: Model.IVariance) => {
    const workingCopy = this.state.workingCopy
    
    const isExists = !_.isEmpty(_.find(workingCopy.orderproducts, (orderproduct: Model.IOrderProduct) => {
      return orderproduct.productid === product.productid && orderproduct.varianceid === variance.varianceid
    }))
    if (isExists) {
      alert("貨品已存在(重覆的貨品)")
      return
    }

    workingCopy.orderproducts.push({
      amount: 1,
      detail: Model.Form.ConvertFromProductModel(product),
      iscreate: true,
      isdelete: false,
      orderid: this.props.order.orderid,
      productid: product.productid,
      sellingprice: variance.sellingprice,
      standardprice: variance.sellingprice,
      variance: Model.Form.ConvertFromVarianceModel(variance),
      varianceid: variance.varianceid,
    })

    this.setState({workingCopy})
  }

  private handleProductCreate = () => {
    const workingCopy = this.state.workingCopy

    const tempProductId = Model.generateTempId()
    const tempVarianceId = Model.generateTempId()
    workingCopy.orderproducts.push({
      amount: 1,
      detail: Model.Form.EmptyOrderProductDetail(tempProductId),
      iscreate: true,
      isdelete: false,
      orderid: this.props.order.orderid,
      productid: tempProductId,
      sellingprice: 0,
      standardprice: 0,
      variance: Model.Form.EmptyVariance(tempVarianceId, tempProductId),
      varianceid: tempVarianceId,
    })

    this.setState({workingCopy})
  }

  private handleProductAddVariance = (product: Model.IProduct) => {
    const workingCopy = this.state.workingCopy

    const tempVarianceId = Model.generateTempId()
    workingCopy.orderproducts.push({
      amount: 1,
      detail: Model.Form.ConvertFromProductModel(product),
      iscreate: true,
      isdelete: false,
      orderid: this.props.order.orderid,
      productid: product.productid,
      sellingprice: 0,
      standardprice: 0,
      variance: Model.Form.EmptyVariance(tempVarianceId, product.productid),
      varianceid: tempVarianceId,
    })

    this.setState({workingCopy})
  }

  private handleCreateProduct = (name: string) => {
    const workingCopy = this.state.workingCopy
    
    workingCopy.orderproducts.push({
      amount: 1,
      detail: {
        name
      },
      iscreate: false,
      isdelete: false,
      orderid: workingCopy.orderid,
      productid: '',
      sellingprice: 0,
      standardprice: 0,
      variance: {
        iscreate: true,
        isdelete: false,
        ishiddenprice: false,
        name: '',
        primecost: 0,
        primecostadjust: 0,
        productid: '',
        sellingprice: 0,
        varianceid: '',
      },
    })

    this.setState({workingCopy})
  }

  private handleOrderProductChanged = (orderproduct: Model.Form.IOrderProduct) => {
    const workingCopy = this.state.workingCopy

    const index = workingCopy.orderproducts.findIndex((eachOrderProduct: Model.Form.IOrderProduct) => {
      return eachOrderProduct.varianceid === orderproduct.varianceid
    })

    workingCopy[index] = orderproduct
    this.setState({workingCopy})
  }

  private handleCustomerDeleted = () => {
    const workingCopy = this.state.workingCopy
    workingCopy.customer = Model.Form.EmptyCustomer()

    this.setState({ workingCopy })
  }

  private handleToggleTab = (tabName: string) => {
    this.setState({
      activeTab: tabName
    })
  }

  // private handleChangeOrderId = (event: any) => {
  //   const orderid = event.target.value
  //   const workingCopy = this.state.workingCopy
  //   workingCopy.orderid = orderid

  //   this.setState({workingCopy})
  // }

  private handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy[name] = value

    this.setState({workingCopy})
  }

  // private handleCheckToggled = (name: string, value: boolean) => {
  //   const workingCopy = this.state.workingCopy
  //   workingCopy[name] = value
  //   this.setState({workingCopy})
  // }

  // private handleVarianceChange = (targetVariance: Model.IOrderVariance) => {
  //   const workingCopy = this.state.workingCopy

  //   workingCopy.variances = _.map(workingCopy.variances, (variance: Model.IOrderVariance) => {
  //     if (variance.varianceid === targetVariance.varianceid) {
  //       return targetVariance
  //     }

  //     return variance
  //   })
  //   this.setState({workingCopy})
  // }

  // private handleAddVariance = (event: any) => {
  //   const workingCopy = this.state.workingCopy

  //   workingCopy.variances.push({varianceid: this.makeid(), ishiddenprice: false, name: '', sellingprice: 0, primecost: 0, primecostadjust: 0 })

  //   this.setState({workingCopy})
  // }

  private handleCancel = () => {
    if (window.confirm('確定要取消？')) {
      this.props.onCancel()
    }
  }

  private handleClear = () => {
    if (window.confirm("確定要重置？")) {
      this.setState({ workingCopy: Model.Form.ConvertFromOrderModel(this.props.order) })
    }
  }
}

export default FormGeneric