import * as React from "react"

import "./MiddleAlign.css"

export const MiddleAlign: React.SFC<{}> = (props) => {
  if (props.children) {
    return (
      <div className="aligner">
        { props.children }
      </div>
    )
  }

  return (
    <div className="aligner" />
  )
}