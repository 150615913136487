import * as React from 'react'
import * as CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Input } from 'reactstrap'

import * as OrderHandling from '../../handling/order'
import * as Model from '../../models'

interface IOrderFormTextPanelProps {
  order: Model.IOrder
}

const OrderFormTextPanel: React.SFC<IOrderFormTextPanelProps> = ({order}) => {
  const text = OrderHandling.orderFormText(order)

  return (
    <div>
      <Input type="textarea" value={text} style={{height: 400+"px"}} readOnly={true} />
      <CopyToClipboard text={text}>
        <Button>Copy</Button>
      </CopyToClipboard>
    </div>
  )
}

export default OrderFormTextPanel