import * as React from 'react'
import * as InfiniteScroll from 'react-infinite-scroller';

import * as Layout from '.'
import './ColumnGallery.css'

interface IProps {
  hasMore: boolean
  onLoadMore?: () => void
  notUseWindow?: boolean
}

const Gallery: React.SFC<IProps> = (props) => {
  const loadMore = () => {
    if (props.hasMore && props.onLoadMore) {
      props.onLoadMore()
    }
  }

  const useWindow = props.notUseWindow !== true

  return (
    <InfiniteScroll
      // pageStart={0}
      hasMore={props.hasMore}
      loadMore={loadMore}
      loader={ <Layout.Loader key="loader" /> }
      className="gallery-columns"
      useWindow={useWindow}
    >
      {props.children}
    </InfiniteScroll>
  )
}

export default Gallery