import * as Model from '..'

export interface IPresetTag extends Model.IPresetTag {
  name: string
  presettagid: string
  tags: Model.Form.ITag[]
}
export function ConvertFromPresetTagModel(presettag: Model.IPresetTag): IPresetTag {
  return {
    name: presettag.name,
    presettagid: presettag.presettagid,
    tags: Model.Form.ConvertFromTagsModel(presettag.tags),
  }
}