import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon, Media, Modal, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../../context/AppContext"
import * as Model from '../../../models'
import * as FormComponent from '../../form'
import * as Layout from '../../layout'
import ProductImage from "../image"
import ProductName from '../ProductName'
import './index.css'

// TODO: fix dropdown height
// possible solution
// https://stackoverflow.com/questions/26660549/bootstrap-3-mobile-menu-100-height

interface IProps extends IAppContextProps {
  // defaultValue: Model.IProduct
  onProductCreate: () => void
  onProductAddVariance: (product: Model.IProduct) => void
  onProductPicked: (product: Model.IProduct, variance: Model.IVariance) => void
}

interface IState {
  isOpen: boolean
  searchKeyword: string
}

export class ProductDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false,
      searchKeyword: ""
    }
  }

  public componentDidUpdate() {
    const action = this.props.appcontext.getProductAction()

    const products = action.datasForDisplay()

    if (products.count() === 0) {
      return this.handleLoadMore()
    }
  }

  public render() {
    const state = this.props.appcontext.getProductState()
    const action = this.props.appcontext.getProductAction()

    const products = action.datasForDisplay(this.state.searchKeyword)
    const { hasMore }  = state

    return (
      <Row>
        <Col>
          <Button onClick={this.toggleModal} className="mr-2">
            <FontAwesome name="search" />
            {' '}Search
          </Button>
          <Button onClick={this.handleProductCreate}>
            <FontAwesome name="plus" />
            {' '}Add Product
          </Button>
          <Modal isOpen={this.state.isOpen}>
            <Dropdown isOpen={true} toggle={this.toggleDropdown}>
              <DropdownToggle tag="span">
                <InputGroup>
                  <FormComponent.DelayTextbox className="product-searchbox" value={this.state.searchKeyword} placeholder="Search Product" onChange={this.handleSearchKeywordChanged} /> 
                  <InputGroupAddon addonType="append">
                    <Button className="float-right" onClick={this.toggleModal}>
                      <FontAwesome name="times" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </DropdownToggle>
              <DropdownMenu className="scrollable-dropdown-menu">
                <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore} notUseWindow={true}>
                  <Container>
                    <Layout.RowSeparator />
                    { products.map((product: Model.IProduct, pindex: number) => (
                        // Normal handling for each variance
                        <span key={product.productid}>
                          <Row>
                            <Col xs={12} className="product-name-group product-item">
                              <Media className="mt-2 mb-2">
                                <ProductImage product={product} size={50} />
                                <ProductName productname={product.name} /><span className="mr-2" /><Layout.BadgeId>{ product.productid }</Layout.BadgeId>
                              </Media>
                            </Col>
                          </Row>
                          <Row>
                            { product.variances.map((variance: Model.IVariance, vindex) => (
                              <Col key={variance.varianceid} onClick={this.handleClick.bind(this, product, variance)} xs={12} md={6}>
                                <Card className="mt-2 mb-2 variance-item">
                                  <CardBody className="pt-2 pb-2">
                                    <ProductName variancename={variance.name || product.name} />
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                            <Col onClick={this.handleAddVariance.bind(this, product)} xs={12} md={6}>
                              <Card className="mt-2 mb-2 add-variance-item">
                                <CardBody className="pt-2 pb-2">
                                  <FontAwesome name="plus" />{' '}新增 {product.name} 的 Variance
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Layout.RowSeparator />
                        </span>
                      // )
                    ))}
                  </Container>
                </Layout.ColumnGallery>
              </DropdownMenu>
            </Dropdown>
          </Modal>
        </Col>
      </Row>
    )
  }

  private toggleModal = () => {
    const isOpen = !this.state.isOpen
    this.setState({isOpen})
  }

  private toggleDropdown = () => {
    // Do nothing
  }

  private handleLoadMore = () => {
    const state = this.props.appcontext.getProductState()
    const action = this.props.appcontext.getProductAction()

    const { isFetching, lastFetchedPage, hasMore } = state
    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  private handleClick(product: Model.IProduct, variance: Model.IVariance) {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onProductPicked(product, variance)
    })
  }

  private handleProductCreate = () => {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onProductCreate()
    })
  }

  private handleAddVariance(product: Model.IProduct) {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onProductAddVariance(product)
    })
  }

  private handleSearchKeywordChanged = (value: string) => {
    this.setState({
      searchKeyword: value
    })
  }
}

export default withAppContext(ProductDropdown)