import * as React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Media, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import Tag from '../tag/Tag'
import Variance from '../variance/Variance'
import ProductEditForm from './FormEdit'

interface IProps extends IAppContextProps {
  product: Model.IProduct
}

const Item: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getProductState()
    const action = props.appcontext.getProductAction()

  const product = props.product
  const updateProduct = state.updateStatuses[product.productid]

  const handleChangeToEdit = () => {
    action.openEditForm(props.product.productid)
  }

  return (
    <div>
      { updateProduct !== undefined && (
        <ProductEditForm {...props} formTitle={`編輯 ${props.product.productid}`} product={product} errorMessage={updateProduct.errorMessage} />
      )}
      <Card>
        <CardHeader>
          <Layout.Clearfix>
            { product.name } <Layout.BadgeId>{ product.productid }</Layout.BadgeId>
            <div className="pull-right">
              <Button color="primary" outline={true} onClick={handleChangeToEdit}>編輯</Button>
            </div>
          </Layout.Clearfix>
        </CardHeader>
        <CardBody>
          <Container>
            <Media>
              <Layout.LazyImage s3key={product.s3keythumbnail} alt={product.name} width={200} height={200} />
            </Media>
            <Row>
              <Col xs={12}>
                <ol>
                  { product.variances && product.variances.length > 0 ? (
                      product.variances.map((variance: Model.IVariance, index: number) => (
                        <Variance key={variance.varianceid} variance={variance} />
                      ))
                    ) : (
                      <span>沒有資料</span>
                    )
                  }
                </ol>
              </Col>
            </Row>
            <pre>
              {product.description}
            </pre>
            <Row>
              <Col xs={12}>
                { product.isusj && (
                  <Tag className="usj" tag={ { name: "USJ" } } />
                )}
                { product.ismij && (
                  <Tag className="mij" tag={ { name: "日本製" } } />
                )}
                { product.isonfb && (
                  <Tag className="fb" tag={ { name: "FB" } } />
                )}
                { product.isonig && (
                  <Tag className="ig" tag={ { name: "IG" } } />
                )}
                { product.isonweb && (
                  <Tag className="web" tag={ { name: "Web" } } />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                { product.tags && product.tags.length > 0 ? (
                  product.tags.map((tag: Model.ITag, index: number) => (
                    <Tag key={tag.name} tag={tag} />
                  ))
                ) : (
                  <span>沒有資料</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="text-muted">
                最後更新: <Layout.CalendarDate format="YYYY-MM-DD HH:mm:ss">{ product.datemodified }</Layout.CalendarDate>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </div>
  )
}

export default withAppContext(Item)