import * as React from 'react'
import { Badge } from 'reactstrap'

import * as Model from '../../models'
import * as Layout from '../layout'

interface IPrimseCostLabelProps {
  variance: Model.IVariance
  bracket?: boolean
}

const PrimeCostLabel: React.StatelessComponent<IPrimseCostLabelProps> = ({variance, bracket}) => {
  return (
    <span>
      { bracket ? "(" : ""}
      { variance.primecost !== undefined && variance.primecost !== null && variance.primecostadjust ? (
          <Layout.DollarSign sign="JPY">{variance.primecost}</Layout.DollarSign>
        ) : (
          <Badge color="warning">未設定</Badge>
        )
      }
      { variance.primecostadjust !== undefined && variance.primecostadjust !== null && variance.primecostadjust !==0 &&
        <span className="small"> +<Layout.DollarSign sign="">{variance.primecostadjust}</Layout.DollarSign></span>
      }
      { bracket ? ")" : ""}
      {' '}
      { variance.ishiddenprice &&
        <Badge color="danger">價格不公開</Badge>
      }
    </span>
  )
}

export default PrimeCostLabel