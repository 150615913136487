import * as React from "react"
import { Button, Col, Container, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import { DelayTextbox } from '../form'
import * as Layout from '../layout'
import FormCreate from './FormCreate'
import Item from './Item'

const PageListComp: React.SFC<IAppContextProps> = (props) => {
  const state = props.appcontext.getMailTrackerState()
  const action = props.appcontext.getMailTrackerAction()
  
  const mailtrackers = action.datasForDisplay().toArray()
  const { hasMore }  = state
  const { isFormOpen } = state.createStatus

  const handleLoadMore = () => {
    const { isFetching, lastFetchedPage } = state

    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  const handleSearchBoxChange = (value: string) => {
    action.searchByKeyword(value)
  }

  const isSearching = () => {
    return !(state.searchKeyword === "" || state.searchKeyword === undefined || state.searchKeyword === null)
  }

  return (
    <Container>
      { isFormOpen && (
        <FormCreate {...props} formTitle="新增郵件追蹤" />
      )}
      <Row>
        <Col xs={12}>
          <Button color="primary" className="pull-right" onClick={action.openCreateForm}>新增郵件追蹤</Button>
        </Col>
      </Row>
      <Layout.RowSeparator />
      <DelayTextbox value="" onChange={handleSearchBoxChange} placeholder="搜尋郵件追蹤" />
      <Layout.RowSeparator />
      <Row>
        { mailtrackers.length <= 0 && (
          ( isSearching() ? 
          <Col xs={12}>沒有找到符合「{ state.searchKeyword }」的資料</Col>
          :
            <Col xs={12}>沒有資料</Col>
          )
        )}
      </Row>
      <Layout.ColumnGallery hasMore={hasMore} onLoadMore={handleLoadMore}>
        <Row>
          { mailtrackers.map((mailtracker: Model.IMailTracker, index: number) => (
            <Col key={mailtracker.mailtrackerid} className="mt-2 mb-2" xs={12} lg={6}>
              <Layout.IndexOf index={index} filteredListCount={mailtrackers.length} wholeListCount={state.offlineDatas.count()} hasMore={state.hasMore} />
              <Item {...props} mailtracker={mailtracker} />
            </Col>
          ))}
        </Row>
      </Layout.ColumnGallery>
    </Container>
  )
}

export default withAppContext(PageListComp)