import { List } from 'immutable'
import * as React from 'react'

import * as Layout from '../layout'
import ValidatorManager from './Manager'
import ValidatorRules from './Rules'

export interface IBaseValidatorStates {
  errorMessages: List<string>
  hasError: boolean
}
export interface IBaseValidatorProps {
  didinvalidate?: (name: string, errorMessages: JSX.Element) => void
  name: string
  value: string | string[] | number | boolean
  rules: ValidatorRules
  manager: ValidatorManager
}

export default class BaseValidator<P = IBaseValidatorProps, S = IBaseValidatorStates, SS = any> extends React.Component<P, S, SS> {
  public render() {
    return (
      <span>[WARNING] Component not yet implemented.</span>
    )
  }

  public performValidation = (value: any): boolean => {
    return false
  }

  protected renderErrorMessages = (errorMessages: List<string>) => (
    <Layout.ErrorMessages errorMessages={errorMessages} />
  )
}