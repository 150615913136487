import * as React from 'react'
import { Col, Row } from 'reactstrap'

import './RowSeparator.css'

const RowSeparator: React.StatelessComponent<{}> = () => {
  return (
    <Row className="row-separator">
      <Col xs={12} />
    </Row>
  )
}

export default RowSeparator