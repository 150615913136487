import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'

import * as Model from '../../models'
import * as Layout from '../layout'

import './Tag.css'

interface IProps {
  key?: string;
  canRemove?: boolean;
  tag: Model.ITag
  onRemove?: (tag: Model.ITag) => void
  className?: string
}

const Tag: React.SFC<IProps> = (props) => {
  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.tag)
    }
  }

  const canRemove = props.canRemove || false

  return (
    <span className={`float-left tag ${props.className || ''}`}>
      <FontAwesome name="tag" className="icon" />
      <small>{props.tag.name}</small>
      { canRemove &&
        <span className="button" onClick={handleRemove}>
          <FontAwesome name="trash" />
        </span>
      }
      <Layout.Clearfix />
    </span>
  )
}

export default Tag