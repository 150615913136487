import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

// import StockList from '../../page/stock/StockList'
import BatchPageList from '../batch/PageList'
import GenericNotFound from '../error/GenericNotFound'
import Dashboard from '../home/Dashboard'
import LogoutForm from '../login/LogoutForm'
import MailTrackerPageList from "../mailtracker/PageList"
import OldProductPageList from "../oldproduct/PageList"
import OrderBatchIdRedirect from '../order/OrderBatchIdRedirect'
import OrderPageList from '../order/PageList'
import PresetTagPageList from '../presettag/PageList'
import ProductPageList from '../product/PageList'
import PrivateRoute from './PrivateRoute'

export const Main = () => {
  return (
    <div className="App-content-container">
      <Switch>
        {/* Dashboard */}
        <PrivateRoute exact={true} path="/" component={Dashboard} />
        {/* Order */}
        <PrivateRoute exact={true} path="/order" component={OrderBatchIdRedirect} />
        <PrivateRoute path="/order/:batchid" component={OrderPageList} />
        {/* OldProduct */}
        <PrivateRoute exact={true} path="/oldproduct" component={OldProductPageList} />
        {/* Stock */}
        {/* <PrivateRoute exact path="/stock" component={StockList} /> */}
        {/* Batch */}
        <PrivateRoute exact={true} path="/batch" component={BatchPageList} />
        {/* MailTracker */}
        <PrivateRoute exact={true} path="/mailtracker" component={MailTrackerPageList} />
        {/* PresetTag */}
        <PrivateRoute exact={true} path="/presettag" component={PresetTagPageList} />
        {/* Product */}
        <PrivateRoute exact={true} path="/product" component={ProductPageList} />
        {/* Logout */}
        <PrivateRoute exact={true} path="/logout" component={LogoutForm} />
        <Route component={GenericNotFound} />
      </Switch>
    </div>
  )
}

export default Main