import * as React from 'react'
import './IndexOf.css'

interface IProps {
  index: number
  filteredListCount: number
  wholeListCount: number
  hasMore?: boolean
}

const RowSeparator: React.SFC<IProps> = (props) => {
  if(props.wholeListCount === props.filteredListCount) {
    return (
      <div className="indexof">
        #{props.index + 1} / {props.wholeListCount}{props.hasMore && ("+")}
      </div>
    )
  }

  return (
    <div className="indexof">
      #{props.index + 1} / {props.filteredListCount} ({ props.wholeListCount }{props.hasMore && ("+")})
    </div>
  )
}

export default RowSeparator