import api, { downloadAxios } from '.'
import * as Model from '../models'

interface IGetPagedReturnType { orders: Model.IOrder[], pagesize: number }
export const getPaged = (batchid: string, page: number) => {
  return api.get(`/api/order/${batchid}/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const get = (orderid: string) => {
  return api.get(`/api/order/id/${orderid}`).then(response => 
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const create = (data: Model.Form.IOrder) => {
  return api.post(`/api/order/create`, data).then(response =>
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const update = (data: Partial<Model.Form.IOrder>) => {
  return api.post(`/api/order/update`, data).then(response =>
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const downloadExcel = (batchid: string) => {
  // return `/api/order/${batchid}/exportcsv`
  downloadAxios.get(`/api/order/${batchid}/exportcsv`).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${batchid}.xlsx`)
    document.body.appendChild(link)
    link.click()
  })
}

export const assignAddress = (orderid: string, addressid: string) => {
  return api.post(`/api/order/address`, { orderid, addressid }).then(response => 
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const assignNewAddress = (orderid: string, customerid: string, shippingmethod: string, name: string, phone: string, addressdetail: string) => {
  return api.post(`/api/order/address/new`, { orderid, customerid, shippingtype: shippingmethod, name, phone, addressdetail }).then(response =>
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const unassignAddress = (orderid: string) => {
  return api.post(`/api/order/address/clear`, { orderid }).then(response => 
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}

export const assignShippingMethod = (orderid: string, shippingmethod: string) => {
  return api.post(`/api/order/shippingmethod`, { orderid, shippingmethod }).then(response =>
    response.data.order as Model.IOrder
  ) as Promise<Model.IOrder>
}