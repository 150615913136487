import * as React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import MailTrackerEditForm from './FormEdit'

interface IProps extends IAppContextProps {
  mailtracker: Model.IMailTracker
}

const Item: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getMailTrackerState()
  const action = props.appcontext.getMailTrackerAction()
  
  const mailtracker = props.mailtracker
  const updateMailTracker = state.updateStatuses[mailtracker.mailtrackerid]

  const handleChangeToEdit = () => {
    action.openEditForm(props.mailtracker.mailtrackerid)
  }

  return (
    <div>
      { updateMailTracker !== undefined && (
        <MailTrackerEditForm {...props} formTitle={`編輯 ${props.mailtracker.mailtrackerid}`} mailtracker={mailtracker} errorMessage={updateMailTracker.errorMessage} />
      )}
      <Card>
        <CardHeader>
          <Layout.Clearfix>
            <Layout.CalendarDate>{ mailtracker.shipdate }</Layout.CalendarDate> <Layout.BadgeId>{ mailtracker.mailtrackerid }</Layout.BadgeId>
            <div className="pull-right">
              <Button color="primary" outline={true} onClick={handleChangeToEdit}>編輯</Button>
            </div>
          </Layout.Clearfix>
        </CardHeader>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12}>
              { mailtracker.description }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                運費 { mailtracker.shippingfee }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                重量 { mailtracker.weight }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                { mailtracker.isdelivered ? (
                  <div className="text-success">已到達</div>
                ) : (
                  <div className="text-warning">未到達</div>
                )}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </div>
  )
}

export default withAppContext(Item)