import { ShippingMethodKeys } from ".";

// Address

export interface IAddress {
  addressdetail: string
  addressid: string
  customerid: string
  name: string
  phone: string
  shippingtype: ShippingMethodKeys | ""
}
export function EmptyAddress(): IAddress {
  return {
    addressdetail: "",
    addressid: "",
    customerid: "",
    name: "",
    phone: "",
    shippingtype: "",
  }
}