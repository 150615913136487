import { List } from 'immutable'

export const listUnion = <T>(list1: List<T>, list2: List<T>, getKey:(item: T)=>string): List<T> => {
  let list = List<T>()
  const screen = {}

  list1.forEach((item: T) => {
    list = performInsertOrUpdate(screen, list, item, getKey)
    screen[getKey(item)] = getKey(item)
  })
  list2.forEach((item: T) => {
    list = performInsertOrUpdate(screen, list, item, getKey)
    screen[getKey(item)] = getKey(item)
  })
  return list
}

const performInsertOrUpdate = <T>(screen: {}, list: List<T>, item: T, getKey:(item: T)=>string): List<T> => {
  if (screen[getKey(item)] === undefined) {
    // Not exist, push
    return list.push(item)
  } else {
    // Exists, perform update
    const index = list.findIndex((existItem: T) => {
      return getKey(existItem) === getKey(item)
    })
    return list.set(index, item)
  }
}