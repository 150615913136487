import * as React from 'react'

import * as ShippingHandling from '../../handling/shipping'
import * as Model from '../../models'

interface IShippingMethodLabelProps {
  shippingmethod: Model.ShippingMethodKeys | ""
}

const ShippingMethodLabel: React.SFC<IShippingMethodLabelProps> = (props) =>{
  const mapDisplayText = (value: Model.ShippingMethodKeys | "") => {
    if (value && ShippingHandling.availableShippingMethod.hasOwnProperty(value)) {
      return ShippingHandling.availableShippingMethod[value]
    }
    
    return "未定"
  }

  return (
    <span>{mapDisplayText(props.shippingmethod)}</span>
  )
}

export default ShippingMethodLabel