import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import PresetTagForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
}

const FormCreate: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getPresetTagState()
  const action = props.appcontext.getPresetTagAction()

  const handleCreate = (presettag: Model.IPresetTag) => {
    action.createRequest(presettag)
  }

  const handleCancel = () => {
    action.closeCreateForm()
  }

  return (
    <PresetTagForm isOpen={state.createStatus.isFormOpen} formTitle={props.formTitle} isCreate={true} presettag={state.createStatus.data} onCreate={handleCreate} onCancel={handleCancel} errorMessage={state.createStatus.errorMessage} isLoading={state.createStatus.isSubmitting} />
  )
}

export default withAppContext(FormCreate)