import * as React from 'react'

import * as Model from '../../models'
import * as Layout from '../layout'
import PrimeCostLabel from './PrimeCostLabel'

interface IProps {
  variance: Model.IVariance
}

const Variance: React.SFC<IProps> = (props) => {
  const { variance } = props

  return (
    <li>
      { variance.name !== undefined && variance.name !== null && variance.name !== "" && 
        (<span><strong>{variance.name}</strong> - </span>)
      }
      <Layout.DollarSign sign="HKD">{variance.sellingprice}</Layout.DollarSign>
      <span className="text-muted">
        {' '}<PrimeCostLabel variance={variance} bracket={true} />
      </span>
    </li>
  )
}

export default Variance