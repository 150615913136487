import axios from 'axios'
import * as NProgress from "nprogress"

import "nprogress/nprogress.css"
import "./nprogress.css"

import { clearJwt, getJwt } from '../auth'
// import history from '../history'
import * as addresses from "./addresses"
import * as batches from './batches'
import * as customers from './customers'
import * as deposits from './deposits'
import * as mailtrackers from './mailtrackers'
import * as oldproducts from "./oldproducts"
import * as orders from './orders'
import * as payments from './payments'
import * as presettags from './presettags'
import * as products from './products'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const apiAxios = axios.create({
  baseURL: BASE_URL,
  headers: { 'x-access-token': `${getJwt()}` },
  responseType: 'json'
})
apiAxios.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiAxios.interceptors.response.use(response => {
  /* Standard response
    // Success
    {
      success: true
      data: { AnyObject }
    }
    // Fail
    {
      success: false
      message: "String"
    }
  */
  NProgress.done()
  if (response.data.success) {
    return response.data.data
  } else {
    // console.error(response.data.message)
    return Promise.reject(response.data.message)
  }
}, (error) => {
  NProgress.done(true)
  
  // console.error(error)
  if (error && error.response && error.response.status) {
    if (error.response.status === 403
      || error.response.status === 401) {
      // 401 = Unauthorized
      // 403 = Forbidden
      clearJwt()
      // window.location.href = '/login'
    }
    //  else if (error.response.status === 500) {
    //   // alert(error.message)
    //   history.push('/error/500')
    // }

    if (error.response.data) {
      error.data = error.response.data
    }
  }

  return Promise.reject(error)
})

const downloadAxios = axios.create({
  baseURL: BASE_URL,
  headers: { 'x-access-token': `${getJwt()}` },
  responseType: 'blob'
})

export default apiAxios
export { addresses, batches, customers, deposits, downloadAxios, mailtrackers, oldproducts, orders, payments, presettags, products }