import * as React from 'react'
import { Input } from 'reactstrap'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

interface IProps {
  className?: string
  value: string
  delay?: number
  placeholder: string
  onChange?: (value: string) => void
  onDelayTriggered?: (value: string) => void
}

interface IState {
  value: string
}

export default class DelayTextbox extends React.Component<IProps, IState> {
  private timer: NodeJS.Timer | undefined
  private delay: number

  constructor(props: IProps) {
    super(props)

    this.timer = undefined
    this.delay = props.delay || WAIT_INTERVAL

    this.state = {
      value: props.value || "",
    }
  }

  public render() {
    return (
      <Input
        className={this.props.className}
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
      />
    )
  }

  private handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault()
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.triggerChange()
    }
  }

  private triggerChange = () => {
    const { value } = this.state;

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  private triggerDelayTimeout = () => {
    if (this.props.onDelayTriggered) {
      const { value } = this.state;

      this.props.onDelayTriggered(value);
    }
  }

  private handleChange = (event: any) => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    const value = event.target.value
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ value }, () => {
      this.timer = setTimeout(this.triggerDelayTimeout, this.delay)
    })
  }
}