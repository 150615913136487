import * as _ from 'lodash'
import * as React from 'react'
import { Table } from 'reactstrap'

import * as Model from '../../models'
import * as Layout from '../layout'
import PaymentChannelLabel from './PaymentChannelLabel'


interface IPaymentHistoryProps {
  payments: Model.IPayment[]
}
const PaymentHistory: React.StatelessComponent<IPaymentHistoryProps> = ({ payments }) => {
  if (_.isEmpty(payments)) {
    return <div>沒有記錄</div>
  }
  return (
    <Table>
      <thead>
        <tr>
          <th>日期</th>
          <th>入數方法</th>
          <th>金額</th>
        </tr>
      </thead>
      <tbody>
        { payments.map((payment, index) => (
          <tr key={payment.paymentid}>
            <td>
              <Layout.CalendarDate format="YYYY-MM-DD HH:mm">{payment.datecreated}</Layout.CalendarDate>
            </td>
            <td>
              <PaymentChannelLabel>{payment.deposit.channel}</PaymentChannelLabel>
            </td>
            <td>
              <Layout.DollarSign sign="$" empty="-">{payment.amount}</Layout.DollarSign>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default PaymentHistory