import * as Model from '..'

// Customer

export interface ICustomer {
  channel: string
  customerid: string
  isnew?: boolean
  name: string
}
export function ConvertFromCustomerModel(order: Model.ICustomer): ICustomer {
  return {
    channel: order.channel,
    customerid: order.customerid,
    isnew: false,
    name: order.name,
  }
}
export function EmptyCustomer(): ICustomer {
  return {
    channel: "ig",
    customerid: "",
    isnew: undefined,
    name: "",
  }
}