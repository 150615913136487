import * as React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import CustomerChannelLabel from './CustomerChannelLabel'

interface ICustomerChannelDropdownProps {
  value?: string
  onChange: (channel: string) => void
  color?: string
}
interface ICustomerChannelDropdownState {
  dropdownOpen: boolean
  selectedValue: string
}

class CustomerChannelDropdown extends React.Component<ICustomerChannelDropdownProps, ICustomerChannelDropdownState> {
  constructor(props: ICustomerChannelDropdownProps) {
    super(props)
    
    this.state = {
      dropdownOpen: false,
      selectedValue: this.props.value || "ig",
    }

    this.dropdownToggle = this.dropdownToggle.bind(this)
  }

  public render() {
    const selectedValue = this.state.selectedValue

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle}>
        <DropdownToggle color={this.props.color || "secondary"} caret={true}>
          <CustomerChannelLabel noBadge={true} value={selectedValue} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.handleDropdownSelect.bind(this, 'ig')}><CustomerChannelLabel noBadge={true} value="ig" /></DropdownItem>
          <DropdownItem onClick={this.handleDropdownSelect.bind(this, 'fb')}><CustomerChannelLabel noBadge={true} value="fb" /></DropdownItem>
          <DropdownItem onClick={this.handleDropdownSelect.bind(this, 'whatsapp')}><CustomerChannelLabel noBadge={true} value="whatsapp" /></DropdownItem>
          <DropdownItem onClick={this.handleDropdownSelect.bind(this, 'email')}><CustomerChannelLabel noBadge={true} value="email" /></DropdownItem>
          <DropdownItem onClick={this.handleDropdownSelect.bind(this, 'other')}><CustomerChannelLabel noBadge={true} value="other" /></DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  private handleDropdownSelect = (value: string, event: any) => {
    const selectedValue = value

    this.setState({ selectedValue }, () => {
      this.props.onChange(this.state.selectedValue)
    })
  }

  private dropdownToggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }
}

export default CustomerChannelDropdown