import * as Model from '.'

// Preset Tag

export interface IPresetTag {
  name: string
  presettagid: string
  tags: Model.ITag[]
}
export function EmptyPresetTag(): IPresetTag {
  return {
    name: '',
    presettagid: '',
    tags: []
  }
}