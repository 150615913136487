import * as React from 'react'
import { Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../../context/AppContext"
import * as Model from '../../../models'
import * as Layout from '../../layout'
import './index.css'

interface IProps extends IAppContextProps {
  defaultValue: string
  onBatchChanged: (batch: Model.IBatch) => void
}

interface IState {
  isOpen: boolean
}

export class BatchDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  public componentDidUpdate() {
    const action = this.props.appcontext.getBatchAction()
    const batches = action.datasForDisplay()

    if (batches.count() === 0) {
      return this.handleLoadMore()
    }
    if (this.props.defaultValue && batches.find(batch => batch !== undefined && batch.batchid === this.props.defaultValue) === undefined) {
      return this.handleLoadMore()
    }
  }

  public render() {
    const state = this.props.appcontext.getBatchState()
    const action = this.props.appcontext.getBatchAction()
  
    const batches = action.datasForDisplay()
    const { hasMore }  = state

    const selectedBatch = action.getByBatchId(this.props.defaultValue, false)

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle caret={true}>
          { selectedBatch ? selectedBatch.batchname : "請選擇" }
        </DropdownToggle>
        <DropdownMenu className="scrollable-dropdown-menu">
          <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore} notUseWindow={true}>
            <Container>
              <Row>
                { batches.map((batch: Model.IBatch, index: number) => (
                  <Col key={batch.batchid} xs={12} md={6} lg={4}>
                    <div className="item" onClick={this.handleClick.bind(this, batch)}>
                      {batch.batchname} <Layout.BadgeId>{ batch.batchid }</Layout.BadgeId>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Layout.ColumnGallery>
        </DropdownMenu>
      </Dropdown>
    )
  }

  private toggle = () => {
    const isOpen = !this.state.isOpen
    this.setState({isOpen})
  }

  private handleLoadMore = () => {
    const state = this.props.appcontext.getBatchState()
    const action = this.props.appcontext.getBatchAction()
  
    const { isFetching, lastFetchedPage, hasMore } = state

    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  private handleClick(batch: Model.IBatch, event: any) {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onBatchChanged(batch)
    })
  }
}

export default withAppContext(BatchDropdown)