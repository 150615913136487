import * as FormComponent from "../form";
import * as Layout from "../layout";
import * as Model from "../../models";
import * as React from "react";

import { ButtonGroup, FormGroup, Input, Label } from "reactstrap";

import ShippingMethodLabel from "./ShippingMethodLabel";

interface IShippingMethodControlProps {
  name: string;
  value: string;
  order: Model.Form.IOrder;
  // fee: {
  //   [k in Model.ShippingMethodKeys]: number
  // },
  onShippingMethodChange: (
    shippingmethod: Model.ShippingMethodKeys | ""
  ) => void;
  onShippingFeeChange: (
    shippingmethod: Model.ShippingMethodKeys | "",
    shippingfee: number
  ) => void;
}
interface IShippingMethodControlState {
  selectedValue: string;
  fee: {
    [k in Model.ShippingMethodKeys]: number;
  };
}

class ShippingMethodControl extends React.Component<
  IShippingMethodControlProps,
  IShippingMethodControlState
> {
  constructor(props: IShippingMethodControlProps) {
    super(props);

    this.state = {
      fee: {
        airmail: props.order.airmail || 0,
        ems: props.order.ems || 0,
        face: 0,
        sal: props.order.sal || 0,
        sfcustomer: props.order.sfcustomer || 0,
        sfwepay: 0,
        ship: props.order.ship || 0,
        surface: props.order.surface || 0,
        surfaceregistered: props.order.surfaceregistered || 0,
      },
      selectedValue: this.props.value,
    };
  }

  public render() {
    const selectedValue = this.state.selectedValue;

    return (
      <FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value=""
              checked={selectedValue === ""}
            />{" "}
            <ShippingMethodLabel shippingmethod="" />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="face"
              checked={selectedValue === "face"}
            />{" "}
            <ShippingMethodLabel shippingmethod="face" />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="surface"
              checked={selectedValue === "surface"}
            />{" "}
            <ShippingMethodLabel shippingmethod="surface" />{" "}
            <Layout.DollarSign sign="$">
              {this.state.fee.surface}
            </Layout.DollarSign>
            <Input
              type="number"
              name="surface"
              value={this.state.fee.surface}
              step="0.1"
              onChange={this.handleFeeChange}
            />
            <ButtonGroup>
              <FormComponent.Button onClick={this.handleSurfaceFee} value="5.6">
                <Layout.DollarSign sign="$">5.6</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button onClick={this.handleSurfaceFee} value="8.2">
                <Layout.DollarSign sign="$">8.2</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button
                onClick={this.handleSurfaceFee}
                value="15.5"
              >
                <Layout.DollarSign sign="$">15.5</Layout.DollarSign>
              </FormComponent.Button>
            </ButtonGroup>
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="surfaceregistered"
              checked={selectedValue === "surfaceregistered"}
            />{" "}
            <ShippingMethodLabel shippingmethod="surfaceregistered" />{" "}
            {this.state.fee.surfaceregistered > 0 && (
              <Layout.DollarSign sign="$">
                {this.state.fee.surfaceregistered}
              </Layout.DollarSign>
            )}
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="sfcustomer"
              checked={selectedValue === "sfcustomer"}
            />{" "}
            <ShippingMethodLabel shippingmethod="sfcustomer" />{" "}
            <Layout.DollarSign sign="$">
              {this.state.fee.sfcustomer}
            </Layout.DollarSign>
            <Input
              type="number"
              name="sfcustomer"
              value={this.state.fee.sfcustomer}
              onChange={this.handleFeeChange}
            />
            <ButtonGroup>
              <FormComponent.Button
                onClick={this.handleSfcustomerFee}
                value="15"
              >
                <Layout.DollarSign sign="$">15</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button
                onClick={this.handleSfcustomerFee}
                value="20"
              >
                <Layout.DollarSign sign="$">20</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button
                onClick={this.handleSfcustomerFee}
                value="22"
              >
                <Layout.DollarSign sign="$">22</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button
                onClick={this.handleSfcustomerFee}
                value="28"
              >
                <Layout.DollarSign sign="$">28</Layout.DollarSign>
              </FormComponent.Button>
              <FormComponent.Button
                onClick={this.handleSfcustomerFee}
                value="34"
              >
                <Layout.DollarSign sign="$">34</Layout.DollarSign>
              </FormComponent.Button>
            </ButtonGroup>
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="sfwepay"
              checked={selectedValue === "sfwepay"}
            />{" "}
            <ShippingMethodLabel shippingmethod="sfwepay" />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="ems"
              checked={selectedValue === "ems"}
            />{" "}
            <ShippingMethodLabel shippingmethod="ems" />{" "}
            <Layout.DollarSign sign="$">{this.state.fee.ems}</Layout.DollarSign>
            <Input
              type="number"
              name="ems"
              value={this.state.fee.ems}
              onChange={this.handleFeeChange}
            />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="airmail"
              checked={selectedValue === "airmail"}
            />{" "}
            <ShippingMethodLabel shippingmethod="airmail" />{" "}
            <Layout.DollarSign sign="$">
              {this.state.fee.airmail}
            </Layout.DollarSign>
            <Input
              type="number"
              name="airmail"
              value={this.state.fee.airmail}
              onChange={this.handleFeeChange}
            />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="sal"
              checked={selectedValue === "sal"}
            />{" "}
            <ShippingMethodLabel shippingmethod="sal" />{" "}
            <Layout.DollarSign sign="$">{this.state.fee.sal}</Layout.DollarSign>
            <Input
              type="number"
              name="sal"
              value={this.state.fee.sal}
              onChange={this.handleFeeChange}
            />
          </Label>
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true}>
            <Input
              type="radio"
              name="shippingGroup"
              onChange={this.handleShippingMethodChange}
              value="ship"
              checked={selectedValue === "ship"}
            />{" "}
            <ShippingMethodLabel shippingmethod="ship" />{" "}
            <Layout.DollarSign sign="$">
              {this.state.fee.ship}
            </Layout.DollarSign>
            <Input
              type="number"
              name="ship"
              value={this.state.fee.ship}
              onChange={this.handleFeeChange}
            />
          </Label>
        </FormGroup>
      </FormGroup>
    );
  }

  private handleShippingMethodChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedValue: value });
    this.props.onShippingMethodChange(value);
  };

  private handleFeeChange = (event: any) => {
    const name: Model.ShippingMethodKeys = event.target.name;
    const value = Number(event.target.value);
    const fee = this.state.fee;

    fee[name] = value;
    if (name === "surface") {
      const surfaceregisteredfee = value + 15.5;
      fee.surfaceregistered = surfaceregisteredfee;
      this.props.onShippingFeeChange("surfaceregistered", surfaceregisteredfee);
    }
    this.setState({ fee });
    this.props.onShippingFeeChange(name, value);
  };

  private handleSurfaceFee = (value: string) => {
    const name = "surface";
    // let fee = this.state.fee
    // fee[name] = +value
    // this.setState({ fee })
    this.handleFeeChange({
      target: { name, value },
    });
  };

  private handleSfcustomerFee = (value: string) => {
    const name = "sfcustomer";
    // let fee = this.state.fee
    // fee[name] = +value
    // this.setState({ fee })
    this.handleFeeChange({
      target: { name, value },
    });
  };
}

export default ShippingMethodControl;
