import * as React from 'react'
import { Button, ButtonGroup, Input } from 'reactstrap'

/* tslint:disable no-var-requires*/
const BlockUI = require('react-block-ui').default
/* tslint:enable no-var-requires*/

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import { paymentMethods } from './PaymentChannelLabel'
import PaymentDropdown from './PaymentDropdown'
import PaymentHistory from './PaymentHistory'

import './PaymentPanel.css'

interface IProps extends IAppContextProps {
  order: Model.IOrder
  // onPaymentDone: Function
}
interface IPaymentPanelState {
  input: {
    channel: string,
    depositamount: number,
    remarks: string
  }
}

class PaymentPanel extends React.Component<IProps, IPaymentPanelState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      input: {
        channel: paymentMethods[0].value,
        depositamount: 0,
        remarks: "",
      }
    }
  }

  public render() {
    const state = this.props.appcontext.getOrderState()

    const order = this.props.order
    const updatePaymentStatus = state.updatePaymentStatuses[this.props.order.orderid]
    const isAsyncLoading = updatePaymentStatus ? updatePaymentStatus.isSubmitting : false

    return (
      <BlockUI tag="div" blocking={isAsyncLoading} loader={<Layout.Loader />}>
        <PaymentHistory payments={order.payments} />
        <ButtonGroup className="payment-dropdown">
          <PaymentDropdown onChange={this.handlePaymentMethodChange} />
          <Input type="number" value={this.state.input.depositamount} onChange={this.handleDepositChange} />
          <Button type="button" onClick={this.handleDepositSubmit}>入數</Button>
        </ButtonGroup>
      </BlockUI>
    )
  }

  private handleDepositChange = (event: any) => {
    const depositamount = event.target.value
    const input = this.state.input

    input.depositamount = depositamount
    this.setState({ input })
  }

  private handleDepositSubmit = (event: any) => {
    const action = this.props.appcontext.getOrderAction()
    
    const order = this.props.order
    const customerid = order.customerid
    const orderid = order.orderid

    const { input } = this.state
    action.depositPaymentRequest(customerid, input.channel, input.depositamount, input.remarks, orderid, input.depositamount)
  }

  private handlePaymentMethodChange = (paymentMethod: any) => {
    const input = this.state.input
    input.channel = paymentMethod.value

    this.setState({input})
  }
}

export default withAppContext(PaymentPanel)