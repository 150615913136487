import { List, Map } from 'immutable'
import * as Validator from '.'

export default class ValidationRules {
  private errorMessageElements: Map<string, JSX.Element> = Map()
  private validators: List<Validator.AnyValidator> = List()

  public addErrorMessage = (name: string, element: JSX.Element) => {
    this.errorMessageElements = this.errorMessageElements.set(name, element)
  }

  public getErrorMessage = (name: string): JSX.Element => {
    return this.errorMessageElements.get(name)
  }

  public addValidator = (validator: Validator.AnyValidator) => {
    this.validators = this.validators.push(validator)
  }

  public validateAll = (): boolean => {
    const errors = this.validators.filter((validator: Validator.AnyValidator) => {
      // Filter out the validator which HAS NO ERROR
      return validator.performValidation(validator.props.value)
    })

    return errors.count() <= 0
  }
}