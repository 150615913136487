import * as _ from 'lodash'
import * as moment from 'moment'

import * as Model from '../../models'
// import * as PaymentHandling from '../payment'

// export const paymentCompleted = (order: Model.IOrder) => hasOrder(order) && isFullPaid(order)

// export const paymentCompletedAndExceeded = (order: Model.IOrder) => paymentCompleted && totalOrderPaymentRemain(order) < 0

// export const paymentIncompleted = (order: Model.IOrder) => hasOrder(order) && isPartialPaid(order) && PaymentHandling.hasPayment(order.payments)

// export const hasOrder = (order: Model.IOrder) => totalOrderAmountWithShipping(order) > 0

// export const isFullPaid = (order: Model.IOrder) => totalOrderPaymentRemain(order) <= 0

// export const isPartialPaid = (order: Model.IOrder) => totalOrderPaymentRemain(order) > 0

// export const totalProductAmount = (products: Model.IOrderProduct[]) => {
//   let totalAmount = 0
  
//   _.each(products, (orderproduct: Model.IOrderProduct) => {
//     totalAmount += +orderproduct.amount * +orderproduct.sellingprice
//   })
  
//   return totalAmount
// }

// export const totalOrderAmountWithShipping = (order: any, beforediscount: boolean = false) => {
//   let totalAmount = 0
  
//   totalAmount += totalProductAmount(order.products)
  
//   if (order.shippingmethod !== undefined && order.shippingmethod !== "" && order.shippingmethod !== "sfcustomer") {
//     const shippingFee = order[order.shippingmethod] || 0
//     totalAmount += +shippingFee
//   }
  
//   if (!beforediscount) {
//     if (+order.discountprice > 0) {
//       totalAmount -= order.discountprice
//     }
//   }
  
//   return totalAmount
// }

// export const totalOrderPaymentRemain = (order: Model.IOrder) => {
//   const totalOrderAmount = totalOrderAmountWithShipping(order, false)
//   const totalPaymentAmount = PaymentHandling.paymentTotalAmount(order.payments)
  
//   return totalOrderAmount - totalPaymentAmount
// }

export const orderFormText = (order: Model.IOrder) => {
  const products: string[] = []
  _.each(order.orderproducts, (product: Model.IOrderProduct) => {
    let productNameWithVariance = product.detail.name
    if (product.variance && product.variance.name !== undefined && product.variance.name !== null && product.variance.name !== "") {
      productNameWithVariance += `(${product.variance.name})`
    }
    products.push(`${productNameWithVariance} $${product.sellingprice} ${product.amount > 1 ? ` x ${product.amount} = ${product.sellingprice * product.amount}` : ""}`)
  })
  let productTotalPrice = 0
  _.each(order.orderproducts, (product: Model.IOrderProduct) => {
    productTotalPrice += +product.sellingprice * +product.amount
  })
  const discountPrice = +order.discountprice
  const totalPriceAfterDiscount = productTotalPrice - order.discountprice

  const shippingMethodNotSelected = (order.shippingmethod === "")
  const shippingMethods = []
  if (+order.surface > 0 && (shippingMethodNotSelected || order.shippingmethod === "surface")) {
    shippingMethods.push(`平郵** $${+order.surface} (入數總額 $${totalPriceAfterDiscount + +order.surface})`)
  }
  if (+order.surfaceregistered > 0 && (shippingMethodNotSelected || order.shippingmethod === "surfaceregistered")) {
    shippingMethods.push(`掛號 $${+order.surfaceregistered} (入數總額 $${totalPriceAfterDiscount + +order.surfaceregistered})`)
  }
  if (+order.sfcustomer > 0 && (shippingMethodNotSelected || order.shippingmethod === "sfcustomer")) {
    shippingMethods.push(`順豐自取或指定地址上門 (運費到付) (入數總額 $${totalPriceAfterDiscount})`)
  }
  if (order.shippingmethod === "sfwepay") {
    shippingMethods.push(`順豐自取或指定地址上門 (入數總額 $${totalPriceAfterDiscount})`)
  }
  if (shippingMethodNotSelected || order.shippingmethod === "face") {
    shippingMethods.push(`黃埔站面交 (CD出口，可不出閘) (入數總額 $${totalPriceAfterDiscount})`)
  }

  if (+order.surface > 0 && (shippingMethodNotSelected || order.shippingmethod === "surface")) {
    shippingMethods.push("\n**平郵會寫回郵地址，但不包寄失風險。")
  }
  

  const cutoffdate = moment().add(2, 'days').isBefore(order.batch.cutoffdate) ? moment().add(2, 'days').toDate() : order.batch.cutoffdate

  const text = `謝謝您支持~^^
以下係您嘅訂單資料

▪訂購貨品:
${products.join("\n")}

▪貨品總額:
$${productTotalPrice}${discountPrice > 0 ? ` - $${discountPrice} = $${totalPriceAfterDiscount}` : ""}

▪收貨方式:
${shippingMethods.join("\n")}

▪今團收貨日期:
🌟${moment(order.batch.receivedatefrom).format("MM月DD日")}~${moment(order.batch.receivedateto).format("MM月DD日")}

▪入數方法:
▫請於${moment(cutoffdate).format("MM月DD日20:00")}前完成入數
▫入數後，請提供入數紙或網上付款完成版面
▫如郵寄，請提供收貨人姓名、聯絡電話及收貨地址/順豐或智能櫃編號

1. Bank of China (中銀)
012-349-1-0093965
Lam Y** Y* J***Y

2. HSBC (滙豐)
591-3-016951
Lam Y** Y* J***Y

3. PayMe
請經以下QR Code 付款後提供付款完成版面

4.轉數快 ID :1170166
Lam Y** Y* J***Y

5.PayPal(手續費5%)詳情可以向我地查詢`

  return text
}