import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { clearJwt } from '../../auth'

const LogoutForm: React.SFC<{}> = (props) => {
  clearJwt()
  
  return (
    <Redirect to="/login" />
  )
}

export default LogoutForm