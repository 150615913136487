import * as Model from '../../models'

export const availableShippingMethod: Model.AvailableShippingMethod = {
  airmail: "空郵",
  ems: "EMS",
  face: "面交",
  sal: "SAL",
  sfcustomer: "順豐(到付)",
  sfwepay: "順豐(寄付)",
  ship: "船",
  surface: "平郵",
  surfaceregistered: "掛號"
}

export const shippingMethodIsUnknown = (value: Model.ShippingMethodKeys | "") => !availableShippingMethod.hasOwnProperty(value)