import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { batches: Model.IBatch[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/batch/page/${page}`).then(response =>
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const create = (data: Model.IBatch) => {
  return api.post(`/api/batch/create`, data).then(response =>
    response.data.batch as Model.IBatch
  ) as Promise<Model.IBatch>
}

export const update = (data: Model.IBatch) => {
  return api.post(`/api/batch/update`, data).then(response =>
    response.data.batch as Model.IBatch
  ) as Promise<Model.IBatch>
}