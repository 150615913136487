import * as React from 'react'

export const Clearfix: React.StatelessComponent<{}> = (props) => {
  return (
    <div className="clearfix">
      { props.children }
    </div>
  )
}

export default Clearfix