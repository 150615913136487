import { List } from "immutable"
import * as React from 'react'
import { Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap'

import * as LoginApi from '../../api/login'
import { setJwt } from '../../auth'
import * as Layout from "../layout"
import * as Validator from '../validator'

import './LoginForm.css'

interface IFormValue {
  loginname: string
  password: string
}

interface IState {
  errorMessages: List<string>
  formValue: IFormValue
  isLoading: boolean
  validatorManager: Validator.Manager
}

export class LoginForm extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      errorMessages: List(),
      formValue: {
        loginname: "",
        password: "",
      },
      isLoading: false,
      validatorManager: new Validator.Manager(),
    }
  }

  public render() {
    const formValue = this.state.formValue

    return (
      <Container className="App-Login">
        <Row>
          <Col xs={12}>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>Login Name</Label>
                <Validator.Input type="text" name="loginname" value={formValue.loginname} didchanged={this.handleChanged}
                  rules={new Validator.Rules().required("Please input login name")}
                  manager={this.state.validatorManager}
                  />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Validator.Input type="password" name="password" value={formValue.password} didchanged={this.handleChanged}
                  rules={new Validator.Rules().required("Please input password")}
                  manager={this.state.validatorManager}
                  />
              </FormGroup>
              <Layout.ErrorMessages errorMessages={this.state.errorMessages} />
              <Button type="submit" className="pull-right">確定</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }

  private submitLogin = (loginname: string, password: string) => {
    LoginApi.verifyLogin(loginname, password).then((token: string) => {
      setJwt(token)
      window.location.replace('/')
    }).catch(error => {
      this.setState({
        errorMessages: List([error.data.message])
      })
    })
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    const formValue = this.state.formValue
    this.submitLogin(formValue.loginname, formValue.password)
  }

  private handleChanged = (name: string, value: string) => {
    const formValue = this.state.formValue
    formValue[name] = value

    this.setState({formValue})
  }
}

export default LoginForm