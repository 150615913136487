import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInString, searchInStrings } from "./AppContext"

import * as api from "../api"
import * as Model from "../models"

export interface IPresetTagContext {
  // Offline Data
  offlineDatas: List<Model.IPresetTag>

  // Search
  searchKeyword: string
  
  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
  
  // Create
  createStatus: {
    data: Model.IPresetTag
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  // Update
  updateStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyPresetTagContext = (): IPresetTagContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.IPresetTag>(),
    
    // Search
    searchKeyword: "",
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  
    // Create
    createStatus: {
      data: Model.EmptyPresetTag(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    updateStatuses: {}
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IPresetTagAction {
  datasForDisplay: () => List<Model.IPresetTag>
  getOfflineDatas: (applyFilter: boolean) => List<Model.IPresetTag>
  getSearchKeyword: () => string

  closeCreateForm: () => void
  closeEditForm: (presettagid: string) => void
  createRequest: (presettag: Model.IPresetTag) => void
  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (presettagid: string) => void
  searchByKeyword: (keyword: string) => void
  updateRequest: (presettag: Model.IPresetTag) => void
}

export const PresetTagAction = (state: IPresetTagContext, provider: AppContextProvider): IPresetTagAction => {
  const datasForDisplay = () => getOfflineDatas(true)
  const getSearchKeyword = () => state.searchKeyword
  const getOfflineDatas = (applyFilter: boolean): List<Model.IPresetTag> => {
    const offlineDatas = state.offlineDatas
    if (!applyFilter) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    const keyword = getSearchKeyword()
    return offlineDatas.filter((presettag: Model.IPresetTag) => {
      return searchInString(presettag.presettagid, keyword) ||
             searchInString(presettag.name, keyword) ||
             searchInStrings(presettag.tags.map(tag => tag.name), keyword) 
    }).toList()
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (presettagid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(presettagid))
  }

  const createRequest = async (presettag: Model.IPresetTag) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.presettags.create(presettag)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart())

      try {
        // Requesting
        const result = await api.presettags.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(result.presettags, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (presettagid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(presettagid))
  }

  const searchByKeyword = (keyword: string) => {
    dispatch(provider, contextHandler(state).searchByKeyword(keyword))
  }

  const updateRequest = async (presettag: Model.IPresetTag) => {
    // Request Start
    const { presettagid } = presettag
    dispatch(provider, contextHandler(state).updateRequestStart(presettagid))

    try {
      const resultPresetTag = await api.presettags.update(presettag)

      const closeFormState = contextHandler(state).closeEditForm(presettagid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultPresetTag))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, presettagid))
    }
  }

  return {
    datasForDisplay,
    getOfflineDatas,
    getSearchKeyword,

    closeCreateForm,
    closeEditForm,
    createRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: IPresetTagContext) => {
  provider.setState({
    presetTag: newState
  })
}

const contextHandler = (previousState: IPresetTagContext) => {
  const listRequestStart = () => {
    return {
      ...previousState,
      isFetching: true
    }
  }

  const listRequestSucceed = (presettags: Model.IPresetTag[], pagesize: number) => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (presettags.length === 0) {
      hasMore = false
    } else if (presettags.length > 0) {
      currentPage++
    }
  
    if (presettags.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.IPresetTag[] = _.filter(presettags, (presettag: Model.IPresetTag) => {
      return previousState.offlineDatas.findIndex((item: Model.IPresetTag) => item.presettagid === presettag.presettagid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      ...previousState,
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }

  const listRequestFail = (error: Model.IApiErrorResult) => {
    return {
      ...previousState,
      fetchErrorMessage: error.data.message,
      isFetching: false
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createPresetTag: Model.EmptyPresetTag(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (presettag: Model.IPresetTag) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([presettag]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (presettagid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[presettagid] = {
      errorMessage: "",
      id: presettagid,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const closeEditForm = (presettagid: string) => {
    const updateStatuses = { ...previousState.updateStatuses}
    delete updateStatuses[presettagid]
    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestStart = (presettagid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[presettagid] = {
      ...updateStatuses[presettagid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestSucceed = (presettag: Model.IPresetTag) => {
    const updateStatuses = { ...previousState.updateStatuses }
    delete updateStatuses[presettag.presettagid]

    const offlineDatas = previousState.offlineDatas.asMutable().map((eachItem: Model.IPresetTag) => {
      if (eachItem.presettagid === presettag.presettagid) {
        return presettag
      }
      return eachItem
    }).toList()

    return {
      ...previousState,
      offlineDatas,
      updateStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, presettagid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[presettagid] = {
      ...updateStatuses[presettagid],
      errorMessage: error.data.message,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const searchByKeyword = (keyword: string) => {
    return {
      ...previousState,
      searchKeyword: keyword
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}