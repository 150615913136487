import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInString } from "./AppContext"

import * as api from "../api"
import * as Model from "../models"

export interface IOldProductContext {
  // Offline Data
  offlineDatas: List<Model.IOldProduct>

  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
}

export const EmptyOldProductContext = (): IOldProductContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.IOldProduct>(),
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IOldProductAction {
  getOfflineDatas: (searchKeyword?: string) => List<Model.IOldProduct>

  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  convertFromOldProduct: (product: Model.IProduct, oldproductid: string) => Promise<Model.IProduct>
}

export const OldProductAction = (state: IOldProductContext, provider: AppContextProvider): IOldProductAction => {
  const getOfflineDatas = (searchKeyword?: string): List<Model.IOldProduct> => {
    const offlineDatas = state.offlineDatas
    if (!searchKeyword) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    return applyFilterByKeyword(offlineDatas, searchKeyword)
  }

  const applyFilterByKeyword = (list: List<Model.IOldProduct>, searchKeyword: string): List<Model.IOldProduct> => {
    return list.filter((oldproduct: Model.IOldProduct) => {
      return searchInString(oldproduct.productid, searchKeyword) ||
             searchInString(oldproduct.description, searchKeyword) ||
             searchInString(oldproduct.name, searchKeyword) ||
             searchInString(oldproduct.mynotes, searchKeyword) ||
             searchInString(oldproduct.presettags, searchKeyword)
    }).toList()
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, state, { isFetching: true })

      try {
        // Requesting
        const result = await api.oldproducts.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, state, contextHandler(state).listRequestSucceed(result.oldproducts, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, state, {
          fetchErrorMessage: error.data.message,
          isFetching: false
        })
      }
    }
  }

  const convertFromOldProduct = (product: Model.IProduct, oldproductid: string): Promise<Model.IProduct> => {
    return new Promise(async (resolve, reject) => {
      try {
        const convertedProduct = await api.oldproducts.convertFromOldProduct(product, oldproductid)

        const offlineDatas = state.offlineDatas.filter(oldProduct => oldProduct!.productid !== oldproductid).toList()

        return dispatch(provider, state, { offlineDatas }, () => {
          resolve(convertedProduct)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  return {
    getOfflineDatas,

    convertFromOldProduct,
    loadMore,
  }
}

const dispatch = (provider: AppContextProvider, previousState: IOldProductContext, newState: Partial<IOldProductContext>, callback?: () => void) => {
  provider.setState({
    oldProduct: {
      ...previousState,
      ...newState,
    }
  }, () => {
    if (callback) {
      callback()
    }
  })
}

const contextHandler = (previousState: IOldProductContext) => {
  const listRequestSucceed = (products: Model.IOldProduct[], pagesize: number): IOldProductContext => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (products.length === 0) {
      hasMore = false
    } else if (products.length > 0) {
      currentPage++
    }
  
    if (products.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.IOldProduct[] = _.filter(products, (product: Model.IOldProduct) => {
      return previousState.offlineDatas.findIndex((item: Model.IOldProduct) => item.productid === product.productid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }


  return {
    listRequestSucceed,
  }
}