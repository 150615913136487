import * as React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { IPaymentMethodOption, paymentMethods } from './PaymentChannelLabel'

interface IProps {
  onChange: (paymentMethod: IPaymentMethodOption) => void
}
interface IState {
  dropdownOpen: boolean
  selectedPaymentMethod: IPaymentMethodOption
}

class PaymentDropdown extends React.Component<IProps, IState> {
  public readonly state = {
    dropdownOpen: false,
    selectedPaymentMethod: paymentMethods[0],
  }

  constructor(props: IProps) {
    super(props)
  }

  public render() {
    const selectedPaymentMethod = this.state.selectedPaymentMethod
    const dropdownOpen = this.state.dropdownOpen

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.dropdownToggle}>
        <DropdownToggle caret={true}>
          {selectedPaymentMethod.text}
        </DropdownToggle>
        <DropdownMenu>
          { paymentMethods.map((paymentMethod, index) => (
            <DropdownItem key={index} onClick={this.handleDropdownChange.bind(this, paymentMethod)}>
              {paymentMethod.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }

  private handleDropdownChange = (paymentMethod: IPaymentMethodOption, event: any) => {
    this.setState({ selectedPaymentMethod: paymentMethod })
    this.props.onChange(paymentMethod)
  }

  private dropdownToggle = () => {
    const dropdownOpen = !this.state.dropdownOpen

    this.setState({ dropdownOpen })
  }
}

export default PaymentDropdown