import * as React from 'react'
import { Button } from 'reactstrap'

type Value = string | number
interface IProps {
  onClick: (value?: Value) => void,
  value?: Value,
  color?: string
}

const ClickButton: React.SFC<IProps> = (props) => {
  const handleClick = () => {
    props.onClick(props.value)
  }

  return (
    <Button {...props} onClick={ handleClick }>
      { props.children }
    </Button>
  )
}

export default ClickButton