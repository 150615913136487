import * as React from 'react'

import * as Model from '../../../models'
import ProductDropdown from '../dropdown'

import './index.css'

interface IProductPickerProps {
  onProductCreate: () => void
  onProductAddVariance: (product: Model.IProduct) => void
  onProductPicked: (product: Model.IProduct, variance: Model.IVariance) => void
  onNewProduct?: (name: string) => void
}
const ProductPicker: React.SFC<IProductPickerProps> = (props) => {
  return (
    <div>
      <ProductDropdown onProductCreate={props.onProductCreate} onProductAddVariance={props.onProductAddVariance} onProductPicked={props.onProductPicked} />
    </div>
  )
}

export default ProductPicker