import * as React from 'react'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, UncontrolledDropdown } from 'reactstrap'

import history from '../../history'
import './SiteMenu.css'

export interface IState {
  isOpen: boolean
}

interface ILeftNavBar {
  brand: {
    linkTo: string
    textOrTag: string | JSX.Element
  },
  links: ILink[]
}
interface IRightNavBar {
  links: ILink[]
}
interface ILink {
  dropdown?: boolean
  onClick?: (...args: any[]) => any
  links?: ILink[]
  linkTo?: string
  textOrTag: string | JSX.Element
}

export class SiteMenu extends React.Component<{}, IState> {
  public readonly state: IState = {
    isOpen: false
  }

  public render() {
    // set data
    const leftNavbar: ILeftNavBar = {
      brand: {
        linkTo: "/",
        textOrTag: "管理系統"
      },
      links: [
        {linkTo: "/product", textOrTag: "貨品"},
        {linkTo: "/order", textOrTag: "訂單"},
        {linkTo: "/oldproduct", textOrTag: "貨品(舊System)"},
        // {linkTo: "/stock", textOrTag: "存貨"},
        {dropdown: true, links: [
          {linkTo: "/mailtracker", textOrTag: "郵件追蹤"},
          {linkTo: "/presettag", textOrTag: "預設Tags"},
          {linkTo: "/batch", textOrTag: "團期"}
        ], textOrTag: "資料"}
      ]
    }

    const rightNavbar: IRightNavBar = {
      links: [{
        dropdown: true,
        links: [{
          onClick: this.confirmLogout,
          textOrTag: "登出"
        }], textOrTag: "帳號"
      }]
    }

    return (
      <Navbar color="light" light={true} expand="md" fixed="top">
        <ReactRouterNavLink to={leftNavbar.brand.linkTo} className="navbar-brand">
          { leftNavbar.brand.textOrTag }
        </ReactRouterNavLink>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar={true}>
          <Nav className="mr-auto" navbar={true}>
            { leftNavbar.links.map((link: ILink, index: number) => {
              if (link.dropdown) {
                return (
                  <UncontrolledDropdown key={index} nav={true} inNavbar={true}>
                    <DropdownToggle nav={true} caret={true}>
                      { this.renderTextOrTag(link) }
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      { link.links && link.links.map((childLink: ILink, childIndex: number) => (
                        <DropdownItem key={childIndex}>
                          { this.renderNavItem(childLink) }
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              } else {
                return (
                  <NavItem key={index}>
                    { this.renderNavItem(link) }
                  </NavItem>
                )
              }
            })}
          </Nav>
          <Nav className="ml-auto" navbar={true}>
            { rightNavbar.links.map((link: ILink, index: number) => {
              if (link.dropdown) {
                return (
                  <UncontrolledDropdown key={index} nav={true} inNavbar={true}>
                    <DropdownToggle nav={true} caret={true}>
                      { this.renderTextOrTag(link) }
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      { link.links && link.links.map((childLink: ILink, childIndex: number) => (
                        <DropdownItem key={childIndex}>
                          { this.renderNavItem(childLink) }
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              } else {
                return (
                  <NavItem key={index}>
                    { this.renderNavItem(link) }
                  </NavItem>
                )
              }
            })}
          </Nav>
        </Collapse>
      </Navbar>
    )
  }

  private renderTextOrTag(link: ILink) {
    if (typeof link.textOrTag === "string") {
      return <span>{link.textOrTag}</span>
    }

    return link.textOrTag
  }

  private renderNavItem(link: ILink) {
    if (link.onClick) {
      return (
        <a href="javascirpt:void(0)" onClick={link.onClick} className="nav-link">
          { this.renderTextOrTag(link) }
        </a>
      )
    }
    
    if (link.linkTo) {
      return (
        <ReactRouterNavLink to={link.linkTo} onClick={this.closeMenu} className="nav-link">
          { this.renderTextOrTag(link) }
        </ReactRouterNavLink>  
      )
    }
    
    return this.renderTextOrTag(link)
  }

  private closeMenu = () => {
    this.setState({
      isOpen: false
    })
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  private confirmLogout = () => {
    if (window.confirm("登出?")) {
      history.push("/logout")
    }
  }
}

export default SiteMenu