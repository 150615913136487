import { List } from 'immutable'
import * as React from 'react'
import { Input, InputProps } from 'reactstrap'

import * as Base from './Base'
import * as ValidatorRules from './Rules'

interface IState extends Base.IBaseValidatorStates {
  isDirty: boolean
}
type CombinedProps = Partial<InputProps> & Base.IBaseValidatorProps
interface IProps extends CombinedProps {
  didchanged: (name: string, value: string) => void
}

class ValidatorInput extends Base.default<IProps, IState> {
  public readonly state: IState = {
    errorMessages: List(),
    hasError: false,
    isDirty: false,
  }

  public componentDidMount() {
    this.props.manager.addValidator(this)
  }

  public render() {
    const { didchanged, didinvalidate, ...rest } = this.props

    return (
      <span>
        <Input {...rest} valid={this.state.isDirty && !this.state.hasError} invalid={this.state.isDirty && this.state.hasError} onChange={this.handleChange} onBlur={this.handleBlur} />
        { this.state.hasError && !didinvalidate &&
          this.renderErrorMessages(this.state.errorMessages)
        }
      </span>
    )
  }

  public performValidation = (value: any): boolean => {
    const result = this.props.rules.validate(value)

    const newState = {
      errorMessages: List<string>(),
      hasError: false
    }
    if (result.count() > 0) {
      newState.errorMessages = result.map((item: ValidatorRules.IValidateResult) => item.errorMessage).toList()
      newState.hasError = true
    }
    this.setState(newState, () => {
      if (this.props.didinvalidate) {
        this.props.didinvalidate(this.props.name, this.renderErrorMessages(this.state.errorMessages))
      }
    })
    
    return newState.hasError
  }

  protected handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    this.performValidation(value)
  }

  protected handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isDirty: true })

    // Use props onChange method if provided
    if (this.props.onChange) {
      this.props.onChange(event)
      return
    }
    
    const name = event.target.name
    const value = event.target.value
    
    this.performValidation(value)
    this.props.didchanged(name, value)
  }
}

export default ValidatorInput