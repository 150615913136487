import * as React from 'react'
import './Loader.css'

const Loader: React.StatelessComponent<{}> = () => {
  return (
    <div className="loader-container">
      <div className="loader">載入中...</div>
    </div>
  )
}

export default Loader