import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import PresetTagForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
  presettag: Model.IPresetTag
  errorMessage: string | undefined
}

const FormEdit: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getPresetTagState()
  const action = props.appcontext.getPresetTagAction()

  const handleUpdate = (presettagWorkingCopy: Model.IPresetTag) => {
    action.updateRequest(presettagWorkingCopy)
  }
  
  const handleCancel = () => {
    action.closeEditForm(props.presettag.presettagid)
  }

  const updatePresetTag = state.updateStatuses[props.presettag.presettagid]

  return (
    <PresetTagForm isOpen={true} formTitle={props.formTitle} isCreate={false} presettag={props.presettag} onUpdate={handleUpdate} onCancel={handleCancel} errorMessage={props.errorMessage} isLoading={updatePresetTag.isSubmitting} />
  )
}

export default withAppContext(FormEdit)