import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import MailTrackerForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
  mailtracker: Model.IMailTracker
  errorMessage: string | undefined
}

const FormEdit: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getMailTrackerState()
  const action = props.appcontext.getMailTrackerAction()

  const handleUpdate = (mailtrackerWorkingCopy: Model.IMailTracker) => {
    action.updateRequest(mailtrackerWorkingCopy)
  }
  
  const handleCancel = () => {
    action.closeEditForm(props.mailtracker.mailtrackerid)
  }

  const updateMailTracker = state.updateStatuses[props.mailtracker.mailtrackerid]

  return (
    <MailTrackerForm isOpen={true} formTitle={props.formTitle} isCreate={false} mailtracker={props.mailtracker} onUpdate={handleUpdate} onCancel={handleCancel} errorMessage={props.errorMessage} isLoading={updateMailTracker.isSubmitting} />
  )
}

export default withAppContext(FormEdit)