import * as React from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import Tag from '../tag/Tag'
import PresetTagEditForm from './FormEdit'

interface IProps extends IAppContextProps{
  presettag: Model.IPresetTag
}

const Item: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getPresetTagState()
  const action = props.appcontext.getPresetTagAction()

  const presettag = props.presettag
  const updatePresetTag = state.updateStatuses[presettag.presettagid]

  const handleChangeToEdit = () => {
    action.openEditForm(props.presettag.presettagid)
  }
  
  return (
    <div>
      { updatePresetTag !== undefined && (
        <PresetTagEditForm {...props} formTitle={`編輯 ${props.presettag.presettagid}`} presettag={presettag} errorMessage={updatePresetTag.errorMessage} />
      )}
      <Card>
        <CardHeader>
          <Layout.Clearfix>
            { presettag.name } <Layout.BadgeId>{ presettag.presettagid }</Layout.BadgeId>
            <div className="pull-right">
              <Button color="primary" outline={true} onClick={handleChangeToEdit}>編輯</Button>
            </div>
          </Layout.Clearfix>
        </CardHeader>
        <CardBody>
          { presettag.tags && presettag.tags.length > 0 ? (
              presettag.tags.map((tag: Model.ITag) => (
                <Tag key={tag.name} tag={tag} />
              ))
          ) : (
            <span>沒有資料</span>
          )}
          { }
        </CardBody>
      </Card>
    </div>
  )
}

export default withAppContext(Item)