import * as _ from "lodash"
import * as moment from 'moment'

import * as Model from '.'

// Product

export interface IProduct {
  datemodified: moment.Moment
  description: string
  ismij: boolean
  isonfb: boolean
  isonig: boolean
  isonweb: boolean
  isusj: boolean
  name: string
  privatenotes: string
  productid: string
  s3keyproduct: string
  s3keythumbnail: string
  tags: Model.ITag[]
  variances: Model.IVariance[]
}
export function EmptyProduct(): IProduct {
  return {
    datemodified: moment(),
    description: '',
    ismij: false,
    isonfb: false,
    isonig: false,
    isonweb: false,
    isusj: false,
    name: '',
    privatenotes: '',
    productid: '',
    s3keyproduct: '',
    s3keythumbnail: '',
    tags: [],
    variances: []
  }
}

export const convertFromOldProduct = (oldproduct: Model.IOldProduct): IProduct => {
  return {
    datemodified: moment(oldproduct.datecreated),
    description: oldproduct.description || "",
    ismij: oldproduct.ismij || false,
    isonfb: oldproduct.ispostedfb || false,
    isonig: oldproduct.ispostedig || false,
    isonweb: oldproduct.isonshelf || false,
    isusj: oldproduct.isusj || false,
    name: oldproduct.name || "",
    privatenotes: oldproduct.mynotes || "",
    productid: oldproduct.productid,
    s3keyproduct: oldproduct.s3key || "",
    s3keythumbnail: oldproduct.s3key || "",
    tags: _.map(oldproduct.tags, tag => {
      return {
        name: tag
      }
    }),
    variances: [{
      ishiddenprice: oldproduct.hideprice || false,
      name: "",
      primecost: oldproduct.primecost || 0,
      primecostadjust: oldproduct.primecostadjust || 0,
      productid: "",
      sellingprice: oldproduct.price || Math.ceil((oldproduct.primecost + oldproduct.primecostadjust) * 0.095) || 0,
      varianceid: "",
    }],
  }
}