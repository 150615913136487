import * as React from 'react'

import { Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap'

import * as Model from '../../models'
import * as FormComponent from '../form'
import * as Layout from '../layout'
import PrimeCostLabel from './PrimeCostLabel'

interface IVarianceCardFormProps {
  index: number
  variance: any
  handleVarianceChange: (variance: Model.IVariance) => void
}

class VarianceCardForm extends React.Component<IVarianceCardFormProps> {
  constructor(props: IVarianceCardFormProps) {
    super(props)
  }

  public render() {
    const variance = this.props.variance

    return (
      <Card outline={true} color={variance.isdeleted ? "danger" : ""}>
        <CardHeader>
          { variance.isdeleted && (
            <span className="text-danger">[刪除]&nbsp;</span>
          )}
          { variance.isnew && (
            <span className="text-warning">[New]&nbsp;</span>
          )}
          <span>{this.props.index+1}.&nbsp;</span>
          {variance.name}&nbsp;
          <Layout.DollarSign sign="$">{variance.sellingprice}</Layout.DollarSign>
          <span className="text-muted">
            {' '}<PrimeCostLabel variance={variance} bracket={true} />
          </span>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Name</Label>
            <Input name="name" type="text" value={variance.name} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>Prime Cost</Label>
            <Input name="primecost" type="number" value={variance.primecost} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <Label>Prime Cost Adjust</Label>
            <Input name="primecostadjust" type="number" value={variance.primecostadjust} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup check={true}>
            <FormComponent.Checkbox name="ishiddenprice" onCheckToggled={this.handleCheckToggled} value={variance.ishiddenprice} text="隱藏價格?" />
          </FormGroup>
          <FormGroup check={true}>
            <FormComponent.Checkbox name="isdelete" onCheckToggled={this.handleCheckToggled} value={variance.isdelete} text="刪除?" />
          </FormGroup>
        </CardBody>
      </Card>
    )
  }

  private handleChange = (event: any) => {
    const variance = this.props.variance
    const keyName = event.target.name
    const value = event.target.value
    
    variance[keyName] = value
    if (keyName === "primecost" || keyName === "primecostadjust") {
      const primecost = +variance.primecost || 0
      const primecostadjust = +variance.primecostadjust || 0
      const sellingprice = Math.ceil((primecost + primecostadjust) * 0.095)
      variance.sellingprice = sellingprice
    }
    this.setState({variance}, () => {
      this.props.handleVarianceChange(variance)
    })
  }

  private handleCheckToggled = (name: string, value: boolean) => {
    const variance = this.props.variance
    
    variance[name] = value
    this.setState({value}, () => {
      this.props.handleVarianceChange(variance)
    })
  }
}

export default VarianceCardForm