import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { presettags: Model.IPresetTag[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/presettag/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const create = (data: Model.IPresetTag) => {
  return api.post(`/api/presettag/create`, data).then(response => 
    response.data.presettag as Model.IPresetTag
  ) as Promise<Model.IPresetTag>
}

export const update = (data: Model.IPresetTag) => {
  return api.post(`/api/presettag/update`, data).then(response => 
    response.data.presettag as Model.IPresetTag
  ) as Promise<Model.IPresetTag>
}