import api from '.'

import * as Model from '../models'

interface IGetPagedReturnType { customers: Model.ICustomer[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/customer/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const getByCustomerId = (customerid: string) => {
  return api.get(`/api/customer/id/${customerid}`).then(response =>
    response.data as Model.ICustomer
  ) as Promise<Model.ICustomer>
}

export const create = (data: Model.ICustomer) => {
  return api.post(`/api/customer/create`, data).then(response =>
    response.data.customer as Model.ICustomer
  ) as Promise<Model.ICustomer>
}

export const update = (data: Model.ICustomer) => {
  return api.post(`/api/customer/update`, data).then(response =>
    response.data.customer as Model.ICustomer
  ) as Promise<Model.ICustomer>
}

export const depositPayment = (customerid: string, channel: string, amount: number, remarks: string, orderid: string, payment: number | undefined) => {
  const data = {
    amount,
    channel,
    customerid,
    payment: {
      amount: payment,
      orderid,
    },
    remarks,
  }
  return api.post(`/api/customer/payment`, data).then(response =>
    response.data as Model.Api.IOrderDepositPaymentResponse
  ) as Promise<Model.Api.IOrderDepositPaymentResponse>
}