import { List, Map } from 'immutable'

enum Types {
  IS_FALSE = 'IS_FALSE',
  REQUIRED = 'REQUIRED',
  MIN_LENGTH = 'MIN_LENGTH',
  MAX_LENGTH = 'MAX_LENGTH',
  SAME_VALUE = 'SAME_VALUE'
}

export interface IValidateResult {
  errorMessage: string,
  type: Types
}

export default class ValidationRules {
  public lazyValidation: boolean = true
  private rules: Map<string, RULES> = Map()

  public validate(value: any): List<IValidateResult> {
    let filtered = this.rules.filterNot((rule: RULES) => {
      switch (rule.type) {
        case Types.IS_FALSE:
        return rule.payload.validator(value)

        case Types.REQUIRED:
        return rule.payload.validator(value)

        case Types.MIN_LENGTH:
        return rule.payload.validator(value, rule.payload.length)

        case Types.MAX_LENGTH:
        return rule.payload.validator(value, rule.payload.length)

        case Types.SAME_VALUE:
        return rule.payload.validator(value)

        default:
        return false
      }
    }).toList()

    if (this.lazyValidation) {
      const singleItem = filtered.first()
      if (singleItem) {
        filtered = List.of(singleItem)
      }
    }
    
    return filtered.map((rule: RULES) => {
      return {
        errorMessage: rule.payload.errorMessage,
        type: rule.type
      }
    }).toList()
  }

  public required(errorMessage?: string) {
    const typeName = Types.REQUIRED
    const rule: IRequiredRule = {
      payload: {
        errorMessage: errorMessage || typeName,
        validator: (value: string) => {
          const trimmedValue = (value || "").trim()
          if (trimmedValue === undefined || trimmedValue === null || trimmedValue === "") {
            return false
          }
          
          return true
        }
      },
      type: typeName,
    }
    this.rules = this.rules.set(typeName, rule)
    return this
  }

  public isFalse(errorMessage?: string) {
    const typeName = Types.IS_FALSE
    const rule: IIsFalseRule = {
      payload: {
        errorMessage: errorMessage || typeName,
        validator: (value: boolean) => {
          return value === false
        }
      },
      type: typeName,
    }
    this.rules = this.rules.set(typeName, rule)
    return this
  }

  public min(length: number, errorMessage?: string) {
    const typeName = Types.MIN_LENGTH
    const rule: IMinLengthRule = {
      payload: {
        errorMessage: errorMessage || typeName,
        length,
        validator: (value: string, validLength: number) => {
          const safeValue = value || ""
          if (safeValue.length < validLength) {
            return false
          }
        
          return true
        },
      },
      type: typeName,
    }
    this.rules = this.rules.set(typeName, rule)
    return this
  }
  
  public max(length: number, errorMessage?: string) {
    const typeName = Types.MAX_LENGTH
    const rule: IMaxLengthRule = {
      payload: {
        errorMessage: errorMessage || typeName,
        length,
        validator: (value: string, validLength: number) => {
          const safeValue = value || ""
          if (safeValue.length > validLength) {
            return false
          }
        
          return true
        },
      },
      type: typeName,
    }
    this.rules = this.rules.set(typeName, rule)
    return this
  }

  public sameValue(compareValue: string, errorMessage?: string) {
    const typeName = Types.SAME_VALUE
    const rule: ISameValueRule = {
      payload: {
        compareValue,
        errorMessage: errorMessage || typeName,
        validator: (value: string) => {
          if (compareValue === value) {
            return true
          }
        
          return false
        },
      },
      type: typeName,
    }
    this.rules = this.rules.set(typeName, rule)
    return this
  }
}

interface IIsFalseRule { type: Types.IS_FALSE, payload: { errorMessage: string, validator: (value: boolean) => boolean } }
interface IRequiredRule { type: Types.REQUIRED, payload: { errorMessage: string, validator: (value: string) => boolean } }
interface IMinLengthRule { type: Types.MIN_LENGTH, payload: { errorMessage: string, length: number, validator: (value:string, length: number) => boolean } }
interface IMaxLengthRule { type: Types.MAX_LENGTH, payload: { errorMessage: string, length: number, validator: (value:string, length: number) => boolean } }
interface ISameValueRule { type: Types.SAME_VALUE, payload: { errorMessage: string, compareValue: string, validator: (value:string) => boolean } }
type RULES = IIsFalseRule | IRequiredRule | IMinLengthRule | IMaxLengthRule | ISameValueRule