import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Badge, Button } from 'reactstrap'

import * as Model from '../../../models'
import * as Layout from '../../layout'
import CustomerChannelLabel from '../CustomerChannelLabel'
import CustomerDropdown from '../dropdown'

interface IProps {
  canDelete: boolean
  defaultCustomer: Model.Form.ICustomer
  onCustomerChanged: (customer: Model.Form.ICustomer) => void
  onCustomerCreated: (customer: Model.Form.ICustomer) => void
  onCustomerPicked: (customer: Model.ICustomer) => void
  onCustomerDeleted: () => void
}

const CustomerPicker: React.SFC<IProps> = (props) => {
  const selectedCustomer = props.defaultCustomer

  if ((selectedCustomer && selectedCustomer.customerid) || selectedCustomer.isnew) {
    return (
      <div>
        <CustomerChannelLabel value={selectedCustomer.channel} />: {selectedCustomer.name}
        { selectedCustomer.isnew ? (
          <Badge color="info">NEW</Badge>
        ) : (
          <Layout.BadgeId>{selectedCustomer.customerid}</Layout.BadgeId>
        )}
        { props.canDelete && (
          <Button color="danger" onClick={props.onCustomerDeleted}>
            <FontAwesome name="times" />
          </Button>
        )}
      </div>
    )
  }

  return (
    <div>
      <CustomerDropdown defaultValue={selectedCustomer} onCustomerChanged={props.onCustomerChanged} onCustomerCreated={props.onCustomerCreated} onCustomerPicked={props.onCustomerPicked} />
    </div>
  )
}

export default CustomerPicker