import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Badge } from 'reactstrap'

interface IProps {
  noBadge?: boolean
  value: string
}

export const CustomerChannelLabel: React.SFC<IProps> = (props: IProps) => {
  const mappingTable: any = {
    email: {
      icon: "at",
      text: "Email"
    },
    fb: {
      icon: "facebook",
      text: "FB"
    },
    ig: {
      icon: "instagram",
      text: "IG"
    },
    other: {
      icon: "",
      text: "Other"
    },
    whatsapp: {
      icon: "whatsapp",
      text: "Whatsapp"
    }
  }

  const mapDisplayText = (value: string) => {
    if (mappingTable.hasOwnProperty(value)) {
      return mappingTable[value].text
    }
    
    return value
  }

  const mapDisplayIcon = (value: string) => {
    if (mappingTable.hasOwnProperty(value)) {
      return mappingTable[value].icon
    }
    
    return value
  }

  const displayText = mapDisplayText(props.value)
  const displayIcon = mapDisplayIcon(props.value)
  const hasIcon = displayIcon ? true : false

  const renderMainContent = () => (
    <span>
      { hasIcon && (
        <FontAwesome name={displayIcon}/>
      )}
      {' '}
      {displayText}
    </span>
  )


  if (props.noBadge === true) {
    return ( renderMainContent() )
  }
  
  return (
    <Badge {...props}>
      { renderMainContent() }
    </Badge>
  )
}

export default CustomerChannelLabel