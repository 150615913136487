import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Alert, Badge, Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Collapse, Row } from 'reactstrap'

/* tslint:disable no-var-requires*/
const BlockUi = require("react-block-ui").default
/* tslint:enable no-var-requires*/

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as ShippingHandling from '../../handling/shipping'
import * as Model from '../../models'
import CustomerChannelLabel from '../customer/CustomerChannelLabel'
import * as Layout from '../layout'
import ShippingAddress from '../shipping/ShippingAddress'
import ShippingMethodLabel from '../shipping/ShippingMethodLabel'
import OrderEditForm from './FormEdit'
import OrderAmountSummary from './OrderAmountSummary'
import OrderFormTextPanel from './OrderFormTextPanel'
import OrderProductListItem from './OrderProductListItem'

interface IProps extends IAppContextProps {
  order: Model.IOrder
}
interface IOrderItemStates {
  addressExpanded: boolean
  orderExpanded: boolean
  orderFormTextExpanded: boolean
  paymentExpanded: boolean
  productsExpanded: boolean
}

class Item extends React.Component<IProps, IOrderItemStates> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      addressExpanded: false,
      orderExpanded: false,
      orderFormTextExpanded: false,
      paymentExpanded: false,
      productsExpanded: false,
    }
  }
  
  public render() {
    const state = this.props.appcontext.getOrderState()
    
    const { addressExpanded, orderExpanded, orderFormTextExpanded, paymentExpanded, productsExpanded } = this.state
    const props = this.props
  
    const order = props.order
    const orderStatus = state.orderStatuses[order.orderid]

    const totalOrderAmountWithShipping = order.derivedata.totalorderamountwithshipping // OrderHandling.totalOrderAmountWithShipping(order)
    const totalOrderAmountWithShippingBeforeDiscount = order.derivedata.totalorderamountwithshippingbeforediscount // OrderHandling.totalOrderAmountWithShipping({order, beforediscount: false})
    const paymentCompleted = order.derivedata.paymentcompleted // OrderHandling.paymentCompleted(order)
    const paymentCompletedAndExceeded = order.derivedata.paymentexceeded // OrderHandling.paymentCompletedAndExceeded(order)
    const paymentIncompleted = order.derivedata.paymentincompleted // OrderHandling.paymentIncompleted(order)

    return (
      <BlockUi tag="div" blocking={orderStatus && orderStatus.isLoading} loader={Layout.Loader}>
        { orderStatus !== undefined && orderStatus.isEditing && (
          <OrderEditForm {...props} formTitle={`編輯 ${props.order.orderid}`} order={order} batchid={order.batchid} errorMessage={orderStatus.errorMessage} />
        )}
        <Card outline={true} color={ paymentCompleted ? "success" : paymentCompletedAndExceeded || paymentIncompleted ? "warning" : "" }>
          <CardHeader onClick={this.toggleOrderExpand}>
            <Layout.Clearfix>
              { order.batchid }
              <Layout.BadgeId>{ order.orderid }</Layout.BadgeId>
              <ButtonGroup className="pull-right" >
                <Button color="success" onClick={ this.toggleOrderFormText }>
                  出單
                </Button>
                <Button color="primary" outline={true} onClick={this.handleChangeToEdit}>編輯</Button>
              </ButtonGroup>
              <br/>
              <Layout.CalendarDate format="YYYY-MM-DD HH:mm">{order.datecreated}</Layout.CalendarDate>
              <CardTitle>
                <CustomerChannelLabel value={order.customer.channel} /> {order.customer.name} <Layout.BadgeId>{ order.customerid }</Layout.BadgeId>
              </CardTitle>
              <Badge color={ShippingHandling.shippingMethodIsUnknown(order.shippingmethod) ? "danger" : "success"}>
                <FontAwesome name="truck" /> {' '}
                <ShippingMethodLabel shippingmethod={order.shippingmethod} />{' '}<Layout.DollarSign sign="$">{order[order.shippingmethod]}</Layout.DollarSign>
              </Badge>
              {' '}
              { !order.addressid && (
                <Badge color="danger">
                  郵寄資料未定
                </Badge>
              )}
              {' '}
              { paymentCompleted && (
                <Badge color="success">
                  已付全數
                </Badge>
              )}
              {' '}
              { paymentCompletedAndExceeded && (
                <Badge color="warning">
                  有多餘入數
                </Badge>
              )}
              {' '}
              { paymentIncompleted && (
                <Badge color="warning">
                  已入訂金
                </Badge>
              )}
              {' '}
              {/* { StockHandling.fullStock(order.orderproducts) && (
                <Badge color="success">
                  已齊貨
                </Badge>
              )}
              {' '}
              { StockHandling.partialStock(order.orderproducts) && (
                <Badge color="danger">
                  未齊貨
                </Badge>
              )} */}
            </Layout.Clearfix>
          </CardHeader>
          <Collapse isOpen={orderExpanded}>
            <CardBody>
              {/* <Container fluid={true}> */}
                <Collapse isOpen={orderFormTextExpanded}>
                  <OrderFormTextPanel order={order} />
                  <Layout.RowSeparator />
                </Collapse>
                { order.remarks && (
                  <Row>
                    <Col className="mt-2 mb-2">
                      <Alert color="info">
                        <FontAwesome name="exclamation-triangle" />
                        {order.remarks}
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="mt-2 mb-2">
                    <Card>
                      <CardHeader onClick={this.toggleAddressExpand}>
                        <FontAwesome name="truck" /> {' '}
                        <ShippingMethodLabel shippingmethod={order.shippingmethod} />{' '}<Layout.DollarSign sign="$">{order[order.shippingmethod]}</Layout.DollarSign>
                        {' '}
                        { !order.addressid && (
                          <Badge color="danger">
                            郵寄資料未定
                          </Badge>
                        )}
                      </CardHeader>
                      <Collapse isOpen={addressExpanded}>
                        <CardBody>
                          <ShippingAddress order={order} />
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2 mb-2">
                    <Card>
                      <CardHeader onClick={this.togglePaymentExpand}>
                        { order.discountprice > 0 ? (
                          <span>
                            全單折實{' '}
                            <Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShippingBeforeDiscount}</Layout.DollarSign>
                            {' '}
                            <span className="text-muted">
                              折扣前{' '}
                              <Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShipping}</Layout.DollarSign>
                            </span>
                          </span>
                        ) : (
                          <span>
                            全單{' '}
                            <Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShipping}</Layout.DollarSign>
                          </span>
                        )}
                        {' '}
                        { paymentCompleted && (
                          <Badge color="success">
                            已付全數
                          </Badge>
                        )}
                        {' '}
                        { paymentCompletedAndExceeded && (
                          <Badge color="warning">
                            有多餘入數
                          </Badge>
                        )}
                        {' '}
                        { paymentIncompleted && (
                          <Badge color="warning">
                            已入訂金
                          </Badge>
                        )}
                      </CardHeader>
                      <Collapse isOpen={paymentExpanded}>
                        <CardBody>
                          <OrderAmountSummary order={order} />
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-2 mb-2">
                    <Card>
                      <CardHeader onClick={this.toggleProductsExpand}>
                        貨品清單
                        {' '}
                        {/* { StockHandling.fullStock(order.orderproducts) && (
                          <Badge color="success">
                            已齊貨
                          </Badge>
                        )}
                        {' '}
                        { StockHandling.partialStock(order.orderproducts) && (
                          <Badge color="danger">
                            未齊貨
                          </Badge>
                        )} */}
                      </CardHeader>
                      <Collapse isOpen={productsExpanded}>
                        <CardBody>
                          { order.orderproducts && order.orderproducts.length > 0 ? (
                            order.orderproducts.map((orderproduct: Model.IOrderProduct, index: number) => (
                              <span key={orderproduct.productid+"-"+orderproduct.varianceid}>
                                <OrderProductListItem orderProduct={orderproduct} />
                                { index < order.orderproducts.length-1 && (
                                  <hr />
                                )}
                              </span>
                            ))
                          ) : (
                            <span>沒有貨品</span>
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                </Row>
              {/* </Container> */}
            </CardBody>
          </Collapse>
        </Card>
      </BlockUi>
    )
  }

  private handleChangeToEdit = (e: any) => {
    e.stopPropagation()
    
    const action = this.props.appcontext.getOrderAction()
  
    action.openEditForm(this.props.order.orderid)
  }

  private toggleAddressExpand = () => {
    const addressExpanded = !this.state.addressExpanded

    this.setState({addressExpanded})
  }

  private toggleOrderExpand = (e: any) => {
    this.setState({
      orderExpanded: !this.state.orderExpanded
    })
  }

  private toggleOrderFormText = (e: any) => {
    e.stopPropagation()

    const orderFormTextExpanded = !this.state.orderFormTextExpanded
    const orderExpanded = orderFormTextExpanded ? true : this.state.orderExpanded

    this.setState({
      orderExpanded,
      orderFormTextExpanded
    })
  }

  private togglePaymentExpand = () => {
    const paymentExpanded = !this.state.paymentExpanded

    this.setState({paymentExpanded})
  }

  private toggleProductsExpand = () => {
    const productsExpanded = !this.state.productsExpanded

    this.setState({productsExpanded})
  }
}

export default withAppContext(Item)