import * as _ from 'lodash'

import * as Model from '..'

export interface IVariance extends Model.IVariance {
  iscreate: boolean
  isdelete: boolean
}

export function ConvertFromVariancesModel(variances: Model.IVariance[]): IVariance[] {
  return _.isEmpty(variances) ? [] : variances.map(variance => ConvertFromVarianceModel(variance))
}
export function ConvertFromVarianceModel(variance: Model.IVariance): IVariance {
  return {
    iscreate: false,
    isdelete: false,
    ishiddenprice: variance.ishiddenprice,
    name: variance.name,
    primecost: variance.primecost,
    primecostadjust: variance.primecostadjust,
    productid: variance.productid,
    sellingprice: variance.sellingprice,
    varianceid: variance.varianceid,
  }
}
export function EmptyVariance(varianceid: string = "", productid: string = ""): IVariance {
  return {
    iscreate: true,
    isdelete: false,
    ishiddenprice: false,
    name: "",
    primecost: 0,
    primecostadjust: 0,
    productid,
    sellingprice: 0,
    varianceid,
  }
}