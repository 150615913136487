import * as moment from 'moment'

// Payment

export interface IPayment {
  amount: number
  datecreated: moment.Moment
  deposit: {
    channel: string
  }
  paymentid: string
}
export function EmptyPayment(): IPayment {
  return {
    amount: 0,
    datecreated: moment(),
    deposit: {
      channel: ""
    },
    paymentid: "",
  }
}