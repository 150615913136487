import * as _ from 'lodash'

import * as Model from '..'

export interface IOrderProduct {
  amount: number
  // boughts: Model.IStock[]
  detail: Partial<Model.Form.IOrderProductDetail>
  iscreate: boolean
  isdelete: boolean
  orderid: string
  productid: string
  sellingprice: number
  standardprice: number
  variance: Model.Form.IVariance
  varianceid?: string
}
export function ConvertFromOrderProductsModel(orderproducts: Model.IOrderProduct[], orderid: string): IOrderProduct[] {
  return _.isEmpty(orderproducts) ? [] : orderproducts.map(eachOrderProduct => ConvertFromOrderProductModel(eachOrderProduct, orderid))
}
export function ConvertFromOrderProductModel(orderproduct: Model.IOrderProduct, orderid: string): IOrderProduct {
  return {
    amount: orderproduct.amount,
    // boughts: orderproduct.boughts,
    detail: Model.Form.ConvertFromProductModel(orderproduct.detail),
    iscreate: false,
    isdelete: false,
    orderid,
    productid: orderproduct.productid,
    sellingprice: orderproduct.sellingprice,
    standardprice: orderproduct.sellingprice,
    variance: Model.Form.ConvertFromVarianceModel(orderproduct.variance),
    varianceid: orderproduct.varianceid,
  }
}