import { List, Map } from 'immutable'
import { AppContextProvider, searchInString } from "./AppContext"

import * as api from "../api"
import * as Lib from "../lib"
import * as Model from "../models"

export interface IFetchData {
  fetchErrorMessage: string
  hasMore: boolean // Indicate if there is any new page
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
}
export function EmptyFetchData(): IFetchData {
  return {
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1
  }
}

export interface IOrderContext {
  // Offline Data
  offlineDatas: List<Model.IOrder>

  // Fetching
  groupedFetchDatas: Map<string, IFetchData>
  
  // Create
  createStatus: {
    data: Model.IOrder
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  orderStatuses: {
    [id: string]: {
      id: string
      isLoading: boolean
      isEditing: boolean
      errorMessage: string
    }
  }

  // Update payment
  updatePaymentStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyOrderContext = (): IOrderContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    // Offline Data
    offlineDatas: List<Model.IOrder>(),
    
    // Fetching
    groupedFetchDatas: Map(),
  
    // Create
    createStatus: {
      data: Model.EmptyOrder(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    orderStatuses: {},
    updatePaymentStatuses: {},
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IOrderAction {
  getOfflineDatas: (batchid: string, searchKeyword?: string) => List<Model.IOrder>
  getFetchDataByBatchId: (batchid: string) => IFetchData

  assignAddress: (orderid: string, addressid: string) => void
  assignNewAddress: (orderid: string, customerid: string, shippingmethod: string, name: string, phone: string, addressdetail: string) => void
  unassignAddress: (orderid: string) => void
  assignShippingMethod: (orderid: string, shippingmethod: string) => void

  closeCreateForm: () => void
  closeEditForm: (orderid: string) => void
  createRequest: (order: Model.Form.IOrder) => void
  depositPaymentRequest: (customerid: string, channel: string, amount: number, remarks: string, orderid: string, payment: number | undefined) => void
  loadMore: (batchid: string, isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (orderid: string) => void
  updateRequest: (order: Model.Form.IOrder) => void
}

export const OrderAction = (state: IOrderContext, provider: AppContextProvider): IOrderAction => {
  const getFetchDataByBatchId = (batchId: string): IFetchData => {
    return state.groupedFetchDatas.get(batchId) || EmptyFetchData()
  }
  const getOfflineDatas = (batchid: string, searchKeyword?: string): List<Model.IOrder> => {
    // *** [Filter by BatchID] can filter out more data then [Filter by Keyword] in general case
    // *** So perform [Filter by BatchID] first
  
    // 1. Perform [Filter by BatchID]
    let result = state.offlineDatas.filter((order: Model.IOrder) => {
      return order.batchid === batchid
    })

    // 2. Perform [Filter by Keyword]
    if (searchKeyword) {
      result = applyFilterByKeyword(result.toList(), searchKeyword)
    }
    
    return result.toList()
  }
  const applyFilterByKeyword = (list: List<Model.IOrder>, searchKeyword: string): List<Model.IOrder> => {
    return list.filter((order: Model.IOrder) => {
      return searchInString(order.orderid, searchKeyword) ||
             searchInString(order.customerid, searchKeyword) ||
             searchInString(order.customer.name, searchKeyword)
    }).toList()
  }

  const assignAddress = async (orderid: string, addressid: string) => {
    // Request Start
    try {
      const order = await api.orders.assignAddress(orderid, addressid)
  
      const offlineDatas = Lib.listUnion(state.offlineDatas, List([order]), (item: Model.IOrder) => {
        return item.orderid
      })

      return dispatch(provider, {
        ...state,
        offlineDatas
      })
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const assignNewAddress = async (orderid: string, customerid: string, shippingmethod: string, name: string, phone: string, addressdetail: string) => {
    // Request Start
    try {
      const order = await api.orders.assignNewAddress(orderid, customerid, shippingmethod, name, phone, addressdetail)
  
      const offlineDatas = Lib.listUnion(state.offlineDatas, List([order]), (item: Model.IOrder) => {
        return item.orderid
      })

      return dispatch(provider, {
        ...state,
        offlineDatas
      })
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const unassignAddress = async (orderid: string) => {
    // Request Start
    try {
      dispatch(provider, contextHandler(state).markOrderLoading(orderid))

      const order = await api.orders.unassignAddress(orderid)

      const offlineDatas = Lib.listUnion(state.offlineDatas, List([order]), (item: Model.IOrder) => {
        return item.orderid
      })

      return dispatch(provider, {
        ...contextHandler(state).markOrderNotLoading(orderid),
        offlineDatas
      })
    } catch (error) {
      return await dispatch(provider, {
        ...contextHandler(state).markOrderNotLoading(orderid)
      })
    }
  }

  const assignShippingMethod = async (orderid: string, shippingmethod: string) => {
    // Request Start
    try {
      const order = await api.orders.assignShippingMethod(orderid, shippingmethod)
  
      const offlineDatas = Lib.listUnion(state.offlineDatas, List([order]), (item: Model.IOrder) => {
        return item.orderid
      })

      return dispatch(provider, {
        ...state,
        offlineDatas
      })
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (orderid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(orderid))
  }

  const createRequest = async (order: Model.Form.IOrder) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.orders.create(order)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const depositPaymentRequest = async (customerid: string, channel: string, amount: number, remarks: string, orderid: string, payment: number | undefined) => {
    // Rquest Start
    dispatch(provider, contextHandler(state).depositPaymentRequestStart(orderid))

    try {
      // Requesting
      await api.customers.depositPayment(customerid, channel, amount, remarks, orderid, payment)
      const order = await api.orders.get(orderid)

      // Request Succeed
      return dispatch(provider, contextHandler(state).depositPaymentRequestSucceed(order))
    } catch (error) {
      // Request Failed
      return dispatch(provider, contextHandler(state).depositPaymentRequestFail(orderid, error))
    }
  }

  const loadMore = async (batchid: string, isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart(batchid))

      try {
        // Requesting
        const result = await api.orders.getPaged(batchid, lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(batchid, result.orders, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(batchid, error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (orderid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(orderid))
  }

  const updateRequest = async (order: Model.Form.IOrder) => {
    // Request Start
    const { orderid } = order
    dispatch(provider, contextHandler(state).updateRequestStart(orderid))

    try {
      const resultOrder = await api.orders.update(order)

      const closeFormState = contextHandler(state).closeEditForm(orderid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultOrder))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, orderid))
    }
  }

  return {
    getFetchDataByBatchId,
    getOfflineDatas,

    assignAddress,
    assignNewAddress,
    assignShippingMethod,
    unassignAddress,

    closeCreateForm,
    closeEditForm,
    createRequest,
    depositPaymentRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: IOrderContext) => {
  provider.setState({
    order: newState
  })
}

const contextHandler = (previousState: IOrderContext) => {
  const markOrderLoading = (orderid: string) => {
    const orderStatuses = previousState.orderStatuses
    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      isLoading: true
    }

    return {
      ...previousState,
      orderStatuses
    }
  }

  const markOrderNotLoading = (orderid: string): IOrderContext => {
    const orderStatuses = previousState.orderStatuses
    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      isLoading: false
    }

    return {
      ...previousState,
      orderStatuses
    }
  }
  
  const listRequestStart = (batchid: string) => {
    const groupedFetchDatas = previousState.groupedFetchDatas.asMutable()
    if (groupedFetchDatas.has(batchid)) {
      groupedFetchDatas.get(batchid).isFetching = true
    } else {
      const fetchData = EmptyFetchData()
      fetchData.isFetching = true
      groupedFetchDatas.set(batchid, fetchData)
    }
    
    return {
      ...previousState,
      groupedFetchDatas,
    }
  }

  const listRequestSucceed = (batchid: string, orders: Model.IOrder[], pagesize: number) => {
    let hasMore = true

    let groupedFetchDatas = previousState.groupedFetchDatas
    let fetchData = EmptyFetchData()
    if (groupedFetchDatas.has(batchid)) {
      fetchData = groupedFetchDatas.get(batchid)
    }
    let currentPage = fetchData.lastFetchedPage
  
    if (orders.length === 0) {
      hasMore = false
    } else if (orders.length > 0) {
      currentPage++
    }
  
    if (orders.length < pagesize) {
      hasMore = false
    }
  
    const resultDatas = Lib.listUnion(previousState.offlineDatas, List(orders), (item: Model.IOrder) => {
      return item.orderid
    })

    fetchData.fetchErrorMessage = ""
    fetchData.hasMore = hasMore
    fetchData.isFetching = false
    fetchData.lastFetchedPage = currentPage
    groupedFetchDatas = groupedFetchDatas.set(batchid, fetchData)
  
    return {
      ...previousState,
      groupedFetchDatas,
      offlineDatas: resultDatas,
    }
  }

  const listRequestFail = (batchid: string, error: Model.IApiErrorResult) => {
    // return {
    //   ...previousState,
    //   fetchErrorMessage: error.data.message,
    //   isFetching: false
    // }
    const groupedFetchDatas = previousState.groupedFetchDatas
    let fetchData = EmptyFetchData()
    if (groupedFetchDatas.has(batchid)) {
      fetchData = groupedFetchDatas.get(batchid)
    }
    fetchData.fetchErrorMessage = error.data.message
    fetchData.isFetching = false
    groupedFetchDatas.set(batchid, fetchData)

    return {
      ...previousState,
      groupedFetchDatas
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createOrder: Model.EmptyOrder(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (order: Model.IOrder) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([order]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (orderid: string) => {
    const orderStatuses = previousState.orderStatuses
    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      errorMessage: "",
      id: orderid,
      isEditing: true,
      isLoading: false,
    }

    return {
      ...previousState,
      orderStatuses,
    }
  }

  const closeEditForm = (orderid: string) => {
    const orderStatuses = previousState.orderStatuses
    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      isEditing: false,
    }

    return {
      ...previousState,
      orderStatuses,
    }
  }

  const updateRequestStart = (orderid: string) => {
    const orderStatuses = previousState.orderStatuses
    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      isLoading: true,
    }

    return {
      ...previousState,
      orderStatuses
    }
  }

  const updateRequestSucceed = (order: Model.IOrder) => {
    const orderStatuses = previousState.orderStatuses
    delete orderStatuses[order.orderid]

    const offlineDatas = Lib.listUnion(previousState.offlineDatas, List([order]), (item: Model.IOrder) => {
      return item.orderid
    })

    return {
      ...previousState,
      offlineDatas,
      orderStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, orderid: string) => {
    const orderStatuses = previousState.orderStatuses

    orderStatuses[orderid] = {
      ...orderStatuses[orderid],
      errorMessage: error.data.message,
      id: orderid,
      isLoading: false,
    }

    return {
      ...previousState,
      orderStatuses,
    }
  }

  const depositPaymentRequestStart = (orderid: string) => {
    const updatePaymentStatuses = { ...previousState.updatePaymentStatuses }
    updatePaymentStatuses[orderid] = {
      ...updatePaymentStatuses[orderid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updatePaymentStatuses
    }
  }
  
  const depositPaymentRequestSucceed = (order: Model.IOrder) => {
    const updatePaymentStatuses = { ...previousState.updatePaymentStatuses }
    delete updatePaymentStatuses[order.orderid]

    const offlineDatas = Lib.listUnion(previousState.offlineDatas, List([order]), (item: Model.IOrder) => {
      return item.orderid
    })

    return {
      ...previousState,
      offlineDatas,
      updatePaymentStatuses,
    }
  }

  const depositPaymentRequestFail = (orderid: string, error: Model.IApiErrorResult) => {
    const updatePaymentStatuses = { ...previousState.updatePaymentStatuses }
    updatePaymentStatuses[orderid] = {
      ...updatePaymentStatuses[orderid],
      errorMessage: error.data.message,
      isSubmitting: false,
    }

    return {
      ...previousState,
      updatePaymentStatuses,
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    depositPaymentRequestFail,
    depositPaymentRequestStart,
    depositPaymentRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    markOrderLoading,
    markOrderNotLoading,
    openCreateForm,
    openEditForm,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}