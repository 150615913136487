import * as React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import BatchEditForm from './FormEdit'

interface IProps extends IAppContextProps {
  batch: Model.IBatch
}

const Item: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getBatchState()
  const action = props.appcontext.getBatchAction()

  const batch = props.batch
  const updateBatch = state.updateStatuses[batch.batchid]

  const handleChangeToEdit = () => {
    action.openEditForm(props.batch.batchid)
  }

  return (
    <div>
      { updateBatch !== undefined && (
        <BatchEditForm {...props} formTitle={`編輯 ${props.batch.batchid}`} batch={batch} errorMessage={updateBatch.errorMessage} />
      )}
      <Card>
        <CardHeader>
          <Layout.Clearfix>
            { batch.batchname } <Layout.BadgeId>{ batch.batchid }</Layout.BadgeId>
            <div className="pull-right">
              <Button color="primary" outline={true} onClick={handleChangeToEdit}>編輯</Button>
            </div>
          </Layout.Clearfix>
        </CardHeader>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12}>
                截團日 <Layout.CalendarDate>{batch.cutoffdate}</Layout.CalendarDate>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                預定寄件日 <Layout.CalendarDate>{batch.shipdate}</Layout.CalendarDate>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                預定收件日 <Layout.CalendarDate>{batch.receivedatefrom}</Layout.CalendarDate> 至 <Layout.CalendarDate>{batch.receivedateto}</Layout.CalendarDate>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </div>
  )
}

export default withAppContext(Item)