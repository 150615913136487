import * as React from 'react'
// import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import './Footer.css'

export const Footer = () => {
  return (
    <footer className="sticky">
      <Container>
        <small>
          {/* <Link to="//www.kalissjapan.com">Copyright &copy; 2018 Kaliss Japan. All Rights Reserved.</Link> */}
          Copyright &copy; 2018 Kaliss Japan. All Rights Reserved.
        </small>
      </Container>
    </footer>
  )
}

export default Footer