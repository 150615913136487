import * as React from 'react'
import { Col, Container, Row } from 'reactstrap'

import * as Model from '../../models'
// import * as BatchApi from '../../api/BatchApi'
// import BatchSummary from '../common/batch/BatchSummary'
import * as Layout from '../layout';
// import FacebookSetup from '../../common/facebook/FacebookSetup';
// import * as Config from '../../../Config'

interface IDashboardState {
  data: {
    batches: Model.IBatch[];
  }
}

export class Dashboard extends React.Component<{}, IDashboardState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      data: {
        batches: []
      }
    }
  }

  public componentDidMount() {
    // const data = this.state.data

    // BatchApi.getActiveBatches().then((result: any) => {
    //   data.batches = result.batches

    //   this.setState({data})
    // })
  }

  public render() {
    // const { data } = this.state
    // const batches = data.batches
    // let fbAppId = Config.FB_APP_ID

    return (
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            {/* <FacebookSetup appId={fbAppId} /> */}
            <Layout.RowSeparator />
            團期(Active)
            {/* <BatchSummary batches={batches} /> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Dashboard