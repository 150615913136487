import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import BatchForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
}

const FormCreate: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getBatchState()
  const action = props.appcontext.getBatchAction()

  const handleCreate = (batch: Model.IBatch) => {
    action.createRequest(batch)
  }

  const handleCancel = () => {
    action.closeCreateForm()
  }

  return (
    <BatchForm isOpen={state.createStatus.isFormOpen} formTitle={props.formTitle} isCreate={true} batch={state.createStatus.data} onCreate={handleCreate} onCancel={handleCancel} errorMessage={state.createStatus.errorMessage} isLoading={state.createStatus.isSubmitting} />
  )
}

export default withAppContext(FormCreate)