import * as React from 'react'
import * as InfiniteScroll from 'react-infinite-scroller';
import MediaQuery from 'react-responsive'
import { Col, Row } from 'reactstrap'

/* tslint:disable no-var-requires*/
const MasonryInfiniteScroller = require('react-masonry-infinite').default
/* tslint:enable no-var-requires*/

import * as Layout from '.'

import './MasonryGallery.css'


interface IProps {
  hasMore: boolean;
  onLoadMore: () => void;
}

export default class Gallery extends React.Component<IProps> {
  public componentDidMount() {
    if (window.innerWidth < 768) {
      // this.loadMore()
    }
  }

  public render() {
    return (
      <div>
        <MediaQuery query={"(max-width: 767px)"}>
          <InfiniteScroll
            // pageStart={0}
            hasMore={this.props.hasMore}
            loadMore={this.loadMore}
            loader={ <Layout.Loader key="loader" /> }
          >
            <Row className="gallery-columns">
              <Col xs={12}>
                {this.props.children}
              </Col>
            </Row>
          </InfiniteScroll>
        </MediaQuery>
        <MediaQuery query={"(min-width: 768px)"}>
          <MasonryInfiniteScroller
            className="gallery-masonry"
            loader={ <Layout.Loader key="loader" /> }
            hasMore={this.props.hasMore}
            loadMore={this.loadMore}
          >
            {this.props.children}
          </MasonryInfiniteScroller>
        </MediaQuery>
      </div>
    )
  }

  private loadMore = () => {
    if (this.props.hasMore && this.props.onLoadMore) {
      this.props.onLoadMore()
    }
  }
}