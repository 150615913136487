import * as React from 'react'
import { Button, Col, Container, Input, Row } from "reactstrap"

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import AddressDropdown from "../address/dropdown"
import ShippingMethodDropdown from "./dropdown"
import ShippingMethodLabel from './ShippingMethodLabel'

interface IProps extends IAppContextProps {
  order: Model.IOrder
}

interface IState {
  name: string
  phone: string
  addressdetail: string
}

class ShippingAddress extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      addressdetail: "",
      name: "",
      phone: "",
    }
  }

  public render() {
    const address = this.props.order.address
    if (address) {
      return (
        <div>
          <ShippingMethodLabel shippingmethod={this.props.order.shippingmethod} />
          <div>收件人: {address.name}</div>
          { address.phone && (
            <div>聯絡電話: {address.phone}</div>
          )}
          { address.addressdetail && (
            <div> { this.props.order.shippingmethod === "face" ? "面交時間" : "地址" }: {address.addressdetail}</div>
          )}
          <Button color="danger" onClick={this.handleUnassignAddress}>Clear</Button>
        </div>
      )
    }

    return (
      this.props.order.shippingmethod === "" ? (
        <ShippingMethodDropdown order={this.props.order} onShippingMethodChanged={this.handleShippingMethodChange} />
      ) : (
        <Container>
          <Row>
            <Col>
              <ShippingMethodLabel shippingmethod={this.props.order.shippingmethod} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              舊有地址<br/>
              <AddressDropdown onAddressChanged={this.handleAddressChanged} defaultValue="" customerid={this.props.order.customerid} shippingtype={this.props.order.shippingmethod} />
            </Col>
            <Col sm={6}>
              或 新地址<br/>
              <Input name="name" onChange={this.handleNameChange} placeholder="收件人" />
              <Input name="phone" onChange={this.handlePhoneChange} placeholder="電話" />
              <Input name="addressdetail" onChange={this.handleAddressDetailChange} placeholder="地址" />
              <Button color="primary" onClick={this.handleAssignNewAddress}>新增</Button>
            </Col>
          </Row>
        </Container>
      )
    )
  }
  
  private handleAddressChanged = (newAddress: Model.IAddress) => {
    const action = this.props.appcontext.getOrderAction()

    action.assignAddress(this.props.order.orderid, newAddress.addressid)
    // /* tslint:disable no-console */
    // console.log("handleAddressChanged")
    // /* tslint:enable no-console */
  }

  private handleShippingMethodChange = (shippingmethod: Model.ShippingMethodKeys | "") => {
    const action = this.props.appcontext.getOrderAction()

    action.assignShippingMethod(this.props.order.orderid, shippingmethod)
  }

  private handleNameChange = (event: any) => {
    const name = event.target.value

    this.setState({name})
  }

  private handlePhoneChange = (event: any) => {
    const phone = event.target.value

    this.setState({phone})
  }

  private handleAddressDetailChange = (event: any) => {
    const addressdetail = event.target.value

    this.setState({addressdetail})
  }

  private handleAssignNewAddress = () => {
    const action = this.props.appcontext.getOrderAction()
    const order = this.props.order
    const state = this.state

    action.assignNewAddress(order.orderid, order.customerid, order.shippingmethod, state.name, state.phone, state.addressdetail)
  }

  private handleUnassignAddress = () => {
    const action = this.props.appcontext.getOrderAction()
    const order = this.props.order

    action.unassignAddress(order.orderid)
  }
}

export default withAppContext(ShippingAddress)