import * as React from 'react'
import { Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../../context/AppContext"
import * as Model from '../../../models'
import * as Layout from '../../layout'
import './index.css'

interface IProps extends IAppContextProps {
  customerid: string
  defaultValue: string
  onAddressChanged: (address: Model.IAddress) => void
  shippingtype: Model.ShippingMethodKeys
}

interface IState {
  isOpen: boolean
}

export class AddressDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  public componentDidUpdate() {
    const action = this.props.appcontext.getAddressAction()
    const addresses = action.getByCustomerId(this.props.customerid, this.props.shippingtype)

    // if (addresses.count() === 0) {
    //   return this.handleLoadMore()
    // }
    if (this.props.defaultValue && addresses.find(address => address !== undefined && address.addressid === this.props.defaultValue) === undefined) {
      return this.handleLoadMore()
    }
  }

  public render() {
    const state = this.props.appcontext.getAddressState()
    const action = this.props.appcontext.getAddressAction()
  
    const addresses = action.getByCustomerId(this.props.customerid, this.props.shippingtype)
    const { hasMore }  = state

    const selectedAddress = action.getByAddressId(this.props.defaultValue)

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle caret={true}>
          { selectedAddress ? selectedAddress.name : "請選擇" }
        </DropdownToggle>
        <DropdownMenu className="scrollable-dropdown-menu">
          <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore} notUseWindow={true}>
            <Container>
              <Row>
                { addresses.map((address: Model.IAddress, index: number) => (
                  <Col key={address.addressid} xs={12}>
                    <div className="item" onClick={this.handleClick.bind(this, address)}>
                      <b>{address.name}</b> ({address.phone})<br/>
                      {address.addressdetail}
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Layout.ColumnGallery>
        </DropdownMenu>
      </Dropdown>
    )
  }

  private toggle = () => {
    const isOpen = !this.state.isOpen
    this.setState({isOpen})
  }

  private handleLoadMore = () => {
    const state = this.props.appcontext.getAddressState()
    const action = this.props.appcontext.getAddressAction()
  
    const { isFetching, lastFetchedPage, hasMore } = state

    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  private handleClick(address: Model.IAddress, event: any) {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onAddressChanged(address)
    })
  }
}

export default withAppContext(AddressDropdown)