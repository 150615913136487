import * as React from 'react'
import { Badge, ButtonGroup, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../../context/AppContext"
import * as Model from '../../../models'
import * as FormComponent from '../../form'
import * as Layout from '../../layout'
import CustomerChannelDropdown from '../CustomerChannelDropdown'
import CustomerChannelLabel from '../CustomerChannelLabel'

import './index.css'

interface IProps extends IAppContextProps {
  defaultValue: Model.Form.ICustomer
  onCustomerChanged: (customer: Model.Form.ICustomer) => void
  onCustomerCreated: (customer: Model.Form.ICustomer) => void
  onCustomerPicked: (customer: Model.ICustomer) => void
}

interface IState {
  isOpen: boolean
  searchKeyword: string
}

export class CustomerDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false,
      searchKeyword: ""
    }
  }

  public componentDidUpdate() {
    const action = this.props.appcontext.getCustomerAction()

    const customers = action.getOfflineDatas()

    if (customers.count() === 0) {
      return this.handleLoadMore()
    }
  }

  public render() {
    const state = this.props.appcontext.getCustomerState()
    const action = this.props.appcontext.getCustomerAction()

    const customers = action.getOfflineDatas(this.state.searchKeyword)
    const { hasMore }  = state

    const selectedCustomer = this.props.defaultValue

    return (
      <ButtonGroup className="customer-dropdown">
        <CustomerChannelDropdown color="info" value={selectedCustomer.channel} onChange={this.handleCustomerChannelChange} />
        <Dropdown className="search-dropdown" isOpen={this.state.isOpen} toggle={this.toggle}>
          <DropdownToggle tag="span" aria-expanded={this.state.isOpen}>
            <FormComponent.DelayTextbox className="customer-searchbox" value={this.state.searchKeyword} placeholder="SNS name" onChange={this.handleSearchKeywordChanged} />
          </DropdownToggle>
          <DropdownMenu className="scrollable-dropdown-menu">
            <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore}>
              <Container>
                <Row>
                  <Col xs={12}>
                    <FormComponent.DelayTextbox className="customer-create-searchbox" value={this.state.searchKeyword} placeholder="SNS name" onChange={this.handleSearchKeywordChanged} />
                  </Col>
                  { this.state.searchKeyword && (
                    <Col>
                      <div className="item" onClick={this.handleCreateCustomer.bind(this, selectedCustomer)}>
                        <CustomerChannelLabel value={selectedCustomer.channel} />: 
                        {selectedCustomer.name} <Badge color="primary">新增</Badge>
                      </div>
                    </Col>
                  )}
                </Row>
                <hr />
                <Row>
                  { customers.map((customer: Model.ICustomer, index: number) => (
                    <Col key={customer.customerid} xs={12} md={6} lg={4}>
                      <div className="item" onClick={this.handlePickCustomer.bind(this, customer)}>
                        <CustomerChannelLabel value={customer.channel} />{' '}{customer.name} <Layout.BadgeId>{ customer.customerid }</Layout.BadgeId>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Layout.ColumnGallery>
          </DropdownMenu>
        </Dropdown>
      </ButtonGroup>
    )
  }

  private toggle = () => {
    const isOpen = !this.state.isOpen
    this.setState({isOpen})
  }

  private handleLoadMore = () => {
    const state = this.props.appcontext.getCustomerState()
    const action = this.props.appcontext.getCustomerAction()

    const { isFetching, lastFetchedPage, hasMore } = state
    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  private handleCreateCustomer(customer: Model.Form.ICustomer, event: any) {
    this.setState({
      isOpen: false
    }, () => {
      customer.isnew = true
      this.props.onCustomerCreated(customer)
    })
  }

  private handlePickCustomer(customer: Model.ICustomer, event: any) {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onCustomerPicked(customer)
    })
  }

  private handleSearchKeywordChanged = (value: string) => {
    const selectedCustomer = this.props.defaultValue
    selectedCustomer.name = value

    this.setState({
      searchKeyword: value
    }, () => {
      this.props.onCustomerChanged(selectedCustomer)
    })
  }

  private handleCustomerChannelChange = (channel: string) => {
    const selectedCustomer = this.props.defaultValue
    selectedCustomer.channel = channel

    this.props.onCustomerChanged(selectedCustomer)
  }

}

export default withAppContext(CustomerDropdown)