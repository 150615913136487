import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Redirect } from 'react-router-dom'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Layout from "../layout"

interface IProps extends IAppContextProps, RouteComponentProps<any> {}

const OrderBatchIdRedirect: React.SFC<IProps> = (props) => {
  const match = props.match
  const state = props.appcontext.getBatchState()
  const action = props.appcontext.getBatchAction()

  // If Url contains batchid
  let currentBatchId = match.params.batchid
  if (currentBatchId) {
    return (<Redirect to={`/order/${currentBatchId}`} />)
  }

  // If not, try to get from batches
  currentBatchId = state.offlineDatas.first() && state.offlineDatas.first().batchid
  if (currentBatchId) {
    return (<Redirect to={`/order/${currentBatchId}`} />)
  }

  action.loadMore(state.isFetching, state.lastFetchedPage, state.hasMore)
  return (<Layout.Loader />)
}

export default withAppContext(OrderBatchIdRedirect)