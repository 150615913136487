import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Badge, Button } from 'reactstrap'

import * as Model from '../../models'
import * as Layout from '../layout'
import PaymentPanel from '../payment/PaymentPanel'

interface IOrderAmountSummaryProps {
  order: Model.IOrder
  // onPaymentDone: () => void
}
interface IOrderAmountSummaryState {
  isExpandPaymentPanel: boolean
}

class OrderAmountSummary extends React.Component<IOrderAmountSummaryProps, IOrderAmountSummaryState> {
  constructor(props: IOrderAmountSummaryProps) {
    super(props)

    this.state = {
      isExpandPaymentPanel: false
    }
  }

  public render() {
    const order = this.props.order
    const isExpandPaymentPanel = this.state.isExpandPaymentPanel
    const totalOrderPaymentRemain = order.derivedata.totalpaymentremain // OrderHandling.totalOrderPaymentRemain(order)
    const paymentTotalAmount = order.derivedata.paymenttotalamount // PaymentHandling.paymentTotalAmount(order.payments)
    const totalOrderAmountWithShipping = order.derivedata.totalorderamountwithshipping // OrderHandling.totalOrderAmountWithShipping(order, true)
    const totalOrderAmountWithShippingBeforeDiscount = order.derivedata.totalorderamountwithshippingbeforediscount // OrderHandling.totalOrderAmountWithShipping({order, beforediscount: false})
    const totalProductAmount = order.derivedata.totalproductamount // OrderHandling.totalProductAmount(order.orderproducts)

    return (
      <div>
        { order.discountprice > 0 ? (
          <span>
            折實:
            <Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShippingBeforeDiscount}</Layout.DollarSign>
            <span className="text-muted">
              {' '}
              全單:<Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShipping}</Layout.DollarSign> {' '}
              (貨品:<Layout.DollarSign sign="$" empty="-">{totalProductAmount}</Layout.DollarSign> {' '}
              運費:<Layout.DollarSign sign="$" empty="-">{order[order.shippingmethod]}{ order.shippingmethod === "sfcustomer" ? "(到付)" : ""}</Layout.DollarSign>{' '}
              { order.discountprice > 0 && (
                <span>
                  優惠:<Layout.DollarSign sign="$">-{order.discountprice}</Layout.DollarSign>
                </span>
              )}
              )
            </span>
          </span>
        ) : (
          <span>
            全單:<Layout.DollarSign sign="$" empty="-">{totalOrderAmountWithShipping}</Layout.DollarSign> {' '}
            (貨品:<Layout.DollarSign sign="$" empty="-">{totalProductAmount}</Layout.DollarSign> {' '}
            運費:<Layout.DollarSign sign="$" empty="-">{order[order.shippingmethod]}{ order.shippingmethod === "sfcustomer" ? "(到付)" : ""}</Layout.DollarSign>{' '}
            { order.discountprice > 0 && (
              <span>
                優惠:<Layout.DollarSign sign="$">-{order.discountprice}</Layout.DollarSign>
              </span>
            )}
            )
          </span>
        )}
        <br/>
        <Badge color="info">
          已付:{' '}<Layout.DollarSign sign="$" empty="-">{paymentTotalAmount}</Layout.DollarSign>
        </Badge> {' '}
        { totalOrderPaymentRemain > 0 && (
          <Badge color="danger">
            尚餘:{' '}<Layout.DollarSign sign="$" empty="-">{totalOrderPaymentRemain}</Layout.DollarSign>
          </Badge>
        )}
        { totalOrderPaymentRemain < 0 && (
          <Badge color="warning">
            多餘:{' '}<Layout.DollarSign sign="$" empty="-">{totalOrderPaymentRemain * -1}</Layout.DollarSign>
          </Badge>
        )}
        <Button type="button" color="link" onClick={this.expandPaymentPanel}>
          <FontAwesome name="list-alt" />{' '}入數/詳細資料
        </Button>
        { isExpandPaymentPanel && (
          <PaymentPanel order={order} />
        )}
      </div>
    )
  }

  private expandPaymentPanel = () => {
    const isExpandPaymentPanel = !this.state.isExpandPaymentPanel

    this.setState({isExpandPaymentPanel})
  }
}

export default OrderAmountSummary