import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { mailtrackers: Model.IMailTracker[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/mailtracker/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const create = (data: Model.IMailTracker) => {
  return api.post(`/api/mailtracker/create`, data).then(response => 
    response.data.mailtracker as Model.IMailTracker
  ) as Promise<Model.IMailTracker>
}

export const update = (data: Model.IMailTracker) => {
  return api.post(`/api/mailtracker/update`, data).then(response => 
    response.data.mailtracker as Model.IMailTracker
  ) as Promise<Model.IMailTracker>
}