import * as React from 'react'
import { Badge, Col, Row } from 'reactstrap'
// import * as FontAwesome from 'react-fontawesome'

import * as Model from '../../models'
// import * as StockApi from '../../../api/stock-api'
import * as Layout from '../layout'
import LazyImage from '../layout/LazyImage';
// import * as FormComponent from '../form'
import ProductName from '../product/ProductName'

interface IOrderProductListItemProps {
  orderProduct: Model.IOrderProduct,
  // onCreateStockUpdate: Function,
  // onAssignStockUpdate: Function,
  // onRemoveAssignStockUpdate: Function
}
interface IOrderProductListItemState {
  // Data
  available: any
  // Control
  expandStockPanel: boolean
}

class OrderProductListItem extends React.Component<IOrderProductListItemProps, IOrderProductListItemState> {
  constructor(props: IOrderProductListItemProps) {
    super(props)

    this.state = {
      available: [],
      expandStockPanel: false
    }
  }

  public componentWillReceiveProps() {
    const expandStockPanel = false

    this.setState({ expandStockPanel })
  }

  // handleAssignStock = (value: number) => {
  //   let orderProduct = this.props.orderProduct

  //   if (window.confirm(`指定現貨${value}件到此訂單?`)) {
  //     this.props.onAssignStockUpdate(orderProduct.productid, orderProduct.varianceid, value)
  //   }
  // }

  // handleCreateStock = (value: number) => {
  //   let orderProduct = this.props.orderProduct

  //   if (window.confirm(`買貨${value}件並指定到此訂單?`)) {
  //     this.props.onCreateStockUpdate(orderProduct.productid, orderProduct.varianceid, value)
  //   }
  // }

  // handleRemoveAssignStock = (value: number) => {
  //   let orderProduct = this.props.orderProduct

  //   if (window.confirm(`取消此訂單的${value}件現貨?`)) {
  //     this.props.onRemoveAssignStockUpdate(orderProduct.productid, orderProduct.varianceid, value)
  //   }
  // }

  // toggleExpandStockPanel = () => {
  //   let expandStockPanel = !this.state.expandStockPanel

  //   let orderProduct = this.props.orderProduct

  //   StockApi.getAvailable(orderProduct.productid, orderProduct.varianceid).then((result: any) => {
  //     let available = result

  //     this.setState({ available })
  //   }, (error: any) => {
  //     console.log(error)
  //   })
  // }

  public render() {
    // const available = this.state.available
    // const expandStockPanel = this.state.expandStockPanel
    const orderProduct = this.props.orderProduct
    // const amountNeeds = orderProduct.amount - (orderProduct.boughts.length || 0)
    const detail: any = orderProduct.detail || {}
    const variance: any = orderProduct.variance || {}

    return (
      <Row>
        {/* <Col xs={4} sm={3} md={2}>
          <div>
            { amountNeeds === 0 ? (
              <Badge color="success">
                <FontAwesome name="check" />
                已入貨: {orderProduct.boughts.length}
              </Badge>
            ) : (
              <Badge color="info">
                已入貨: {orderProduct.boughts.length}
              </Badge>
            )}
          </div>
          <div>
            { amountNeeds > 0 && (
              <Badge color="danger">尚欠: {amountNeeds}</Badge>
            )}
            { amountNeeds < 0 && (
              <Badge color="warning">多餘: {amountNeeds * -1}</Badge>
            )}
          </div>
        </Col> */}
        <Col xs={8} sm={2} lg={1}>
            <LazyImage s3key={detail.s3keythumbnail} alt={detail.name} width={50} height={50} />
        </Col>
        <Col xs={12} sm={7} md={8} lg={9}>
            <ProductName productname={detail.name} variancename={variance.name} />
            {' '}
            { orderProduct.amount > 1 &&
              <Badge color="info">x {orderProduct.amount}</Badge>
            }
            <br/>
            { orderProduct.amount === 1 ? (
              <Layout.DollarSign sign="$">{orderProduct.sellingprice}</Layout.DollarSign>
            ) : (
              <span>
                <Layout.DollarSign sign="$">{orderProduct.sellingprice * orderProduct.amount}</Layout.DollarSign> 
                (<Layout.DollarSign sign="$">{orderProduct.sellingprice}</Layout.DollarSign> x {orderProduct.amount})
              </span>
            )}
            {/* <div>
              <FormComponent.Button color="link" onClick={this.toggleExpandStockPanel}>
                <FontAwesome name="pencil" />
                入貨/管理存貨
              </FormComponent.Button>
            </div>
            { expandStockPanel && (
              <div>
                { available.length === 0 ? (
                  <Badge color="info">此貨沒有現貨</Badge>
                ) : (
                  <div>
                    <Badge color="success">此貨有現貨{available.length}件</Badge>
                    { amountNeeds > 0 &&
                      <div>
                        使用存貨: 
                        <ButtonGroup>
                          {
                            [...Array(Math.min(available.length, amountNeeds))].map((value, index) => (
                              <FormComponent.Button key={index} color="info" value={index+1} onClick={this.handleAssignStock}>{index+1}</FormComponent.Button>
                            ))
                          }
                        </ButtonGroup>
                      </div>
                    }
                  </div>
                )}
                { orderProduct.boughts.length > 0 &&
                  <div>
                    取消貨品: 
                    <ButtonGroup>
                      {
                        [...Array(orderProduct.boughts.length)].map((value, index) => (
                          <FormComponent.Button key={index} color="danger" value={index+1} onClick={this.handleRemoveAssignStock}>{index+1}</FormComponent.Button>
                        ))
                      }
                    </ButtonGroup>
                  </div>
                }
                { amountNeeds > 0 &&
                  <div>
                    買貨: 
                    <ButtonGroup>
                      {
                        [...Array(amountNeeds)].map((value, index) => (
                          <FormComponent.Button key={index} color="success" value={index+1} onClick={this.handleCreateStock}>{index+1}</FormComponent.Button>
                        ))
                      }
                    </ButtonGroup>
                  </div>
                }
              </div>
            )} */}
        </Col>
      </Row>
    )
  }
}

export default OrderProductListItem