import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInNumber, searchInString } from "./AppContext"

import * as api from "../api"
import * as Model from "../models"

export interface IMailTrackerContext {
  // Offline Data
  offlineDatas: List<Model.IMailTracker>

  // Search
  searchKeyword: string
  
  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
  
  // Create
  createStatus: {
    data: Model.IMailTracker
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  // Update
  updateStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyMailTrackerContext = (): IMailTrackerContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.IMailTracker>(),
    
    // Search
    searchKeyword: "",
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  
    // Create
    createStatus: {
      data: Model.EmptyMailTracker(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    updateStatuses: {}
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IMailTrackerAction {
  datasForDisplay: () => List<Model.IMailTracker>
  getOfflineDatas: (applyFilter: boolean) => List<Model.IMailTracker>
  getSearchKeyword: () => string

  closeCreateForm: () => void
  closeEditForm: (mailtrackerid: string) => void
  createRequest: (mailtracker: Model.IMailTracker) => void
  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (mailtrackerid: string) => void
  searchByKeyword: (keyword: string) => void
  updateRequest: (mailtracker: Model.IMailTracker) => void
}

export const MailTrackerAction = (state: IMailTrackerContext, provider: AppContextProvider): IMailTrackerAction => {
  const datasForDisplay = () => getOfflineDatas(true)
  const getSearchKeyword = () => state.searchKeyword
  const getOfflineDatas = (applyFilter: boolean): List<Model.IMailTracker> => {
    const offlineDatas = state.offlineDatas
    if (!applyFilter) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    const keyword = getSearchKeyword()
    return offlineDatas.filter((mailtracker: Model.IMailTracker) => {
      return searchInString(mailtracker.mailtrackerid, keyword) ||
            searchInString(mailtracker.description, keyword) ||
            searchInNumber(mailtracker.shippingfee, keyword) ||
            searchInNumber(mailtracker.weight, keyword)
    }).toList()
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (mailtrackerid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(mailtrackerid))
  }

  const createRequest = async (mailtracker: Model.IMailTracker) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.mailtrackers.create(mailtracker)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart())

      try {
        // Requesting
        const result = await api.mailtrackers.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(result.mailtrackers, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (mailtrackerid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(mailtrackerid))
  }

  const searchByKeyword = (keyword: string) => {
    dispatch(provider, contextHandler(state).searchByKeyword(keyword))
  }

  const updateRequest = async (mailtracker: Model.IMailTracker) => {
    // Request Start
    const { mailtrackerid } = mailtracker
    dispatch(provider, contextHandler(state).updateRequestStart(mailtrackerid))

    try {
      const resultMailTracker = await api.mailtrackers.update(mailtracker)

      const closeFormState = contextHandler(state).closeEditForm(mailtrackerid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultMailTracker))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, mailtrackerid))
    }
  }

  return {
    datasForDisplay,
    getOfflineDatas,
    getSearchKeyword,

    closeCreateForm,
    closeEditForm,
    createRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: IMailTrackerContext) => {
  provider.setState({
    mailTracker: newState
  })
}

const contextHandler = (previousState: IMailTrackerContext) => {
  const listRequestStart = () => {
    return {
      ...previousState,
      isFetching: true
    }
  }

  const listRequestSucceed = (mailtrackers: Model.IMailTracker[], pagesize: number) => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (mailtrackers.length === 0) {
      hasMore = false
    } else if (mailtrackers.length > 0) {
      currentPage++
    }
  
    if (mailtrackers.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.IMailTracker[] = _.filter(mailtrackers, (mailtracker: Model.IMailTracker) => {
      return previousState.offlineDatas.findIndex((item: Model.IMailTracker) => item.mailtrackerid === mailtracker.mailtrackerid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      ...previousState,
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }

  const listRequestFail = (error: Model.IApiErrorResult) => {
    return {
      ...previousState,
      fetchErrorMessage: error.data.message,
      isFetching: false
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createMailTracker: Model.EmptyMailTracker(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (mailtracker: Model.IMailTracker) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([mailtracker]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (mailtrackerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[mailtrackerid] = {
      errorMessage: "",
      id: mailtrackerid,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const closeEditForm = (mailtrackerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses}
    delete updateStatuses[mailtrackerid]
    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestStart = (mailtrackerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[mailtrackerid] = {
      ...updateStatuses[mailtrackerid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestSucceed = (mailtracker: Model.IMailTracker) => {
    const updateStatuses = { ...previousState.updateStatuses }
    delete updateStatuses[mailtracker.mailtrackerid]

    const offlineDatas = previousState.offlineDatas.asMutable().map((eachItem: Model.IMailTracker) => {
      if (eachItem.mailtrackerid === mailtracker.mailtrackerid) {
        return mailtracker
      }
      return eachItem
    }).toList()

    return {
      ...previousState,
      offlineDatas,
      updateStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, mailtrackerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[mailtrackerid] = {
      ...updateStatuses[mailtrackerid],
      errorMessage: error.data.message,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const searchByKeyword = (keyword: string) => {
    return {
      ...previousState,
      searchKeyword: keyword
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}