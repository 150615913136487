import * as React from 'react'
import { Col, Container, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import { DelayTextbox } from '../form'
import * as Layout from '../layout'
import Item from "./Item"

interface IState {
  searchKeyword: string
}

class PageList extends React.Component<IAppContextProps, IState> {
  public constructor(props: IAppContextProps) {
    super(props)

    this.state = {
      searchKeyword: ""
    }
  }

  public render() {
    const state = this.props.appcontext.getOldProductState()
    const action = this.props.appcontext.getOldProductAction()

    const oldproducts = action.getOfflineDatas(this.state.searchKeyword)
    const { hasMore }  = state

    return (
      <Container>
        <Layout.RowSeparator />
        <DelayTextbox value="" onChange={this.handleSearchBoxChange} placeholder="搜尋舊貨品" />
        <Layout.RowSeparator />
        <Row>
          { oldproducts.count() <= 0 && (
            ( this.isSearching() ? 
              <Col xs={12}>沒有找到符合「{ this.state.searchKeyword }」的資料</Col>
              :
              <Col xs={12}>沒有資料</Col>
            )
          )}
        </Row>
        <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore}>
          <Row>
            { oldproducts.map((oldproduct: Model.IOldProduct, index: number) => (
              <Col key={oldproduct.productid} className="mt-2 mb-2" xs={12} md={6} xl={4}>
                <Layout.IndexOf index={index} filteredListCount={oldproducts.count()} wholeListCount={state.offlineDatas.count()} hasMore={state.hasMore} />
                {/* <pre>{ JSON.stringify(oldproduct) }</pre> */}
                <Item oldproduct={oldproduct} />
              </Col>
            ))}
          </Row>
        </Layout.ColumnGallery>
      </Container>
    )
  }

  private handleLoadMore = () => {
    const state = this.props.appcontext.getOldProductState()
    const action = this.props.appcontext.getOldProductAction()
    
    const { isFetching, lastFetchedPage, hasMore } = state

    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  private handleSearchBoxChange = (value: string) => {
    this.setState({
      searchKeyword: value
    })
  }

  private isSearching = () => {
    const state = this.state
    return !(state.searchKeyword === "" || state.searchKeyword === undefined || state.searchKeyword === null)
  }
}

export default withAppContext(PageList)