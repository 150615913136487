import * as React from 'react';

import { isAuth } from './auth'
import * as Layout from "./components/layout"
import LoginForm from './components/login/LoginForm'
import Footer from './components/nav/Footer'
import Header from './components/nav/Header'
import Main from './components/nav/Main'
import { AppContextProvider } from "./context/AppContext"

import './App.css';

class App extends React.Component {
  public render() {
    if (isAuth()) {
      return (
        <AppContextProvider>
          <div className="App">
            <Header />
            <Main />
            <Footer />
          </div>
        </AppContextProvider>
      )
    }

    return (
      <Layout.MiddleAlign>
        <LoginForm />
      </Layout.MiddleAlign>
    )
  }
}

export default App;
