import * as React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import { DelayTextbox } from '../form'
import * as Layout from '../layout'
import FormCreate from './FormCreate'
import Item from './Item'

const PageList: React.SFC<IAppContextProps> = (props) => {
  const state = props.appcontext.getBatchState()
  const action = props.appcontext.getBatchAction()

  const batches = action.datasForDisplay().toArray()
  const { hasMore }  = state
  const { isFormOpen } = state.createStatus

  const handleLoadMore = () => {
    const { isFetching, lastFetchedPage } = state

    action.loadMore(isFetching, lastFetchedPage, hasMore)
  }

  const handleSearchBoxChange = (value: string) => {
    action.searchByKeyword(value)
  }

  const isSearching = () => {
    return !(state.searchKeyword === "" || state.searchKeyword === undefined || state.searchKeyword === null)
  }

  return (
    <Container>
      { isFormOpen && (
        <FormCreate {...props} formTitle="新增團期" />
      )}
      <Row>
        <Col xs={12}>
          <Button color="primary" className="pull-right" onClick={action.openCreateForm}>新增團期</Button>
        </Col>
      </Row>
      <Layout.RowSeparator />
      <DelayTextbox value="" onChange={handleSearchBoxChange} placeholder="搜尋團期" />
      <Layout.RowSeparator />
      <Row>
        { batches.length <= 0 && (
          ( isSearching() ? 
            <Col xs={12}>沒有找到符合「{ state.searchKeyword }」的資料</Col>
            :
            <Col xs={12}>沒有資料</Col>
          )
        )}
      </Row>
      <Layout.ColumnGallery hasMore={hasMore} onLoadMore={handleLoadMore}>
        <Row>
          { batches.map((batch: Model.IBatch, index: number) => (
            <Col key={batch.batchid} className="mt-2 mb-2" xs={12} md={6} lg={4}>
              <Layout.IndexOf index={index} filteredListCount={batches.length} wholeListCount={state.offlineDatas.count()} hasMore={state.hasMore} />
              <Item {...props} batch={batch} />
            </Col>
          ))}
        </Row>
      </Layout.ColumnGallery>
    </Container>
  )
}

export default withAppContext(PageList)