import * as Model from '..'

// Orders

export interface IOrder {
  airmail: number
  batchid: string
  customer: Model.Form.ICustomer
  customerid: string
  discountpercentage: number
  discountprice: number
  ems: number
  orderid: string
  orderproducts: Model.Form.IOrderProduct[]
  remarks: string
  sal: number
  sfcustomer: number
  ship: number
  shippingmethod: Model.ShippingMethodKeys | ""
  surface: number
  surfaceregistered: number
}
export function ConvertFromOrderModel(order: Model.IOrder): IOrder {
  const formOrder = {
    airmail: order.airmail,
    batchid: order.batchid,
    customer: Model.Form.ConvertFromCustomerModel(order.customer),
    customerid: order.customerid,
    discountpercentage: order.discountpercentage,
    discountprice: order.discountprice,
    ems: order.ems,
    orderid: order.orderid,
    orderproducts: Model.Form.ConvertFromOrderProductsModel(order.orderproducts, order.orderid),
    remarks: order.remarks,
    sal: order.sal,
    sfcustomer: order.sfcustomer,
    ship: order.ship,
    shippingmethod: order.shippingmethod,
    surface: order.surface,
    surfaceregistered: order.surfaceregistered,
  }
  return formOrder
}