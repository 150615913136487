// Customer

export interface ICustomer {
  channel: string
  customerid: string
  name: string
}
export function EmptyCustomer(): ICustomer {
  return {
    channel: "ig",
    customerid: "",
    name: ""
  }
}