import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { oldproducts: Model.IOldProduct[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/oldproduct/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const getByProductId = (productid: string) => {
  return api.get(`/api/oldproduct/id/${productid}`).then(response =>
    response.data as Model.IOldProduct
  ) as Promise<Model.IOldProduct>
}

export const convertFromOldProduct = (product: Model.IProduct, oldproductid: string) => {
  const data = {
    oldproductid,
    product,
  }
  return api.post(`/api/product/convert/oldproduct`, data).then(response =>
    response.data.product as Model.IProduct
  ) as Promise<Model.IProduct>
}