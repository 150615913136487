import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInString } from "./AppContext"

import * as api from "../api"
import * as Model from "../models"

export interface IBatchContext {
  // Offline Data
  offlineDatas: List<Model.IBatch>

  // Search
  searchKeyword: string
  
  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
  
  // Create
  createStatus: {
    data: Model.IBatch
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  // Update
  updateStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyBatchContext = (): IBatchContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.IBatch>(),
    
    // Search
    searchKeyword: "",
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  
    // Create
    createStatus: {
      data: Model.EmptyBatch(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    updateStatuses: {}
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IBatchAction {
  datasForDisplay: () => List<Model.IBatch>
  getByBatchId: (batchId: string, applyFilter: boolean) => Model.IBatch
  getOfflineDatas: (applyFilter: boolean) => List<Model.IBatch>
  getSearchKeyword: () => string

  closeCreateForm: () => void
  closeEditForm: (batchid: string) => void
  createRequest: (batch: Model.IBatch) => void
  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (batchid: string) => void
  searchByKeyword: (keyword: string) => void
  updateRequest: (batch: Model.IBatch) => void
}

export const BatchAction = (state: IBatchContext, provider: AppContextProvider): IBatchAction => {
  const datasForDisplay = () => getOfflineDatas(true)
  const getByBatchId = (batchId: string, applyFilter: boolean): Model.IBatch => {
    const result = getOfflineDatas(applyFilter)
  
    return result.find((batch: Model.IBatch) => {
      return batch.batchid === batchId
    })
  }
  const getSearchKeyword = () => state.searchKeyword
  const getOfflineDatas = (applyFilter: boolean): List<Model.IBatch> => {
    const offlineDatas = state.offlineDatas
    if (!applyFilter) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    const keyword = getSearchKeyword()
    return offlineDatas.filter((batch: Model.IBatch) => {
      return searchInString(batch.batchid, keyword) ||
            searchInString(batch.batchname, keyword)
    }).toList()
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (batchid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(batchid))
  }

  const createRequest = async (batch: Model.IBatch) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.batches.create(batch)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart())

      try {
        // Requesting
        const result = await api.batches.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(result.batches, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (batchid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(batchid))
  }

  const searchByKeyword = (keyword: string) => {
    dispatch(provider, contextHandler(state).searchByKeyword(keyword))
  }

  const updateRequest = async (batch: Model.IBatch) => {
    // Request Start
    const { batchid } = batch
    dispatch(provider, contextHandler(state).updateRequestStart(batchid))

    try {
      const resultBatch = await api.batches.update(batch)

      const closeFormState = contextHandler(state).closeEditForm(batchid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultBatch))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, batchid))
    }
  }

  return {
    datasForDisplay,
    getByBatchId,
    getOfflineDatas,
    getSearchKeyword,

    closeCreateForm,
    closeEditForm,
    createRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: IBatchContext) => {
  provider.setState({
    batch: newState
  })
}

const contextHandler = (previousState: IBatchContext) => {
  const listRequestStart = () => {
    return {
      ...previousState,
      isFetching: true
    }
  }

  const listRequestSucceed = (batches: Model.IBatch[], pagesize: number) => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (batches.length === 0) {
      hasMore = false
    } else if (batches.length > 0) {
      currentPage++
    }
  
    if (batches.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.IBatch[] = _.filter(batches, (batch: Model.IBatch) => {
      return previousState.offlineDatas.findIndex((item: Model.IBatch) => item.batchid === batch.batchid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      ...previousState,
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }

  const listRequestFail = (error: Model.IApiErrorResult) => {
    return {
      ...previousState,
      fetchErrorMessage: error.data.message,
      isFetching: false
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createBatch: Model.EmptyBatch(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (batch: Model.IBatch) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([batch]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (batchid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[batchid] = {
      errorMessage: "",
      id: batchid,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const closeEditForm = (batchid: string) => {
    const updateStatuses = { ...previousState.updateStatuses}
    delete updateStatuses[batchid]
    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestStart = (batchid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[batchid] = {
      ...updateStatuses[batchid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestSucceed = (batch: Model.IBatch) => {
    const updateStatuses = { ...previousState.updateStatuses }
    delete updateStatuses[batch.batchid]

    const offlineDatas = previousState.offlineDatas.asMutable().map((eachItem: Model.IBatch) => {
      if (eachItem.batchid === batch.batchid) {
        return batch
      }
      return eachItem
    }).toList()

    return {
      ...previousState,
      offlineDatas,
      updateStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, batchid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[batchid] = {
      ...updateStatuses[batchid],
      errorMessage: error.data.message,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const searchByKeyword = (keyword: string) => {
    return {
      ...previousState,
      searchKeyword: keyword
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}