import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInNumber, searchInString, searchInStrings } from "./AppContext"

import * as api from "../api"
import * as Lib from "../lib"
import * as Model from "../models"

export interface IProductContext {
  // Offline Data
  offlineDatas: List<Model.IProduct>

  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
  
  // Create
  createStatus: {
    data: Model.IProduct
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  // Update
  updateStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyProductContext = (): IProductContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.IProduct>(),
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  
    // Create
    createStatus: {
      data: Model.EmptyProduct(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    updateStatuses: {}
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface IProductAction {
  datasForDisplay: (searchKeyword?: string) => List<Model.IProduct>
  getOfflineDatas: (searchKeyword?: string) => List<Model.IProduct>

  closeCreateForm: () => void
  closeEditForm: (productid: string) => void
  createRequest: (product: Model.Form.IProduct) => void
  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (productid: string) => void
  updateRequest: (product: Model.Form.IProduct) => void
  updateOne: (product: Model.IProduct) => void
}

export const ProductAction = (state: IProductContext, provider: AppContextProvider): IProductAction => {
  const datasForDisplay = (searchKeyword?: string) => getOfflineDatas(searchKeyword)
  const getOfflineDatas = (searchKeyword?: string): List<Model.IProduct> => {
    const offlineDatas = state.offlineDatas
    if (!searchKeyword) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    return applyFilterByKeyword(offlineDatas, searchKeyword)
  }

  const applyFilterByKeyword = (list: List<Model.IProduct>, searchKeyword: string): List<Model.IProduct> => {
    return list.filter((product: Model.IProduct) => {
      return searchInString(product.productid, searchKeyword) ||
             searchInString(product.description, searchKeyword) ||
             searchInString(product.name, searchKeyword) ||
             searchInString(product.privatenotes, searchKeyword) ||
             searchInStrings(product.tags.map(tag => tag.name), searchKeyword) ||
             searchInVariances(product.variances, searchKeyword)
    }).toList()
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (productid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(productid))
  }

  const createRequest = async (product: Model.Form.IProduct) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.products.create(product)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart())

      try {
        // Requesting
        const result = await api.products.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(result.products, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (productid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(productid))
  }

  const updateRequest = async (product: Model.Form.IProduct) => {
    // Request Start
    const { productid } = product
    dispatch(provider, contextHandler(state).updateRequestStart(productid))

    try {
      const resultProduct = await api.products.update(product)

      const closeFormState = contextHandler(state).closeEditForm(productid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultProduct))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, productid))
    }
  }

  const updateOne = (product: Model.IProduct) => {
    const products = state.offlineDatas
    const mergedProducts = Lib.listUnion(products, List([product]), item => item.productid)
    dispatch(provider, {...state, offlineDatas: mergedProducts})
  }

  return {
    datasForDisplay,
    getOfflineDatas,

    closeCreateForm,
    closeEditForm,
    createRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    updateOne,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: IProductContext) => {
  provider.setState({
    product: newState
  })
}

const contextHandler = (previousState: IProductContext) => {
  const listRequestStart = () => {
    return {
      ...previousState,
      isFetching: true
    }
  }

  const listRequestSucceed = (products: Model.IProduct[], pagesize: number) => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (products.length === 0) {
      hasMore = false
    } else if (products.length > 0) {
      currentPage++
    }
  
    if (products.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.IProduct[] = _.filter(products, (product: Model.IProduct) => {
      return previousState.offlineDatas.findIndex((item: Model.IProduct) => item.productid === product.productid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      ...previousState,
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }

  const listRequestFail = (error: Model.IApiErrorResult) => {
    return {
      ...previousState,
      fetchErrorMessage: error.data.message,
      isFetching: false
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createProduct: Model.EmptyProduct(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (product: Model.IProduct) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([product]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (productid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[productid] = {
      errorMessage: "",
      id: productid,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const closeEditForm = (productid: string) => {
    const updateStatuses = { ...previousState.updateStatuses}
    delete updateStatuses[productid]
    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestStart = (productid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[productid] = {
      ...updateStatuses[productid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestSucceed = (product: Model.IProduct) => {
    const updateStatuses = { ...previousState.updateStatuses }
    delete updateStatuses[product.productid]

    const offlineDatas = previousState.offlineDatas.asMutable().map((eachItem: Model.IProduct) => {
      if (eachItem.productid === product.productid) {
        return product
      }
      return eachItem
    }).toList()

    return {
      ...previousState,
      offlineDatas,
      updateStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, productid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[productid] = {
      ...updateStatuses[productid],
      errorMessage: error.data.message,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const searchByKeyword = (keyword: string) => {
    return {
      ...previousState,
      searchKeyword: keyword
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}

// Helper Methods
function searchInVariances(variances: Model.IVariance[], keyword: string): boolean {
  return _.find(variances, (variance: Model.IVariance) => {
    return searchInString(variance.name, keyword) ||
           searchInNumber(variance.primecost, keyword) ||
           searchInNumber(variance.sellingprice, keyword)
  }) !== undefined
}