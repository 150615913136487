import * as moment from 'moment'
import * as React from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import 'react-block-ui/style.css'

/* tslint:disable no-var-requires */
const BlockUi = require("react-block-ui").default
/* tslint:enable no-var-requires */

import * as Model from '../../models'
import * as FormComponent from '../form'
import * as Layout from '../layout'

interface IProps {
  isOpen: boolean
  formTitle: string
  isCreate: boolean
  batch: Model.IBatch
  onCreate?: (batch: Model.IBatch) => void
  onUpdate?: (batch: Model.IBatch) => void
  onCancel: (batchid?: string) => void
  errorMessage: string | undefined
  isLoading: boolean
}
interface IState {
  workingCopy: Model.IBatch
}

class FormGeneric extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      workingCopy: { ...props.batch }
    }
  }

  public render() {
    const workingCopy = this.state.workingCopy
    
    return (
      <Form>
        <Modal isOpen={this.props.isOpen} toggle={this.handleCancel} size="lg">
          <BlockUi tag="div" blocking={this.props.isLoading} loader={Layout.Loader}>
            <ModalHeader>
              <span>{this.props.formTitle}</span>
            </ModalHeader>
            <ModalBody>
              {this.props.isCreate &&
                <FormGroup row={true}>
                  <Label sm={2}>ID</Label>
                  <Col sm={10}>
                    <Input type="text" name="batchid" value={workingCopy.batchid} onChange={this.handleChangeBatchId} required={true} />
                  </Col>
                </FormGroup>
              }
              <FormGroup row={true}>
                <Label sm={2}>團名</Label>
                <Col sm={10}>
                  <Input type="text" name="batchname" value={workingCopy.batchname} onChange={this.handleChangeBatchName} placeholder="E.g. 18年01月A團" required={true} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>截團日</Label>
                <Col sm={10}>
                  <FormComponent.DatePickerInput name="cutoffdate" value={workingCopy.cutoffdate} onChange={this.handleChangeCutOffDate} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>預定寄件日</Label>
                <Col sm={10}>
                  <FormComponent.DatePickerInput name="shipdate" value={workingCopy.shipdate} onChange={this.handleChangeShipDate} />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>預定收件日</Label>
                <Col sm={10}>
                  <FormComponent.DatePickerInput name="receivedatefrom" value={workingCopy.receivedatefrom} onChange={this.handleChangeReceiveDateFrom} />
                  <Label>至</Label>
                  <FormComponent.DatePickerInput name="receivedateto" value={workingCopy.receivedateto} onChange={this.handleChangeReceiveDateTo} />
                </Col>
              </FormGroup>
              { this.props.errorMessage && (
                <Row>
                  <Col>
                    <Alert color="danger">{this.props.errorMessage}</Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="danger" className="mr-auto" onClick={this.handleClear}>重置</Button>
              <Button type="button" color="link" onClick={this.handleCancel}>取消</Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>確定</Button>
            </ModalFooter>
          </BlockUi>
        </Modal>
      </Form>
    )
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.submitBatch(this.state.workingCopy)
  }

  private submitBatch = (batch: Model.IBatch) => {
    if (this.props.isCreate) {
      // Submit Create
      if (this.props.onCreate) {
        this.props.onCreate(batch)
      }
    } else {
      // Submit Update
      if (this.props.onUpdate) {
        this.props.onUpdate(batch)
      }
    }
  }

  private handleChangeBatchId = (event: any) => {
    const batchid = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.batchid = batchid

    this.setState({workingCopy})
  }

  private handleChangeBatchName = (event: any) => {
    const batchname = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.batchname = batchname

    this.setState({workingCopy})
  }

  private handleChangeCutOffDate = (name: string, value: moment.Moment) => {
    const workingCopy = this.state.workingCopy
    workingCopy.cutoffdate = value

    this.setState({workingCopy})
  }

  private handleChangeShipDate = (name: string, value: moment.Moment) => {
    const workingCopy = this.state.workingCopy
    workingCopy.shipdate = value

    this.setState({workingCopy})
  }

  private handleChangeReceiveDateFrom = (name: string, value: moment.Moment) => {
    const workingCopy = this.state.workingCopy
    workingCopy.receivedatefrom = value

    this.setState({workingCopy})
  }

  private handleChangeReceiveDateTo = (name: string, value: moment.Moment) => {
    const workingCopy = this.state.workingCopy
    workingCopy.receivedateto = value

    this.setState({workingCopy})
  }

  private handleCancel = () => {
    if (window.confirm('確定要取消？')) {
      this.props.onCancel()
    }
  }

  private handleClear = () => {
    if (window.confirm("確定要重置？")) {
      this.setState({ workingCopy: { ... this.props.batch } })
    }
  }
}

export default FormGeneric