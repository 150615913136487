export const getJwt = () => {
  return sessionStorage.getItem('jwt')
}

export const setJwt = (jwt: string) => {
  sessionStorage.setItem('jwt', jwt)
}

export const clearJwt = () => {
  sessionStorage.removeItem('jwt')
}

export const isAuth = () => {
  return getJwt() !== null
}