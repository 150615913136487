import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Col, Container, Row } from 'reactstrap'

import * as OrderApi from "../../api/orders"
import { IAppContextProps, withAppContext } from "../../context/AppContext"
import history from '../../history'
import * as Model from '../../models'
import BatchDropdown from '../batch/dropdown'
import { DelayTextbox } from '../form'
import * as Layout from '../layout'
import FormCreate from './FormCreate'
import Item from './Item'

interface IProps extends IAppContextProps, RouteComponentProps<any> {}
interface IState {
  searchKeyword: string
}

class PageList extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      searchKeyword: ""
    }
  }

  public render() {
    const state = this.props.appcontext.getOrderState()
    const action = this.props.appcontext.getOrderAction()

    const currentBatchId = this.props.match.params.batchid
    const orders = action.getOfflineDatas(currentBatchId, this.state.searchKeyword).toArray()
    const fetchData = action.getFetchDataByBatchId(currentBatchId)
    const { hasMore }  = fetchData
    const { isFormOpen } = state.createStatus


    return (
      <Container>
        { isFormOpen && (
          <FormCreate {...this.props} formTitle="新增訂單" batchid={currentBatchId} />
        )}
        <Row>
          <Col xs={6}>
            <Button color="success" onClick={this.handleExportExcel}>匯出 Excel</Button>
          </Col>
          <Col xs={6}>
            <Button color="primary" className="pull-right" onClick={action.openCreateForm}>新增訂單</Button>
          </Col>
        </Row>
        <Layout.RowSeparator />
        <DelayTextbox value="" onChange={this.handleSearchBoxChange} placeholder="搜尋訂單" />
        <Layout.RowSeparator />
        <BatchDropdown defaultValue={currentBatchId} onBatchChanged={this.handleBatchChanged} />
        <Layout.RowSeparator />
        <Row>
          { orders.length <= 0 && (
            ( this.isSearching() ? 
              <Col xs={12}>沒有找到符合「{ this.state.searchKeyword }」的資料</Col>
              :
              <Col xs={12}>沒有資料</Col>
            )
          )}
        </Row>
        <Layout.ColumnGallery hasMore={hasMore} onLoadMore={this.handleLoadMore}>
          <Row>
            { orders.map((order: Model.IOrder, index: number) => (
              <Col key={order.orderid} className="mt-2 mb-2" xs={12} lg={6}>
                <Layout.IndexOf index={index} filteredListCount={orders.length} wholeListCount={action.getOfflineDatas(currentBatchId).count()} hasMore={fetchData.hasMore} />
                <Item {...this.props} order={order} />
              </Col>
            ))}
          </Row>
        </Layout.ColumnGallery>
      </Container>
    )
  }

  private handleLoadMore = () => {
    const currentBatchId = this.props.match.params.batchid
    const action = this.props.appcontext.getOrderAction()
  
    const { isFetching, lastFetchedPage, hasMore } = action.getFetchDataByBatchId(currentBatchId)

    action.loadMore(currentBatchId, isFetching, lastFetchedPage, hasMore)
  }

  private handleSearchBoxChange = (value: string) => {
    this.setState({
      searchKeyword: value
    })
  }

  private isSearching = () => {
    const state = this.state
    return !(state.searchKeyword === "" || state.searchKeyword === undefined || state.searchKeyword === null)
  }

  private handleBatchChanged = (batch: Model.IBatch) => {
    history.push(`/order/${batch.batchid}`)
  }

  private handleExportExcel = () => {
    const currentBatchId = this.props.match.params.batchid
    OrderApi.downloadExcel(currentBatchId)
  }
}

export default withAppContext(PageList)