import { List } from "immutable"
import * as _ from 'lodash'
import * as React from 'react'
import 'react-block-ui/style.css'
import { Button, Col, Form, FormGroup, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

/* tslint:disable no-var-requires*/
const BlockUi = require("react-block-ui").default
/* tslint:enable no-var-requires*/

import * as api from "../../api"
import * as Model from '../../models'
import * as FormComponent from '../form'
import * as Layout from '../layout'
import TagEditPanel from "../tag/TagEditPanel"
import VarianceForm from '../variance/VarianceForm'

interface IProps {
  formTitle: string
  isLoading: boolean
  oldproduct: Model.IOldProduct
  onCancel: (productid?: string) => void
  onCreate: (product: Model.IProduct) => void
}
interface IState {
  workingCopy: Model.IProduct
}

class FormCreate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      workingCopy: Model.EmptyProduct()
    }
  }

  public componentDidMount() {
    api.oldproducts.getByProductId(this.props.oldproduct.productid).then(oldproduct => {
      this.setState({
        workingCopy: Model.convertFromOldProduct(oldproduct)
      })
    })
  }

  public render() {
    const workingCopy = this.state.workingCopy
    const isLoading = this.props.isLoading
    
    return (
      <Form>
        <Modal isOpen={true} toggle={this.handleCancel} size="lg">
          <BlockUi tag="div" blocking={isLoading} loader={Layout.Loader}>
            <ModalHeader>
              <span>{this.props.formTitle}</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup row={true}>
                <Label sm={2}>Old Product ID</Label>
                <Col sm={10}>
                  {workingCopy.productid}
                </Col>
              </FormGroup>
              <Media>
                <Layout.LazyImage s3key={workingCopy.s3keythumbnail} alt={workingCopy.name} />
              </Media>
              <FormGroup>
                <Label>Name</Label>
                <Input name="name" type="text" value={workingCopy.name} onChange={this.handleChange} />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input name="description" type="textarea" value={workingCopy.description} onChange={this.handleChange} placeholder="Description (free text)" />
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>Variances</Label>
                <Col sm={10}>
                  <Row>
                    { workingCopy.variances.map((variance: Model.IVariance, index: number) => (
                      <Col key={index} xs={12}>
                        <VarianceForm index={index} variance={variance} handleVarianceChange={this.handleVarianceChange} />
                      </Col>
                    ))}
                    <Col sm={12}>
                      <Button type="button" onClick={this.handleAddVariance}>Add Variance</Button>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup>
                <Label>Private Notes</Label>
                <Input name="privatenotes" type="textarea" value={workingCopy.privatenotes} onChange={this.handleChange} placeholder="Remarks (free text)" />
              </FormGroup>
              <FormGroup check={true}>
                <FormComponent.Checkbox name="isusj" onCheckToggled={this.handleCheckToggled} value={workingCopy.isusj} text="USJ?" />
              </FormGroup>
              <FormGroup check={true}>
                <FormComponent.Checkbox name="ismij" onCheckToggled={this.handleCheckToggled} value={workingCopy.ismij} text="日本製?" />
              </FormGroup>
              <FormGroup check={true}>
                <FormComponent.Checkbox name="isonweb" onCheckToggled={this.handleCheckToggled} value={workingCopy.isonweb} text="On Web" />
              </FormGroup>
              <FormGroup check={true}>
                <FormComponent.Checkbox name="isonfb" onCheckToggled={this.handleCheckToggled} value={workingCopy.isonfb} text="On FB" />
              </FormGroup>
              <FormGroup check={true}>
                <FormComponent.Checkbox name="isonig" onCheckToggled={this.handleCheckToggled} value={workingCopy.isonig} text="On IG" />
              </FormGroup>
              <FormGroup>
                <TagEditPanel tags={workingCopy.tags} onAddTag={this.handleAddTag} onRemoveTag={this.handleRemoveTag} />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={this.handleCancel}>取消</Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>確定</Button>
            </ModalFooter>
          </BlockUi>
        </Modal>
      </Form>
    )
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.submitProduct(this.state.workingCopy)
  }

  private submitProduct = (product: Model.IProduct) => {
    this.props.onCreate(product)
  }

  private handleChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy[name] = value

    this.setState({workingCopy})
  }

  private handleCheckToggled = (name: string, value: boolean) => {
    const workingCopy = this.state.workingCopy
    workingCopy[name] = value
    this.setState({workingCopy})
  }

  private handleVarianceChange = (targetVariance: Model.Form.IVariance) => {
    const workingCopy = this.state.workingCopy

    const index = _.findIndex(workingCopy.variances, (variance: Model.Form.IVariance) => {
      return variance.varianceid === targetVariance.varianceid
    })
    workingCopy.variances[index] = targetVariance
    this.setState({workingCopy})
  }

  private handleAddVariance = (event: any) => {
    const workingCopy = this.state.workingCopy

    const newVariance: Model.Form.IVariance = { varianceid: this.makeid(), productid: workingCopy.productid, iscreate: true, isdelete: false, ishiddenprice: false, name: '', sellingprice: 0, primecost: 0, primecostadjust: 0 }
    workingCopy.variances.push(newVariance)

    this.setState({workingCopy})
  }

  private handleAddTag = (tagname: string) => {
    const workingCopy = this.state.workingCopy

    workingCopy.tags.push({ name: tagname })

    this.setState({workingCopy})
  }

  private handleRemoveTag = (tagToRemove: Model.ITag) => {
    const workingCopy = this.state.workingCopy
    
    workingCopy.tags = List(workingCopy.tags).filterNot((value: Model.Form.ITag) => {
      return value.name === tagToRemove.name
    }).toArray()
    this.setState({workingCopy})
  }

  private makeid() {
    let text = ""
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
  
    return text
  }

  private handleCancel = () => {
    if (window.confirm('確定要取消？')) {
      this.props.onCancel()
    }
  }
}

export default FormCreate