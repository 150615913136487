import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Alert } from 'reactstrap'

import { List } from 'immutable'

interface IProps {
  errorMessages: List<string>
}

const ErrorMessages: React.SFC<IProps> = (props: IProps) => {
  if (props.errorMessages.count() <= 0) {
    return <span />
  }
  return (
    <span>
      { props.errorMessages.map((message: string, index: number) => (
        <Alert key={index} color="danger">
          <FontAwesome name="warning" />{' '}<span>{message}</span>
        </Alert>
      ))}
    </span>
  )
}

export default ErrorMessages