import * as React from 'react'

import SiteMenu from './SiteMenu'

export const Header = () => {
  return (
    <SiteMenu />
  )
}

export default Header