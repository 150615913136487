import * as React from 'react'
import { Badge } from 'reactstrap'

import './BadgeId.css'

export default class BadgeId extends React.Component {
  public render() {
    return (
      <small>
        <Badge color="dark" className="badge-description">{this.props.children}</Badge>
      </small>
    )
  }
}