import * as moment from 'moment'

// Batch

export interface IBatch {
  batchid: string
  batchname: string
  cutoffdate: moment.Moment
  receivedatefrom: moment.Moment
  receivedateto: moment.Moment
  shipdate: moment.Moment
}
export function EmptyBatch(): IBatch {
  return {
    batchid: '',
    batchname: '',
    cutoffdate: moment(),
    receivedatefrom: moment(),
    receivedateto: moment(),
    shipdate: moment()
  }
}