import * as React from 'react'

interface IDollarSignProps {
  sign?: string
  empty?: string | React.Component
}

const DollarSign: React.StatelessComponent<IDollarSignProps> = ({sign, empty, children}) => {
  if (children) {
    return (
      <span>{sign}{children}</span>
    )
  }

  const emptyText = empty || ""
  return (<span>{emptyText}</span>)
}

export default DollarSign