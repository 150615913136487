import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import BatchForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
  batch: Model.IBatch
  errorMessage: string | undefined
}

const FormEdit: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getBatchState()
  const action = props.appcontext.getBatchAction()

  const handleUpdate = (batchWorkingCopy: Model.IBatch) => {
    action.updateRequest(batchWorkingCopy)
  }
  
  const handleCancel = () => {
    action.closeEditForm(props.batch.batchid)
  }

  const updateBatch = state.updateStatuses[props.batch.batchid]

  return (
    <BatchForm isOpen={true} formTitle={props.formTitle} isCreate={false} batch={props.batch} onUpdate={handleUpdate} onCancel={handleCancel} errorMessage={props.errorMessage} isLoading={updateBatch.isSubmitting} />
  )
}

export default withAppContext(FormEdit)