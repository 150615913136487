import * as moment from 'moment'

import * as Model from '.'

// Orders

export interface IOrder {
  address: Model.IAddress
  addressid: string
  airmail: number
  batch: Model.IBatch
  batchid: string
  customer: Model.ICustomer
  customerid: string
  datecreated: moment.Moment
  derivedata: {
    paymentcompleted: boolean
    paymentexceeded: boolean
    paymentincompleted: boolean
    paymenttotalamount: number
    totalorderamountwithshipping: number
    totalorderamountwithshippingbeforediscount: number
    totalpaymentremain: number
    totalproductamount: number
  }
  discountpercentage: number
  discountprice: number
  ems: number
  orderid: string
  orderproducts: Model.IOrderProduct[]
  payments: Model.IPayment[]
  remarks: string
  sal: number
  sfcustomer: number
  ship: number
  shippingmethod: Model.ShippingMethodKeys | ""
  surface: number
  surfaceregistered: number
}
export function EmptyOrder(): IOrder {
  return {
    address: Model.EmptyAddress(),
    addressid: "",
    airmail: 0,
    batch: Model.EmptyBatch(),
    batchid: "",
    customer: Model.EmptyCustomer(),
    customerid: "",
    datecreated: moment(),
    derivedata: {
      paymentcompleted: false,
      paymentexceeded: false,
      paymentincompleted: true,
      paymenttotalamount: 0,
      totalorderamountwithshipping: 0,
      totalorderamountwithshippingbeforediscount: 0,
      totalpaymentremain: 0,
      totalproductamount: 0,
    },
    discountpercentage: 0,
    discountprice: 0,
    ems: 0,
    orderid: "",
    orderproducts: [],
    payments: [],
    remarks: "",
    sal: 0,
    sfcustomer: 0,
    ship: 0,
    shippingmethod: "",
    surface: 0,
    surfaceregistered: 0
  }
}