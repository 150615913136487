import * as React from 'react'
import { Media } from 'reactstrap'
// import LazyLoad from 'react-lazyload'

import { IMAGE_BASE_URL, OLD_PRODUCT_IMAGE_BASE_URL } from '../../Config'

interface ILazyImageProps {
  s3key: string,
  alt: string,
  width?: number,
  height?: number,
  isoldproduct?: boolean
}

const LazyImage: React.StatelessComponent<ILazyImageProps> = ({s3key, alt, width=80, height = 80, isoldproduct=false}) => {
  if (s3key === null) {
    return (<span />)
  }
  if (process.env.REACT_APP_LOAD_PRODUCT_IMAGE === "false") {
    return (<span />)
  }

  return (
    // <LazyLoad height={height}>
    <Media object={true} src={`${isoldproduct ? OLD_PRODUCT_IMAGE_BASE_URL : IMAGE_BASE_URL}/${s3key}`} alt={alt} style={{width, height}} />
    // </LazyLoad>
  )
}

export default LazyImage