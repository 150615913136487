import * as React from 'react'

import './ProductName.css'

interface IProductNameProps {
  productname?: string
  variancename?: string
}
const ProductName: React.SFC<IProductNameProps> = (props) => {
  const { productname, variancename } = props
  
  // Has product name, variance optional
  if (productname !== undefined && productname !== null && productname !== "") {
    return (
      <span>
        <strong className="product-name">
        { productname }
        </strong>{' '}
        { variancename !== undefined && variancename !== null && variancename !== "" && (
          <small className="variance-name"> ({variancename})</small>
        )}
      </span>
    )
  }

  // Variance only
  if (variancename !== undefined && variancename !== null && variancename !== "") {
    return <span className="variance-name">{ variancename }</span>
  }

  // None
  return (
    <span />
  )
}

export default ProductName