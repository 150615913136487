import * as React from 'react'
import { Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import * as Model from '../../../models'
import * as Layout from '../../layout'
import ShippingMethodLabel from '../ShippingMethodLabel'
import './index.css'

interface IProps {
  order: Model.IOrder
  onShippingMethodChanged: (shippingmethod: string) => void
}

interface IState {
  isOpen: boolean
}

export default class ShippingMethodDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  public render() {
    const order = this.props.order
    const shippingmethod = order.shippingmethod
  
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle caret={true}>
          <ShippingMethodLabel shippingmethod={shippingmethod} />
          { shippingmethod !== "" &&
            <Layout.DollarSign>{ order[shippingmethod] }</Layout.DollarSign>
          }
        </DropdownToggle>
        <DropdownMenu className="scrollable-dropdown-menu">
          <Container>
            <Row>
              <Col>
                <div className="item" onClick={this.handleClick.bind(this, "face")}>
                  <ShippingMethodLabel shippingmethod="face" />
                </div>
              </Col>
            </Row>
            { order.surface > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "surface")}>
                    <ShippingMethodLabel shippingmethod="surface" /> <Layout.DollarSign>{ order.surface }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.surfaceregistered > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "surfaceregistered")}>
                    <ShippingMethodLabel shippingmethod="surfaceregistered" /> <Layout.DollarSign>{ order.surfaceregistered }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.sfcustomer > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "sfcustomer")}>
                    <ShippingMethodLabel shippingmethod="sfcustomer" /> <Layout.DollarSign>{ order.sfcustomer }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.ems > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "ems")}>
                    <ShippingMethodLabel shippingmethod="ems" /> <Layout.DollarSign>{ order.ems }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.airmail > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "airmail")}>
                    <ShippingMethodLabel shippingmethod="airmail" /> <Layout.DollarSign>{ order.airmail }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.sal > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "sal")}>
                    <ShippingMethodLabel shippingmethod="sal" /> <Layout.DollarSign>{ order.sal }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
            { order.ship > 0 &&
              <Row>
                <Col>
                  <div className="item" onClick={this.handleClick.bind(this, "ship")}>
                    <ShippingMethodLabel shippingmethod="ship" /> <Layout.DollarSign>{ order.ship }</Layout.DollarSign>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </DropdownMenu>
      </Dropdown>
    )
  }

  private toggle = () => {
    const isOpen = !this.state.isOpen
    this.setState({isOpen})
  }

  private handleClick = (shippingmethod: string, event: any) => {
    this.setState({
      isOpen: false
    }, () => {
      this.props.onShippingMethodChanged(shippingmethod)
    })
  }
}