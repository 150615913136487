import * as moment from 'moment'
import * as React from 'react'

interface ICalendarDateProps {
  format?: string;
  children: moment.Moment; // CalendarDateProps only accept Moment
  className?: string;
}

const CalendarDate: React.StatelessComponent<ICalendarDateProps> = ({format = 'YYYY-MM-DD', children = moment(), className = ""}) => {
  const hasDate = (children !== undefined)
  const date = children

  return (
    <span className={className}>
    {hasDate && (
      moment(date).format(format)
    )}
    </span>
  )
}

export default CalendarDate