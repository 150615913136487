import { List } from 'immutable'
import * as _ from "lodash"
import { AppContextProvider, searchInString } from "./AppContext"

import * as api from "../api"
import * as Model from "../models"

export interface ICustomerContext {
  // Offline Data
  offlineDatas: List<Model.ICustomer>
  
  // Fetching
  isFetching: boolean // UI indication
  lastFetchedPage: number // Last page fetched, "currentPage" in previous version
  hasMore: boolean // Indicate if there is any new page
  fetchErrorMessage: string
  
  // Create
  createStatus: {
    data: Model.ICustomer
    isFormOpen: boolean
    isSubmitting: boolean
    errorMessage: string
  }

  // Update
  updateStatuses: {
    [id: string]: {
      id: string
      isSubmitting: boolean
      errorMessage: string
    }
  }
}

export const EmptyCustomerContext = (): ICustomerContext => {
  /* tslint:disable:object-literal-sort-keys */
  return {
    offlineDatas: List<Model.ICustomer>(),
    
    // Fetching
    fetchErrorMessage: "",
    hasMore: true,
    isFetching: false,
    lastFetchedPage: -1,
  
    // Create
    createStatus: {
      data: Model.EmptyCustomer(),
      errorMessage: "",
      isFormOpen: false,
      isSubmitting: false
    },
  
    // Update
    updateStatuses: {}
  }
  /* tslint:enable:object-literal-sort-keys */
}

export interface ICustomerAction {
  getOfflineDatas: (searchKeywod?: string) => List<Model.ICustomer>

  closeCreateForm: () => void
  closeEditForm: (customerid: string) => void
  createRequest: (customer: Model.Form.ICustomer) => void
  loadMore: (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => void
  openCreateForm: () => void
  openEditForm: (customerid: string) => void
  updateRequest: (customer: Model.ICustomer) => void
}

export const CustomerAction = (state: ICustomerContext, provider: AppContextProvider): ICustomerAction => {
  const getOfflineDatas = (searchKeyword?: string): List<Model.ICustomer> => {
    const offlineDatas = state.offlineDatas
    if (!searchKeyword) {
      // No Filter
      return offlineDatas
    }
    
    // With Filter
    return applyFilterByKeyword(offlineDatas, searchKeyword)
  }

  const applyFilterByKeyword = (list: List<Model.ICustomer>, searchKeyword: string): List<Model.ICustomer> => {
    return list.filter((customer: Model.ICustomer) => {
      return searchInString(customer.customerid, searchKeyword) ||
             searchInString(customer.name, searchKeyword)
    }).toList()
  }

  const closeCreateForm = () => {
    dispatch(provider, contextHandler(state).closeCreateForm())
  }

  const closeEditForm = (customerid: string) => {
    dispatch(provider, contextHandler(state).closeEditForm(customerid))
  }

  const createRequest = async (customer: Model.ICustomer) => {
    // Request Start
    dispatch(provider, contextHandler(state).createRequestStart())

    try {
      const result = await api.customers.create(customer)

      const closeFormState = contextHandler(state).closeCreateForm()
      return dispatch(provider, contextHandler(closeFormState).createRequestSucceed(result))
    } catch (error) {
      return dispatch(provider, contextHandler(state).createRequestFail(error))
    }
  }

  const loadMore = async (isFetching: boolean, lastFetchedPage: number, hasMore: boolean) => {
    if (!isFetching && hasMore) {
      // Rquest Start
      dispatch(provider, contextHandler(state).listRequestStart())

      try {
        // Requesting
        const result = await api.customers.getPaged(lastFetchedPage+1)

        // Request Succeed
        return dispatch(provider, contextHandler(state).listRequestSucceed(result.customers, result.pagesize))
      } catch (error) {
        // Request Failed
        return dispatch(provider, contextHandler(state).listRequestFail(error))
      }
    }
  }

  const openCreateForm = () => {
    dispatch(provider, contextHandler(state).openCreateForm())
  }

  const openEditForm = (customerid: string) => {
    dispatch(provider, contextHandler(state).openEditForm(customerid))
  }

  const updateRequest = async (customer: Model.ICustomer) => {
    // Request Start
    const { customerid } = customer
    dispatch(provider, contextHandler(state).updateRequestStart(customerid))

    try {
      const resultCustomer = await api.customers.update(customer)

      const closeFormState = contextHandler(state).closeEditForm(customerid)
      return dispatch(provider, contextHandler(closeFormState).updateRequestSucceed(resultCustomer))
    } catch (error) {
      return dispatch(provider, contextHandler(state).updateRequestFail(error, customerid))
    }
  }

  return {
    getOfflineDatas,

    closeCreateForm,
    closeEditForm,
    createRequest,
    loadMore,
    openCreateForm,
    openEditForm,
    updateRequest,
  }
}

const dispatch = (provider: AppContextProvider, newState: ICustomerContext) => {
  provider.setState({
    customer: newState
  })
}

const contextHandler = (previousState: ICustomerContext) => {
  const listRequestStart = () => {
    return {
      ...previousState,
      isFetching: true
    }
  }

  const listRequestSucceed = (customers: Model.ICustomer[], pagesize: number) => {
    let hasMore = true
    let currentPage = previousState.lastFetchedPage
  
    if (customers.length === 0) {
      hasMore = false
    } else if (customers.length > 0) {
      currentPage++
    }
  
    if (customers.length < pagesize) {
      hasMore = false
    }
  
    const unexistDatas: Model.ICustomer[] = _.filter(customers, (customer: Model.ICustomer) => {
      return previousState.offlineDatas.findIndex((item: Model.ICustomer) => item.customerid === customer.customerid) < 0
    })
    const offlineDatas = previousState.offlineDatas.concat(List(unexistDatas)).toList()
  
    return {
      ...previousState,
      fetchErrorMessage: '',
      hasMore,
      isFetching: false,
      lastFetchedPage: currentPage,
      offlineDatas
    }
  }

  const listRequestFail = (error: Model.IApiErrorResult) => {
    return {
      ...previousState,
      fetchErrorMessage: error.data.message,
      isFetching: false
    }
  }

  const openCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      createCustomer: Model.EmptyCustomer(),
      isFormOpen: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const closeCreateForm = () => {
    const createStatus = {
      ...previousState.createStatus,
      isFormOpen: false
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestStart = () => {
    const createStatus = {
      ...previousState.createStatus,
      isSubmitting: true
    }

    return {
      ...previousState,
      createStatus
    }
  }

  const createRequestSucceed = (customer: Model.ICustomer) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: "",
      isSubmitting: false
    }

    const offlineDatas = List([customer]).concat(previousState.offlineDatas).toList()

    return {
      ...previousState,
      createStatus,
      offlineDatas
    }
  }

  const createRequestFail = (error: Model.IApiErrorResult) => {
    const createStatus = {
      ...previousState.createStatus,
      errorMessage: error.data.message,
      isSubmitting: false
    }
    
    return {
      ...previousState,
      createStatus
    }
  }

  const openEditForm = (customerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[customerid] = {
      errorMessage: "",
      id: customerid,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const closeEditForm = (customerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses}
    delete updateStatuses[customerid]
    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestStart = (customerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[customerid] = {
      ...updateStatuses[customerid],
      isSubmitting: true
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const updateRequestSucceed = (customer: Model.ICustomer) => {
    const updateStatuses = { ...previousState.updateStatuses }
    delete updateStatuses[customer.customerid]

    const offlineDatas = previousState.offlineDatas.asMutable().map((eachItem: Model.ICustomer) => {
      if (eachItem.customerid === customer.customerid) {
        return customer
      }
      return eachItem
    }).toList()

    return {
      ...previousState,
      offlineDatas,
      updateStatuses
    }
  }

  const updateRequestFail = (error: Model.IApiErrorResult, customerid: string) => {
    const updateStatuses = { ...previousState.updateStatuses }
    updateStatuses[customerid] = {
      ...updateStatuses[customerid],
      errorMessage: error.data.message,
      isSubmitting: false
    }

    return {
      ...previousState,
      updateStatuses
    }
  }

  const searchByKeyword = (keyword: string) => {
    return {
      ...previousState,
      searchKeyword: keyword
    }
  }

  return {
    closeCreateForm,
    closeEditForm,
    createRequestFail,
    createRequestStart,
    createRequestSucceed,
    listRequestFail,
    listRequestStart,
    listRequestSucceed,
    openCreateForm,
    openEditForm,
    searchByKeyword,
    updateRequestFail,
    updateRequestStart,
    updateRequestSucceed,
  }
}