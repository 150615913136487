import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { products: Model.IProduct[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/product/page/${page}`).then(response => 
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const getByProductId = (productid: string) => {
  return api.get(`/api/product/id/${productid}`).then(response =>
    response.data as Model.IProduct
  ) as Promise<Model.IProduct>
}

export const create = (data: Model.Form.IProduct) => {
  return api.post(`/api/product/create`, data).then(response =>
    response.data.product as Model.IProduct
  ) as Promise<Model.IProduct>
}

export const update = (data: Model.Form.IProduct) => {
  return api.post(`/api/product/update`, data).then(response =>
    response.data.product as Model.IProduct
  ) as Promise<Model.IProduct>
}