import * as Model from '..'

export interface ITag {
  isadd: boolean
  isdelete: boolean
  name: string
}
export function EmptyITag(): ITag {
  return {
    isadd: false,
    isdelete: false,
    name: ""
  }
}
export function ConvertFromTagsModel(variances: Model.ITag[]): ITag[] {
  return variances.map(variance => ConvertFromTagModel(variance))
}
export function ConvertFromTagModel(variance: Model.ITag): ITag {
  return {
    isadd: false,
    isdelete: false,
    name: variance.name,
  }
}