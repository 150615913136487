import * as Model from '..'

export interface IProduct {
  description: string
  ismij: boolean
  isonfb: boolean
  isonig: boolean
  isonweb: boolean
  isusj: boolean
  name: string
  privatenotes: string
  productid: string
  s3keythumbnail: string
  tags: Model.Form.ITag[]
  variances: Model.Form.IVariance[]
}
export function ConvertFromProductModel(product: Model.IProduct): IProduct {
  return {
    description: product.description,
    ismij: product.ismij,
    isonfb: product.isonfb,
    isonig: product.isonig,
    isonweb: product.isonweb,
    isusj: product.isusj,
    name: product.name,
    privatenotes: product.privatenotes,
    productid: product.productid,
    s3keythumbnail: product.s3keythumbnail,
    tags: Model.Form.ConvertFromTagsModel(product.tags),
    variances: Model.Form.ConvertFromVariancesModel(product.variances),
  }
}

export interface IOrderProductDetail extends Partial<IProduct> {
  iscreate: boolean
}

export function EmptyOrderProductDetail(productid: string = ""): IOrderProductDetail {
  return {
    // description: "",
    iscreate: true,
    // ismij: false,
    // isonfb: false,
    // isonig: false,
    // isonweb: false,
    // isusj: false,
    name: "",
    // privatenotes: "",
    productid,
    // s3keythumbnail: "",
    // tags: [],
  }
}