import * as _ from 'lodash'
import * as React from 'react'

export interface IPaymentMethodOption { value: string, text: string }
export const paymentMethods: IPaymentMethodOption[] = [
  { value: "hsbc", text: "滙豐" },
  { value: "boc", text: "中銀" },
  { value: "hs", text: "恆生" },
  { value: "payme", text: "PayMe" },
  { value: "paypal", text: "Paypal" },
  { value: "other", text: "其他" }
]

// interface IPaymentChannelLabelProps { }

const PaymentChannelLabel: React.SFC<{}> = (props) => {
  const paymentMethod = _.find(paymentMethods, ({ value }) => value === props.children)

  if (!paymentMethod) {
    return <span>{props.children}</span>
  }
  return <span>{paymentMethod.text}</span>
} 

export default PaymentChannelLabel