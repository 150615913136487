import * as moment from 'moment'
import * as React from 'react'
import DatePicker from "react-datepicker"

import 'react-datepicker/dist/react-datepicker-cssmodules.min.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import './DatePickerInput.css'

interface IProps {
  name: string
  value: moment.Moment
  onChange: (name: string, value: moment.Moment) => void
  className?: string
}

interface IState {
  selecteddate: moment.Moment
}

class DatePickerInput extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)

    this.state = {
      selecteddate: moment(this.props.value) || moment()
    }
  }
  
  public render() {
    return (
      <DatePicker
        className={`form-control ${this.props.className}`}
        dateFormat="YYYY-MM-DD"
        todayButton={"Today"}
        selected={this.state.selecteddate}
        onChange={this.handleChange}
      />
    )
  }

  private handleChange = (date: moment.Moment) => {
    this.setState({
      selecteddate: date
    }, () => {
      this.props.onChange(this.props.name, this.state.selecteddate)
    })
  }
}

export default DatePickerInput