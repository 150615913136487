import { List } from 'immutable'
import * as React from 'react'
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

/* tslint:disable no-var-requires*/
const BlockUi = require("react-block-ui").default
/* tslint:enable no-var-requires*/
import 'react-block-ui/style.css'

import * as Lib from "../../lib"
import * as Model from '../../models'
import * as Layout from '../layout'
import AddTag from '../tag/AddTag'
import Tag from '../tag/Tag'

interface IProps {
  isOpen: boolean
  formTitle: string
  isCreate: boolean
  presettag: Model.IPresetTag
  onCreate?: (presettag: Model.IPresetTag) => void
  onUpdate?: (presettag: Model.IPresetTag) => void
  onCancel: (presettagid?: string) => void
  errorMessage: string | undefined
  isLoading: boolean
}
interface IState {
  workingCopy: Model.Form.IPresetTag
}

class FormGeneric extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      workingCopy: Model.Form.ConvertFromPresetTagModel(props.presettag)
    }
  }

  public render() {
    const workingCopy = this.state.workingCopy
    
    return (
      <Form>
        <Modal isOpen={this.props.isOpen} toggle={this.handleCancel} size="lg">
          <BlockUi tag="div" blocking={this.props.isLoading} loader={Layout.Loader}>
            <ModalHeader>
              {this.props.formTitle}
            </ModalHeader>
            <ModalBody>
              { this.props.isCreate && (
                <FormGroup row={true}>
                  <Label sm={2}>ID</Label>
                  <Col sm={10}>
                    <Input name="presettagid" value={workingCopy.presettagid} onChange={this.handleChangePresetTagId} required={true} placeholder="ID" />
                  </Col>
                </FormGroup>
              )}
              <FormGroup row={true}>
                <Label sm={2}>名稱</Label>
                <Col sm={10}>
                  <Input id="name" className="form-control" type="text" name="name" value={workingCopy.name} onChange={this.handleChangeName} required={true} placeholder="名稱" />
                </Col>
              </FormGroup>
              <FormGroup row={true}>
                <Label sm={2}>標籤</Label>
                <Col sm={10}>
                  { workingCopy.tags && workingCopy.tags.length > 0 ? (
                    workingCopy.tags.map((tag: Model.Form.ITag, index: number) => (
                      <Tag key={tag.name} tag={tag} canRemove={true} onRemove={this.handleRemoveTag} />
                    ))
                  ) : (
                    <span>沒有資料</span>
                  )}
                  <AddTag onAddTag={this.handleAddTag} tag={ Model.Form.EmptyITag() } />
                </Col>
                <Layout.Clearfix />
              </FormGroup>
              { this.props.errorMessage && (
                <Alert color="danger">{this.props.errorMessage}</Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="danger" className="mr-auto" onClick={this.handleClear}>重置</Button>
              <Button type="button" color="link" onClick={this.handleCancel}>取消</Button>
              <Button type="button" color="primary" onClick={this.handleSubmit}>確定</Button>
            </ModalFooter>
          </BlockUi>
        </Modal>
      </Form>
    )
  }

  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.submitPresetTag(this.state.workingCopy)
  }

  private submitPresetTag = (presettag: Model.IPresetTag) => {
    if (this.props.isCreate) {
      // Submit Create
      if (this.props.onCreate) {
        this.props.onCreate(presettag)
      }
    } else {
      // Submit Update
      if (this.props.onUpdate) {
        this.props.onUpdate(presettag)
      }
    }
  }

  private handleChangePresetTagId = (event: any) => {
    const presettagid = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.presettagid = presettagid

    this.setState({workingCopy})
  }

  private handleChangeName = (event: any) => {
    const name = event.target.value
    const workingCopy = this.state.workingCopy
    workingCopy.name = name

    this.setState({workingCopy})
  }
  
  private handleRemoveTag = (tagToRemove: Model.Form.ITag) => {
    const workingCopy = this.state.workingCopy
    
    workingCopy.tags = List(workingCopy.tags).filterNot((value: Model.Form.ITag) => {
      return value.name === tagToRemove.name
    }).toArray()
    this.setState({workingCopy})
  }
  
  private handleAddTag = (tagToAdd: Model.Form.ITag) => {
    const workingCopy = this.state.workingCopy
    
    workingCopy.tags = Lib.listUnion(List(workingCopy.tags), List([tagToAdd]), tag => tag.name).toArray()
    this.setState({workingCopy})
  }

  private handleCancel = () => {
    if (window.confirm('確定要取消？')) {
      // window.location.replace('/presettag')
      this.props.onCancel()
    }
  }

  private handleClear = () => {
    if (window.confirm("確定要重置？")) {
      this.setState({ workingCopy: Model.Form.ConvertFromPresetTagModel(this.props.presettag) })
    }
  }
}

export default FormGeneric