import * as _ from "lodash"
import * as React from "react"
import { Card, CardBody } from "reactstrap"

import * as Model from "../../models"
import Tag from "./Tag"

interface IProps {
  tags: Model.ITag[]
  onAddTag: (tagname: string) => void
  onRemoveTag: (tagToRemove: Model.ITag) => void
}

const TagEditPanel: React.SFC<IProps> = (props) => {
  return (
    <Card>
      <CardBody>
        { _.map(props.tags, tag => (
          <Tag key={tag.name} tag={tag} canRemove={true} onRemove={props.onRemoveTag} />
        ))}
      </CardBody>
    </Card>
  )
}

export default TagEditPanel