import { List } from 'immutable'
import * as React from 'react'

import * as Base from './Base'
import * as ValidatorRules from './Rules'

class ValidatorCustom extends Base.default<Base.IBaseValidatorProps, Base.IBaseValidatorStates> {
  public readonly state: Base.IBaseValidatorStates = {
    errorMessages: List(),
    hasError: false
  }

  public componentDidMount() {
    this.props.manager.addValidator(this)
  }

  public render() {
    const { didinvalidate } = this.props

    return (
      <span>
        { this.state.hasError && !didinvalidate &&
          this.renderErrorMessages(this.state.errorMessages)
        }
      </span>
    )
  }

  public performValidation = (value: any): boolean => {
    const result = this.props.rules.validate(value)

    const newState = {
      ...this.state,
      errorMessages: List<string>(),
      hasError: false
    }
    if (result.count() > 0) {
      newState.errorMessages = result.map((item: ValidatorRules.IValidateResult) => item.errorMessage).toList()
      newState.hasError = true
    }
    this.setState(newState, () => {
      if (this.props.didinvalidate) {
        this.props.didinvalidate(this.props.name, this.renderErrorMessages(this.state.errorMessages))
      }
    })

    return newState.hasError
  }
}

export default ValidatorCustom