import api from '.'
import * as Model from '../models'

interface IGetPagedReturnType { addresses: Model.IAddress[], pagesize: number }
export const getPaged = (page: number) => {
  return api.get(`/api/address/page/${page}`).then(response =>
    response.data as IGetPagedReturnType
  ) as Promise<IGetPagedReturnType>
}

export const create = (data: Model.IAddress) => {
  return api.post(`/api/address/create`, data).then(response =>
    response.data.address as Model.IAddress
  ) as Promise<Model.IAddress>
}

export const update = (data: Model.IAddress) => {
  return api.post(`/api/address/update`, data).then(response =>
    response.data.address as Model.IAddress
  ) as Promise<Model.IAddress>
}