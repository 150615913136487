import * as React from "react"

import * as Model from "../../../models"
import * as Layout from '../../layout'

interface IProps {
  product: Model.IProduct
  size: 50 | 80 | 100 | 200
}
const ProductImage: React.SFC<IProps> = (props)  => {
  const product = props.product
  const size = props.size

  return (
    <Layout.LazyImage s3key={product.s3keythumbnail} alt={product.name} width={size} height={size} />
  )
}

export default ProductImage