import * as _ from "lodash"
import * as moment from "moment"
import * as React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Media, Row } from 'reactstrap'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import * as Layout from '../layout'
import Tag from '../tag/Tag'
import FormConvert from "./FormConvert"

interface IProps extends IAppContextProps {
  oldproduct: Model.IOldProduct
}

interface IState {
  isLoading: boolean
  isOpen: boolean
  errorMessage: string
}

class Item extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      errorMessage: "",
      isLoading: false,
      isOpen: false,
    }
  }

  public render() {
    const oldproduct = this.props.oldproduct
    return (
      <div>
        { this.state.isOpen === true && (
          <FormConvert formTitle={`Convert ${oldproduct.productid}`} oldproduct={this.props.oldproduct} onCancel={this.handleCancel} onCreate={this.handleCreate} isLoading={this.state.isLoading} />
        )}
        <Card>
          <CardHeader>
            <Layout.Clearfix>
              { oldproduct.name } <Layout.BadgeId>{ oldproduct.productid }</Layout.BadgeId>
            </Layout.Clearfix>
            <Button onClick={this.handleOldProductConvert}>Convert</Button>
          </CardHeader>
          <CardBody>
            <Container>
              <Media>
                <Layout.LazyImage s3key={oldproduct.s3key} alt={oldproduct.name} width={200} height={200} isoldproduct={true} />
              </Media>
              <pre>
                {oldproduct.description}
              </pre>
              <Row>
                <Col xs={12}>
                  { oldproduct.isusj && (
                    <Tag className="usj" tag={ { name: "USJ" } } />
                  )}
                  { oldproduct.ismij && (
                    <Tag className="mij" tag={ { name: "日本製" } } />
                  )}
                  { oldproduct.ispostedfb && (
                    <Tag className="fb" tag={ { name: "FB" } } />
                  )}
                  { oldproduct.ispostedig && (
                    <Tag className="ig" tag={ { name: "IG" } } />
                  )}
                  { oldproduct.isonshelf && (
                    <Tag className="web" tag={ { name: "Web" } } />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  Preset tags: { _.map(JSON.parse(oldproduct.presettags), presettag => presettag) }
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-muted">
                  建立日期: <Layout.CalendarDate format="YYYY-MM-DD HH:mm:ss">{ moment(oldproduct.datecreated) }</Layout.CalendarDate>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    )
  }

  private handleOldProductConvert = () => {
    this.setState({
      isOpen: true
    })
  }

  private handleCancel = () => {
    this.setState({
      isOpen: false
    })
  }

  private handleCreate = async (product: Model.IProduct) => {
    // tslint:disable no-console
    console.log(product)

    const action = this.props.appcontext.getOldProductAction()
    this.setState({
      errorMessage: "",
      isLoading: true,
    })

    try {
      await action.convertFromOldProduct(product, this.props.oldproduct.productid)
      this.setState({
        errorMessage: "",
        isLoading: false,
        isOpen: false,
      })
    } catch (error) {
      this.setState({
        errorMessage: JSON.stringify(error),
        isLoading: false,
      })
    }
  }
}

export default withAppContext(Item)