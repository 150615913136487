import * as React from 'react'
import * as FontAwesome from 'react-fontawesome'
import { Badge, Button, Card, CardBody, Collapse, Input } from 'reactstrap'

import * as Model from '../../models'
import * as Layout from '../layout'
import ProductName from '../product/ProductName'

interface IOrderProductProps {
  orderproduct: Model.Form.IOrderProduct,
  onOrderProductChanged: (orderproduct: Model.Form.IOrderProduct) => void
}
interface IOrderProductState {
  isExpand?: boolean
  workingCopy: Model.Form.IOrderProduct
}

export default class OrderProduct extends React.Component<IOrderProductProps, IOrderProductState> {
  constructor(props: IOrderProductProps) {
    super(props)

    this.state = {
      isExpand: this.props.orderproduct.iscreate || false,
      workingCopy: this.props.orderproduct
    }
  }

  public render() {
    const { isExpand, workingCopy } = this.state

    return (
      <Card outline={true} color={ workingCopy.isdelete ? "danger" : "" }>
        <CardBody>
          <Button color="link" type="button" onClick={ this.handleToggle } className="pull-right">
            <FontAwesome name={ isExpand ? "caret-up" : "caret-down" } />
          </Button>
          <div>
            { this.props.orderproduct.detail.s3keythumbnail &&
              <Layout.LazyImage s3key={this.props.orderproduct.detail.s3keythumbnail} alt={this.props.orderproduct.detail.name || ""} width={50} height={50} />
              // <Media object={true} data-src={`${IMAGE_BASE_URL}/${this.props.orderproduct.detail.s3keythumbnail}`} alt={this.props.orderproduct.detail.name || ""} style={{width: 50, height: 50}} />
            }
            <div>
              { workingCopy.isdelete && (<Badge color="danger">刪除</Badge>) }
              <ProductName productname={ workingCopy.detail.name || "" } variancename={ workingCopy.variance.name } /> {' '}
              <Layout.BadgeId>{workingCopy.productid}</Layout.BadgeId>
              <Layout.BadgeId>{workingCopy.varianceid}</Layout.BadgeId>
              { workingCopy.amount > 1 &&
                <Badge color="danger">x {workingCopy.amount}</Badge>
              }
            </div>
            <div>
              <Layout.DollarSign sign="$">{workingCopy.sellingprice * workingCopy.amount}</Layout.DollarSign> = 
              <Layout.DollarSign sign="$">{workingCopy.sellingprice}</Layout.DollarSign> x {workingCopy.amount}
            </div>
          </div>
          {/* <div>{workingCopy.product.productid} | {workingCopy.variance.varianceid}</div> */}
          <Collapse isOpen={isExpand}>
            <hr />
            { workingCopy.detail.iscreate === true && (
              <div>
                貨品名稱
                <Input name="name" value={workingCopy.detail.name} onChange={this.handleProductValueChange} />
              </div>
            )}
            { workingCopy.variance.iscreate === true && (
              <div>
                種類名稱
                <Input name="name" value={workingCopy.variance.name} onChange={this.handleVarianceValueChange} />
                成本
                <Input name="primecost" type="number" value={workingCopy.variance.primecost} onChange={this.handleVarianceValueChange} />
                成本調整
                <Input name="primecostadjust" type="number" value={workingCopy.variance.primecostadjust} onChange={this.handleVarianceValueChange} />
              </div>
            )}
            售價 (定價:<Layout.DollarSign sign="$">{workingCopy.standardprice}</Layout.DollarSign>
              {workingCopy.sellingprice - workingCopy.standardprice === 0 ? (
                ""
              ) : (
                workingCopy.sellingprice - workingCopy.standardprice > 0 ? (
                  <Badge color="info">+<Layout.DollarSign sign="$">{workingCopy.sellingprice - workingCopy.standardprice}</Layout.DollarSign></Badge>
                ) : (
                  <Badge color="danger"><Layout.DollarSign sign="$">{workingCopy.sellingprice - workingCopy.standardprice}</Layout.DollarSign></Badge>
                )
              )})
            <Input name="sellingprice" type="number" value={workingCopy.sellingprice} onChange={this.handleOrderProductValueChange} />
            數量
            <Input name="amount" type="number" value={workingCopy.amount} onChange={this.handleOrderProductValueChange} />
            <Layout.RowSeparator />
            <Button color={!workingCopy.isdelete ? "danger" : "success"} type="button" onClick={this.handleRemoveOrderProduct}>
              { !workingCopy.isdelete && (
                <FontAwesome name="arrow-right" />
              )}
              <FontAwesome name="trash" />
              { workingCopy.isdelete && (
                <FontAwesome name="arrow-right" />
              )}
            </Button>
          </Collapse>
        </CardBody>
      </Card>
    )
  }

  private handleToggle = () => {
    const isExpand = !this.state.isExpand

    this.setState({isExpand})
  }

  private handleOrderProductValueChange = (event: any) => {
    const keyName = event.target.name
    const workingCopy = this.state.workingCopy
    workingCopy[keyName] = event.target.value

    this.setState({ workingCopy })
  }

  private handleRemoveOrderProduct = (event: any) => {
    const workingCopy = this.state.workingCopy
    workingCopy.isdelete = !workingCopy.isdelete

    this.setState({ workingCopy })
  }
  
  private handleProductValueChange = (event: any) => {
    const keyName = event.target.name
    const workingCopy = this.state.workingCopy
    workingCopy.detail[keyName] = event.target.value

    this.setState({ workingCopy })
  }

  private handleVarianceValueChange = (event: any) => {
    const keyName = event.target.name
    const workingCopy = this.state.workingCopy
    workingCopy.variance[keyName] = event.target.value
    if (workingCopy.iscreate && (keyName === "primecost" || keyName === "primecostadjust")) {
      const primecost = +workingCopy.variance.primecost || 0
      const primecostadjust = +workingCopy.variance.primecostadjust || 0
      const sellingprice = Math.ceil((primecost + primecostadjust) * 0.095)
      workingCopy.sellingprice = sellingprice
      workingCopy.variance.sellingprice = sellingprice
      workingCopy.standardprice = sellingprice
    }

    this.setState({ workingCopy })
  }
}