import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import ProductForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
  product: Model.IProduct
  errorMessage: string | undefined
}

const FormEdit: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getProductState()
    const action = props.appcontext.getProductAction()

  const handleUpdate = (productWorkingCopy: Model.Form.IProduct) => {
    action.updateRequest(productWorkingCopy)
  }
  
  const handleCancel = () => {
    action.closeEditForm(props.product.productid)
  }

  const updateProduct = state.updateStatuses[props.product.productid]

  return (
    <ProductForm isOpen={true} formTitle={props.formTitle} isCreate={false} product={props.product} onUpdate={handleUpdate} onCancel={handleCancel} errorMessage={props.errorMessage} isLoading={updateProduct.isSubmitting} />
  )
}

export default withAppContext(FormEdit)