import * as React from "react"
import {Redirect, Route, RouteProps} from "react-router-dom"

import { isAuth } from '../../auth'

// type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>

const AUTHENTICATED = isAuth()

// tslint:disable jsx-no-lambda
const PrivateRoute: React.SFC<RouteProps> = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentType<RouteProps>;
}) => (
  <Route
    {...rest}
    render={props =>
      AUTHENTICATED
        ? <Component {...props} /> 
        : <Redirect to="/login" />
    }
  />
);
// tslint:enable jsx-no-lambda

// const PrivateRoute: React.StatelessComponent<RouteProps & RouteComponentProps> = ({component, ...rest}) => {
//   const renderFn = (Component?: RouteComponent) => (props: RouteProps & RouteComponentProps) => {
//     if (!Component) {
//       return null
//     }

//     if (AUTHENTICATED) {
//       return <Component {...props} />
//     }

//     const redirectProps = {
//       to: {
//         pathname: "/login",
//         state: {from: props.location},
//       },
//     }

//     return <Redirect {...redirectProps} />
//   }

//   return <Route {...rest} render={renderFn(component)} />
// }

export default PrivateRoute