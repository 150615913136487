import * as React from 'react'

import { IAppContextProps, withAppContext } from "../../context/AppContext"
import * as Model from '../../models'
import MailTrackerForm from './FormGeneric'

interface IProps extends IAppContextProps {
  formTitle: string
}

const FormCreate: React.SFC<IProps> = (props) => {
  const state = props.appcontext.getMailTrackerState()
  const action = props.appcontext.getMailTrackerAction()

  const handleCreate = (mailtracker: Model.IMailTracker) => {
    action.createRequest(mailtracker)
  }

  const handleCancel = () => {
    action.closeCreateForm()
  }

  return (
    <MailTrackerForm isOpen={state.createStatus.isFormOpen} formTitle={props.formTitle} isCreate={true} mailtracker={state.createStatus.data} onCreate={handleCreate} onCancel={handleCancel} errorMessage={state.createStatus.errorMessage} isLoading={state.createStatus.isSubmitting} />
  )
}

export default withAppContext(FormCreate)