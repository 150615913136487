import * as React from 'react'
import { Link } from 'react-router-dom'

export const GenericNotFound = () => {
  return (
    <div>
      <h1>發生錯誤</h1>
      <Link to="/">返回首頁</Link>
    </div>
  )
}

export default GenericNotFound